@charset "UTF-8";
/*
    IMPORTANT :
    A cause d'IE 8 et 9 qui ne supportent pas les fichiers css trop gros, on doit le séparer en 3 fichiers plus petits :(
*/
/*
 * vars.scss
 */
/*==============================*/
/*==============================*/
/*==============================*/
/*Colors*/
/*==============================*/
/*==============================*/
/*Path*/
/*==============================*/
/*==============================*/
/*Fonts*/
/*==============================*/
/*==============================*/
/*Compass mixins settings*/
/*==============================*/
/*==============================*/
/*==============================*/
/*==============================*/
/*Compass & custom imports*/
/*==============================*/
/*==============================*/
/*Grids (using Gridle - http://gridle.org/)*/
/*==============================*/
/* line 30, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.container:after, #page-chart .row-3:after {
  content: "";
  display: table;
  clear: both;
}

/* line 38, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.parent:before, .parent-mobile:before, .parent:after, .parent-mobile:after {
  content: "";
  display: table;
}
/* line 43, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.parent:after, .parent-mobile:after {
  clear: both;
}
/* line 47, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.parent, .parent-mobile {
  zoom: 1;
}

/* line 56, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.push-0, .push-1, .push-2, .push-3, .push-4, .push-5, .push-6, .push-7, .push-8, .push-9, .push-10, .push-11, .push-12, .push-mobile-0, .push-mobile-1, .push-mobile-2, .push-mobile-3, .push-mobile-4, .push-mobile-5, .push-mobile-6, .push-mobile-7, .push-mobile-8, .push-mobile-9, .push-mobile-10, .push-mobile-11, .push-mobile-12, .pull-0, .pull-1, .pull-2, .pull-3, .pull-4, .pull-5, .pull-6, .pull-7, .pull-8, .pull-9, .pull-10, .pull-11, .pull-12, .pull-mobile-0, .pull-mobile-1, .pull-mobile-2, .pull-mobile-3, .pull-mobile-4, .pull-mobile-5, .pull-mobile-6, .pull-mobile-7, .pull-mobile-8, .pull-mobile-9, .pull-mobile-10, .pull-mobile-11, .pull-mobile-12 {
  position: relative;
}

/* line 59, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.container, #page-chart .row-3 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 64, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.parent, .parent-mobile {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 90, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_silent-classes.scss */
.grid-0, .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12, .grid-mobile-0, .grid-mobile-1, .grid-mobile-2, .grid-mobile-3, .grid-mobile-4, .grid-mobile-5, .grid-mobile-6, .grid-mobile-7, .grid-mobile-8, .grid-mobile-9, .grid-mobile-10, .grid-mobile-11, .grid-mobile-12, #page-chart .row-3 .col {
  display: inline-block;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * Str replace
 *
 * @param {string}  $string    String that you want to replace
 * @param {string}  $substr    String that is to be replaced by `$newsubstr`
 * @param {string}  $newsubstr String that replaces `$substr`
 * @param {number*} $all       Flag for replaceing all (1+) or not (0)
 * @return {string}
 */
/**
 * Map set
 *
 * @param 	Map 	$map 		The map to use
 * @param 	String 	$key 		The key to update
 * @param 	Mixed 	$value 		The new value
 * @return 	Map 			The new map
 */
/**
 * Get the column width in percent for the global or a specific context
 *
 * @param 	int 		$columns 					The number of columns to calculate
 * @param 	int 		$context : $gridle-columns-count 	 	The context to use
 * @return 	percentage 							The width in percent
 */
/**
 *  Get a state map
 *
 * @param 	string 		$name 		The name of the state to get
 * @return 	map 				A state map object
 */
/**
 * Check if a state exist :
 *
 * @param 	string 		$name 		The name of the state to check
 * @return 	Boolean 			true is exist
 */
/**
 * Get the media queries variables :
 *
 * @param 	int 		$index 	 	The media query indes
 * @param 	String 		$var 		The media query variable name
 * @return 	String|int 			The variable value
 */
/**
 * Get a variable
 *
 * @param 	String 		$varName 				The variable name
 * @param  	String 		$stateMap-or-stateName 	 	The state name or a map state value
 * @return 	Mixed 							The finded value
 */
/**
 * Set a variable in a state
 * @param 	Mixed $stateName-or-stateIndex 	The state name of state index
 * @param  	String $var                    		Variable name to assign
 * @param  	Mixed $newValue          		The new value to assign
 * @return 	List                         			The states list (full)
 */
/**
 * Generate a column
 *
 * @param 	String 		$name 			The column name (often count)
 * @param 	int 		$columns 		The column count that the column will take
 * @param 	int 		$context 		The context on witch the with will be calculed
 * @param 	Boolean 	$generateClasses 	Set if the column has to be generated in css
 */
/**
 * Generate classname
 *
 * @param 	List 		$parrern 	The pattern to use to generate classname
 * @param 	String 		$state 		The state
 * @param 	int 		$count 		The column count
 */
/**
 * Get the media query for a particular state, or with, etc...
 *
 * @param 	Mixed 		$state-or-min-width 		The state name of the min with
 * @param 	Mixed 		$max-width 			The max width if first param is a min width
 * @return 	String 						The media query string without the @media
 */
/**
 * Get states count
 * 
 * @return 	int 	The number of states defined
 */
/**
 * Setup
 */
/**
 *  Register a clear each class
 */
/**
 * Register a special class
 */
/**
 * Register default states
 */
/**
 * Register default mobile first states :
 */
/**
 * Set the debug device (not used for now)
 * 
 * @param 	String 		$state 		The state to update
 * @para m 	String 		$device 	The device to use (iphone, etc...)
 */
/**
 * Parent clear each
 */
/**
 * Vertical align :
 */
/**
 * Show inline
 *
 * @param 	String 		$state 		The state name
 */
/**
 * Generate a custom class for all the states
 *
 * @param 	list 	$pattern 		The name pattern of the class
 * @param 	list 	$statesNames 		The states names to generate
 */
@media screen and (max-width: 400px) {
  @-ms-viewport {
    width: device-width;
  }
}
/* line 306, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
#gridle-settings {
  content: '{ "version" : "1.3.36", "states" : { "retina":{ "query" : "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)", "classes" : false, "name" : "retina", "_gridle" : true }, "tv":{ "query" : "only tv", "classes" : false, "name" : "tv", "_gridle" : true }, "print":{ "query" : "only print", "classes" : false, "name" : "print", "_gridle" : true }, "portrait":{ "query" : "only screen and (orientation: portrait)", "classes" : false, "name" : "portrait", "_gridle" : true }, "landscape":{ "query" : "only screen and (orientation: landscape)", "classes" : false, "name" : "landscape", "_gridle" : true }, "default":{ "name" : "default", "min-width" : null, "max-width" : null, "query" : null, "classes" : true, "context" : "12", "gutter-width" : "20px", "direction" : "ltr", "name-multiplicator" : "1", "debug" : false, "debug-show-class-names" : true, "ie7-support" : false, "html-states-classes" : false, "generate-push-classes" : true, "generate-pull-classes" : true, "generate-prefix-classes" : true, "generate-suffix-classes" : true, "generate-helpers-classes" : true, "_gridle" : true }, "mobile":{ "name" : "mobile", "min-width" : null, "max-width" : "320px", "query" : "screen and (max-width: 320px)", "classes" : true, "context" : "12", "gutter-width" : "20px", "direction" : "ltr", "name-multiplicator" : "1", "debug" : false, "debug-show-class-names" : true, "ie7-support" : false, "html-states-classes" : false, "generate-push-classes" : true, "generate-pull-classes" : true, "generate-prefix-classes" : true, "generate-suffix-classes" : true, "generate-helpers-classes" : true, "_gridle" : true }} }';
}

/* line 502, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-0, .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12, .grid-mobile-0, .grid-mobile-1, .grid-mobile-2, .grid-mobile-3, .grid-mobile-4, .grid-mobile-5, .grid-mobile-6, .grid-mobile-7, .grid-mobile-8, .grid-mobile-9, .grid-mobile-10, .grid-mobile-11, .grid-mobile-12 {
  float: left;
  direction: ltr;
  padding-left: 10px;
  padding-right: 10px;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-0 {
  width: 0%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-0 {
  left: 0%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-0 {
  right: 0%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-0 {
  margin-left: 0%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-0 {
  margin-right: 0%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-1 {
  width: 8.33333%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-1 {
  left: 8.33333%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-1 {
  right: 8.33333%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-1 {
  margin-left: 8.33333%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-1 {
  margin-right: 8.33333%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-2 {
  width: 16.66667%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-2 {
  left: 16.66667%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-2 {
  right: 16.66667%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-2 {
  margin-left: 16.66667%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-2 {
  margin-right: 16.66667%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-3 {
  width: 25%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-3 {
  left: 25%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-3 {
  right: 25%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-3 {
  margin-left: 25%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-3 {
  margin-right: 25%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-4 {
  width: 33.33333%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-4 {
  left: 33.33333%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-4 {
  right: 33.33333%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-4 {
  margin-left: 33.33333%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-4 {
  margin-right: 33.33333%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-5 {
  width: 41.66667%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-5 {
  left: 41.66667%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-5 {
  right: 41.66667%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-5 {
  margin-left: 41.66667%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-5 {
  margin-right: 41.66667%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-6 {
  width: 50%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-6 {
  left: 50%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-6 {
  right: 50%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-6 {
  margin-left: 50%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-6 {
  margin-right: 50%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-7 {
  width: 58.33333%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-7 {
  left: 58.33333%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-7 {
  right: 58.33333%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-7 {
  margin-left: 58.33333%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-7 {
  margin-right: 58.33333%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-8 {
  width: 66.66667%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-8 {
  left: 66.66667%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-8 {
  right: 66.66667%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-8 {
  margin-left: 66.66667%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-8 {
  margin-right: 66.66667%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-9 {
  width: 75%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-9 {
  left: 75%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-9 {
  right: 75%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-9 {
  margin-left: 75%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-9 {
  margin-right: 75%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-10 {
  width: 83.33333%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-10 {
  left: 83.33333%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-10 {
  right: 83.33333%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-10 {
  margin-left: 83.33333%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-10 {
  margin-right: 83.33333%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-11 {
  width: 91.66667%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-11 {
  left: 91.66667%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-11 {
  right: 91.66667%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-11 {
  margin-left: 91.66667%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-11 {
  margin-right: 91.66667%;
}

/* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.grid-12 {
  width: 100%;
}

/* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.push-12 {
  left: 100%;
}

/* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.pull-12 {
  right: 100%;
}

/* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.prefix-12 {
  margin-left: 100%;
}

/* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.suffix-12 {
  margin-right: 100%;
}

/* line 62, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.float-left {
  float: left;
}

/* line 65, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.float-right {
  float: right;
}

/* line 71, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.clear {
  clear: both;
}

/* line 74, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.clear-left {
  clear: left;
}

/* line 77, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.clear-right {
  clear: right;
}

/* line 83, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.no-gutter,
.no-margin {
  padding-left: 0;
  padding-right: 0;
}

/* line 87, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.no-gutter-left,
.no-margin-left {
  padding-left: 0;
}

/* line 91, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.no-gutter-right,
.no-margin-right {
  padding-right: 0;
}

/* line 95, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.no-gutter-top,
.no-margin-top {
  padding-top: 0;
}

/* line 99, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.no-gutter-bottom,
.no-margin-bottom {
  padding-bottom: 0;
}

/* line 106, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.gutter,
.margins {
  padding-left: 10px;
  padding-right: 10px;
}

/* line 110, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.gutter-left,
.margin-left {
  padding-left: 10px;
}

/* line 114, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.gutter-right,
.margin-right {
  padding-right: 10px;
}

/* line 118, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.gutter-top,
.margin-top {
  padding-top: 10px;
}

/* line 122, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.gutter-bottom,
.margin-bottom {
  padding-bottom: 10px;
}

/* line 129, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.auto-height {
  height: inherit;
}

/* line 135, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.centered {
  display: block !important;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  clear: both !important;
}

/* line 141, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.parent {
  padding-left: 0;
  padding-right: 0;
}

/* line 147, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.vertical-align-middle {
  font-size: 0;
  clear: both;
}
/* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
.vertical-align-middle > * {
  display: inline-block;
  float: none !important;
  vertical-align: middle;
  font-size: 1rem;
}

/* line 150, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.vertical-align-top {
  font-size: 0;
  clear: both;
}
/* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
.vertical-align-top > * {
  display: inline-block;
  float: none !important;
  vertical-align: top;
  font-size: 1rem;
}

/* line 153, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.vertical-align-bottom {
  font-size: 0;
  clear: both;
}
/* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
.vertical-align-bottom > * {
  display: inline-block;
  float: none !important;
  vertical-align: bottom;
  font-size: 1rem;
}

/**
 * Visible, hide, etc...
 */
/* line 162, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.hide {
  display: none;
}

/* line 168, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.not-visible {
  visibility: hidden;
}

/* line 174, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.show {
  display: block;
}

/* line 177, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.show-inline {
  display: inline-block;
}

/* line 183, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
.visible {
  visibility: visible;
}

/**
 * Clear each class :
 */
@media screen and (max-width: 320px) {
  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-0 {
    width: 0%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-0 {
    left: 0%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-0 {
    right: 0%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-0 {
    margin-left: 0%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-0 {
    margin-right: 0%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-1 {
    width: 8.33333%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-1 {
    left: 8.33333%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-1 {
    right: 8.33333%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-1 {
    margin-left: 8.33333%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-1 {
    margin-right: 8.33333%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-2 {
    width: 16.66667%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-2 {
    left: 16.66667%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-2 {
    right: 16.66667%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-2 {
    margin-left: 16.66667%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-2 {
    margin-right: 16.66667%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-3 {
    width: 25%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-3 {
    left: 25%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-3 {
    right: 25%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-3 {
    margin-left: 25%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-3 {
    margin-right: 25%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-4 {
    width: 33.33333%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-4 {
    left: 33.33333%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-4 {
    right: 33.33333%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-4 {
    margin-left: 33.33333%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-4 {
    margin-right: 33.33333%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-5 {
    width: 41.66667%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-5 {
    left: 41.66667%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-5 {
    right: 41.66667%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-5 {
    margin-left: 41.66667%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-5 {
    margin-right: 41.66667%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-6 {
    width: 50%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-6 {
    left: 50%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-6 {
    right: 50%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-6 {
    margin-left: 50%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-6 {
    margin-right: 50%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-7 {
    width: 58.33333%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-7 {
    left: 58.33333%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-7 {
    right: 58.33333%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-7 {
    margin-left: 58.33333%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-7 {
    margin-right: 58.33333%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-8 {
    width: 66.66667%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-8 {
    left: 66.66667%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-8 {
    right: 66.66667%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-8 {
    margin-left: 66.66667%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-8 {
    margin-right: 66.66667%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-9 {
    width: 75%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-9 {
    left: 75%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-9 {
    right: 75%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-9 {
    margin-left: 75%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-9 {
    margin-right: 75%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-10 {
    width: 83.33333%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-10 {
    left: 83.33333%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-10 {
    right: 83.33333%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-10 {
    margin-left: 83.33333%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-10 {
    margin-right: 83.33333%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-11 {
    width: 91.66667%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-11 {
    left: 91.66667%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-11 {
    right: 91.66667%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-11 {
    margin-left: 91.66667%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-11 {
    margin-right: 91.66667%;
  }

  /* line 591, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .grid-mobile-12 {
    width: 100%;
  }

  /* line 596, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .push-mobile-12 {
    left: 100%;
  }

  /* line 601, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .pull-mobile-12 {
    right: 100%;
  }

  /* line 606, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .prefix-mobile-12 {
    margin-left: 100%;
  }

  /* line 611, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .suffix-mobile-12 {
    margin-right: 100%;
  }

  /* line 62, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .float-mobile-left {
    float: left;
  }

  /* line 65, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .float-mobile-right {
    float: right;
  }

  /* line 71, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .clear-mobile {
    clear: both;
  }

  /* line 74, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .clear-mobile-left {
    clear: left;
  }

  /* line 77, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .clear-mobile-right {
    clear: right;
  }

  /* line 83, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .no-gutter-mobile,
  .no-margin-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 87, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .no-gutter-mobile-left,
  .no-margin-mobile-left {
    padding-left: 0;
  }

  /* line 91, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .no-gutter-mobile-right,
  .no-margin-mobile-right {
    padding-right: 0;
  }

  /* line 95, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .no-gutter-mobile-top,
  .no-margin-mobile-top {
    padding-top: 0;
  }

  /* line 99, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .no-gutter-mobile-bottom,
  .no-margin-mobile-bottom {
    padding-bottom: 0;
  }

  /* line 106, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .gutter-mobile,
  .margins-mobile {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 110, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .gutter-mobile-left,
  .margin-mobile-left {
    padding-left: 10px;
  }

  /* line 114, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .gutter-mobile-right,
  .margin-mobile-right {
    padding-right: 10px;
  }

  /* line 118, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .gutter-mobile-top,
  .margin-mobile-top {
    padding-top: 10px;
  }

  /* line 122, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .gutter-mobile-bottom,
  .margin-mobile-bottom {
    padding-bottom: 10px;
  }

  /* line 129, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .auto-height-mobile {
    height: inherit;
  }

  /* line 135, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .centered-mobile {
    display: block !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    clear: both !important;
  }

  /* line 141, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .parent-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 147, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .vertical-align-mobile-middle {
    font-size: 0;
    clear: both;
  }
  /* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
  .vertical-align-mobile-middle > * {
    display: inline-block;
    float: none !important;
    vertical-align: middle;
    font-size: 1rem;
  }

  /* line 150, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .vertical-align-mobile-top {
    font-size: 0;
    clear: both;
  }
  /* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
  .vertical-align-mobile-top > * {
    display: inline-block;
    float: none !important;
    vertical-align: top;
    font-size: 1rem;
  }

  /* line 153, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .vertical-align-mobile-bottom {
    font-size: 0;
    clear: both;
  }
  /* line 618, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_mixins.scss */
  .vertical-align-mobile-bottom > * {
    display: inline-block;
    float: none !important;
    vertical-align: bottom;
    font-size: 1rem;
  }

  /**
   * Visible, hide, etc...
   */
  /* line 162, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .hide-mobile {
    display: none;
  }

  /* line 168, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .not-visible-mobile {
    visibility: hidden;
  }

  /* line 174, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .show-mobile {
    display: block;
  }

  /* line 177, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .show-inline-mobile {
    display: inline-block;
  }

  /* line 183, ../../../../local/bundle/gems/gridle-1.3.36/stylesheets/gridle/_generate-mixins.scss */
  .visible-mobile {
    visibility: visible;
  }

  /**
   * Clear each class :
   */
}
/*------------------------------------------------
- Ici, toutes les fonctions sass et les mixins
------------------------------------------------*/
/*==============================*/
/*Custom Functions*/
/*==============================*/
/*==============================*/
/*Custom Mixins*/
/*==============================*/
/* line 48, ../sass/_mixins.scss */
.clearfix {
  *zoom: 1;
}
/* line 50, ../sass/_mixins.scss */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* line 57, ../sass/_mixins.scss */
.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*==============================*/
/*==============================*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?aa0b7k");
  src: url("../fonts/icomoon.eot?aa0b7k#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?aa0b7k") format("truetype"), url("../fonts/icomoon.woff?aa0b7k") format("woff"), url("../fonts/icomoon.svg?aa0b7k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* line 12, ../sass/_fonts.scss */
[class^="icon-"],
[class*=" icon-"],
.icomoon,
.arrow-list li:before,
#page-liste-dossiers .dossier-list li .dossier-date span:before,
#page-liste-dossiers .dossier-list li .dossier-btn:before,
#page-contact .contact-form .styled-select:before,
#page-recherche .result-list .result-btn:before,
.prix .breadcrumb-prix .breadcrumb-list li:before,
.node-type-award .breadcrumb-prix .breadcrumb-list li:before,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li:before,
.node-type-laureate .back-to-list:before,
.page-header-wrapper .sub-menu .sub-menu-list li a:after,
.page-header-wrapper .sub-menu .sub-menu-footer .close-block .close-sub-menu-btn:before,
.breadcrumb .breadcrumb-list li:before,
.page-footer-wrapper .footer-list-2 li a.rss-link:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 28, ../sass/_fonts.scss */
.icon-chevron-right-after:after, .page-header-wrapper .sub-menu .sub-menu-list li a:after {
  content: "\f054";
}

/* line 35, ../sass/_fonts.scss */
.icon-heart-insurance:before {
  content: "";
}

/* line 40, ../sass/_fonts.scss */
.icon-export:before {
  content: "";
}

/* line 45, ../sass/_fonts.scss */
.icon-book-search:before {
  content: "";
}

/* line 50, ../sass/_fonts.scss */
.icon-youtube-play:before {
  content: "";
}

/* line 55, ../sass/_fonts.scss */
.icon-camera:before {
  content: "";
}

/* line 60, ../sass/_fonts.scss */
.icon-loupe-left:before {
  content: "";
}

/* line 65, ../sass/_fonts.scss */
.icon-cloud:before {
  content: "";
}

/* line 70, ../sass/_fonts.scss */
.icon-chevron-right-light:before {
  content: "";
}

/* line 75, ../sass/_fonts.scss */
.icon-play-2:before {
  content: "";
}

/* line 80, ../sass/_fonts.scss */
.icon-left-hand:before {
  content: "";
}

/* line 85, ../sass/_fonts.scss */
.icon-women-baby:before {
  content: "";
}

/* line 90, ../sass/_fonts.scss */
.icon-baby:before {
  content: "";
}

/* line 95, ../sass/_fonts.scss */
.icon-right-hand:before {
  content: "";
}

/* line 100, ../sass/_fonts.scss */
.icon-books:before {
  content: "";
}

/* line 105, ../sass/_fonts.scss */
.icon-chevron-left-light:before {
  content: "";
}

/* line 110, ../sass/_fonts.scss */
.icon-rss-feed:before {
  content: "";
}

/* line 115, ../sass/_fonts.scss */
.icon-acrobat:before {
  content: "";
}

/* line 120, ../sass/_fonts.scss */
.icon-single-book:before {
  content: "";
}

/* line 125, ../sass/_fonts.scss */
.icon-pdf-dl:before {
  content: "";
}

/* line 130, ../sass/_fonts.scss */
.icon-file:before, #page-liste-dossiers .dossier-list li .dossier-date span:before {
  content: "";
}

/* line 135, ../sass/_fonts.scss */
.icon-filter-all:before {
  content: "";
}

/* line 140, ../sass/_fonts.scss */
.icon-mail:before {
  content: "";
}

/* line 145, ../sass/_fonts.scss */
.icon-f-increase:before {
  content: "";
}

/* line 150, ../sass/_fonts.scss */
.icon-sommaire:before {
  content: "";
}

/* line 155, ../sass/_fonts.scss */
.icon-backtop:before {
  content: "";
}

/* line 160, ../sass/_fonts.scss */
.icon-f-default:before {
  content: "";
}

/* line 165, ../sass/_fonts.scss */
.icon-f-lower:before {
  content: "";
}

/* line 170, ../sass/_fonts.scss */
.icon-f-tools:before {
  content: "";
}

/* line 175, ../sass/_fonts.scss */
.icon-print:before {
  content: "";
}

/* line 180, ../sass/_fonts.scss */
.icon-share:before {
  content: "";
}

/* line 185, ../sass/_fonts.scss */
.icon-contraste:before {
  content: "";
}

/* line 190, ../sass/_fonts.scss */
.icon-font-default:before {
  content: "";
}

/* line 195, ../sass/_fonts.scss */
.icon-font-increase:before {
  content: "";
}

/* line 200, ../sass/_fonts.scss */
.icon-font-lower:before {
  content: "";
}

/* line 205, ../sass/_fonts.scss */
.icon-tools:before {
  content: "";
}

/* line 210, ../sass/_fonts.scss */
.icon-dm:before {
  content: "";
}

/* line 215, ../sass/_fonts.scss */
.icon-gplus:before {
  content: "";
}

/* line 220, ../sass/_fonts.scss */
.icon-scoopit:before {
  content: "";
}

/* line 225, ../sass/_fonts.scss */
.icon-twitter:before {
  content: "";
}

/* line 230, ../sass/_fonts.scss */
.icon-youtube:before {
  content: "";
}

/* line 235, ../sass/_fonts.scss */
.icon-fb:before {
  content: "";
}

/* line 240, ../sass/_fonts.scss */
.icon-loupe:before {
  content: "";
}

/* line 245, ../sass/_fonts.scss */
.icon-linkedin:before {
  content: "";
}

/* line 250, ../sass/_fonts.scss */
.icon-heart:before {
  content: "";
}

/* line 255, ../sass/_fonts.scss */
.icon-close:before, .page-header-wrapper .sub-menu .sub-menu-footer .close-block .close-sub-menu-btn:before, .page-header-wrapper .mobile-menu-btn.is-open .icon-bars:before, .page-header-wrapper .is-open.margin-top-adjust .icon-bars:before {
  content: "";
}

/* line 260, ../sass/_fonts.scss */
.icon-remove:before {
  content: "";
}

/* line 265, ../sass/_fonts.scss */
.icon-times:before {
  content: "";
}

/* line 270, ../sass/_fonts.scss */
.icon-play:before {
  content: "";
}

/* line 275, ../sass/_fonts.scss */
.icon-pause:before {
  content: "";
}

/* line 280, ../sass/_fonts.scss */
.icon-chevron-left:before, .node-type-laureate .back-to-list:before {
  content: "";
}

/* line 285, ../sass/_fonts.scss */
.icon-chevron-right:before, #page-liste-dossiers .dossier-list li .dossier-btn:before, #page-recherche .result-list .result-btn:before, .prix .breadcrumb-prix .breadcrumb-list li:before,
.node-type-award .breadcrumb-prix .breadcrumb-list li:before,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li:before, .breadcrumb .breadcrumb-list li:before {
  content: "";
}

/* line 290, ../sass/_fonts.scss */
.icon-plus-circle:before {
  content: "";
}

/* line 295, ../sass/_fonts.scss */
.icon-chevron-up:before {
  content: "";
}

/* line 300, ../sass/_fonts.scss */
.icon-chevron-down:before, #page-contact .contact-form .styled-select:before {
  content: "";
}

/* line 305, ../sass/_fonts.scss */
.icon-feed:before {
  content: "";
}

/* line 310, ../sass/_fonts.scss */
.icon-rss:before, .page-footer-wrapper .footer-list-2 li a.rss-link:before {
  content: "";
}

/* line 315, ../sass/_fonts.scss */
.icon-bars:before {
  content: "";
}

/* line 320, ../sass/_fonts.scss */
.icon-navicon:before {
  content: "";
}

/* line 325, ../sass/_fonts.scss */
.icon-reorder:before {
  content: "";
}

/* line 330, ../sass/_fonts.scss */
.icon-angle-double-left:before {
  content: "";
}

/* line 335, ../sass/_fonts.scss */
.icon-angle-double-right:before {
  content: "";
}

/* line 340, ../sass/_fonts.scss */
.icon-angle-left:before {
  content: "";
}

/* line 345, ../sass/_fonts.scss */
.icon-angle-right:before {
  content: "";
}

/* line 350, ../sass/_fonts.scss */
.icon-long-arrow-right:before, .arrow-list li:before {
  content: "";
}

/*
 * Vendors - Ici, on importe toute les css des plugins en prenant soin de rajouter le _ devant le nom de fichier et de renommer l'extension .css en .scss
 * Ne pas oublier de changer les chemins d'images (media/images/vendors/...)
 */
/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
/* line 17, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
}

/* line 24, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper img {
  max-width: 100%;
  display: block;
}

/** THEME
===================================*/
/* line 32, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-viewport {
  background: #fff;
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

/* line 44, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

/* LOADER */
/* line 53, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(../images/vendors/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */
/* line 66, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}

/* line 75, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

/* line 82, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* line 95, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
/* line 102, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-prev {
  left: 10px;
}

/* line 107, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-next {
  right: 10px;
}

/* line 112, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

/* line 116, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

/* line 120, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  outline: 0;
  width: 30px;
  height: 30px;
  z-index: 9999;
}

/* line 131, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

/* AUTO CONTROLS (START / STOP) */
/* line 137, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}

/* line 141, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}

/* line 151, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}

/* line 156, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}

/* line 166, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
/* line 173, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

/* line 178, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */
/* line 185, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666 \9;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

/* line 194, ../sass/vendors/_jquery.bxslider.scss */
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

/* Slider */
/* line 2, ../sass/vendors/_slick.scss */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

/* line 23, ../sass/vendors/_slick.scss */
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* line 33, ../sass/vendors/_slick.scss */
.slick-list:focus {
  outline: none;
}

/* line 37, ../sass/vendors/_slick.scss */
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

/* line 43, ../sass/vendors/_slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* line 53, ../sass/vendors/_slick.scss */
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

/* line 61, ../sass/vendors/_slick.scss */
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

/* line 68, ../sass/vendors/_slick.scss */
.slick-track:after {
  clear: both;
}

/* line 72, ../sass/vendors/_slick.scss */
.slick-loading .slick-track {
  visibility: hidden;
}

/* line 77, ../sass/vendors/_slick.scss */
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

/* line 85, ../sass/vendors/_slick.scss */
[dir='rtl'] .slick-slide {
  float: right;
}

/* line 89, ../sass/vendors/_slick.scss */
.slick-slide img {
  display: block;
}

/* line 93, ../sass/vendors/_slick.scss */
.slick-slide.slick-loading img {
  display: none;
}

/* line 97, ../sass/vendors/_slick.scss */
.slick-slide.dragging img {
  pointer-events: none;
}

/* line 101, ../sass/vendors/_slick.scss */
.slick-initialized .slick-slide {
  display: block;
}

/* line 105, ../sass/vendors/_slick.scss */
.slick-loading .slick-slide {
  visibility: hidden;
}

/* line 109, ../sass/vendors/_slick.scss */
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* line 117, ../sass/vendors/_slick.scss */
.slick-arrow.slick-hidden {
  display: none;
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
/* line 5, ../sass/vendors/_colorbox.scss */
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

/* line 6, ../sass/vendors/_colorbox.scss */
#cboxWrapper {
  max-width: none;
}

/* line 7, ../sass/vendors/_colorbox.scss */
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

/* line 8, ../sass/vendors/_colorbox.scss */
#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

/* line 9, ../sass/vendors/_colorbox.scss */
#cboxContent {
  position: relative;
}

/* line 10, ../sass/vendors/_colorbox.scss */
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* line 11, ../sass/vendors/_colorbox.scss */
#cboxTitle {
  margin: 0;
}

/* line 12, ../sass/vendors/_colorbox.scss */
#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 13, ../sass/vendors/_colorbox.scss */
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

/* line 14, ../sass/vendors/_colorbox.scss */
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

/* line 15, ../sass/vendors/_colorbox.scss */
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

/* line 16, ../sass/vendors/_colorbox.scss */
#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/pdfmake-0.1.36/dt-1.10.18/b-1.5.2/b-flash-1.5.2/b-html5-1.5.2/b-print-1.5.2/fc-3.2.5/fh-3.1.4/kt-2.4.0/r-2.2.2
 *
 * Included libraries:
 *   pdfmake 0.1.36, DataTables 1.10.18, Buttons 1.5.2, Flash export 1.5.2, HTML5 export 1.5.2, Print view 1.5.2, FixedColumns 3.2.5, FixedHeader 3.1.4, KeyTable 2.4.0, Responsive 2.2.2
 */
/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th, table.dataTable tfoot th {
  font-weight: bold;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th, table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th:active, table.dataTable thead td:active {
  outline: none;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tfoot th, table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;
  background-position: center right;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting {
  background-image: url("DataTables-1.10.18/images/sort_both.png");
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting_asc {
  background-image: url("DataTables-1.10.18/images/sort_asc.png");
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting_desc {
  background-image: url("DataTables-1.10.18/images/sort_desc.png");
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting_asc_disabled {
  background-image: url("DataTables-1.10.18/images/sort_asc_disabled.png");
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead .sorting_desc_disabled {
  background-image: url("DataTables-1.10.18/images/sort_desc_disabled.png");
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody tr {
  background-color: #ffffff;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody tr.selected {
  background-color: #B0BED9;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th, table.dataTable tbody td {
  padding: 8px 10px;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid #ddd;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.row-border tbody tr:first-child th, table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th, table.dataTable.display tbody tr:first-child td {
  border-top: none;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.cell-border tbody tr th:first-child, table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.cell-border tbody tr:first-child th, table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3 {
  background-color: #fafafa;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.order-column tbody tr.selected > .sorting_1, table.dataTable.order-column tbody tr.selected > .sorting_2, table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1, table.dataTable.display tbody tr.selected > .sorting_2, table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad5;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fafafa;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fcfcfc;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fefefe;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad5;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #aebcd6;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ececec;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #efefef;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a2aec7;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a3b0c9;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a5b2cb;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.no-footer {
  border-bottom: 1px solid #111;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.compact thead th, table.dataTable.compact thead td {
  padding: 4px 17px 4px 4px;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.compact tfoot th, table.dataTable.compact tfoot td {
  padding: 4px;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable.compact tbody th, table.dataTable.compact tbody td {
  padding: 4px;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
table.dataTable, table.dataTable th, table.dataTable td {
  box-sizing: content-box;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_length {
  float: left;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #dcdcdc 100%);
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111111 100%);
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: #333;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

/* line 13, ../sass/vendors/_datatables.min.scss */
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  /* line 13, ../sass/vendors/_datatables.min.scss */
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  /* line 13, ../sass/vendors/_datatables.min.scss */
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  /* line 13, ../sass/vendors/_datatables.min.scss */
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  /* line 13, ../sass/vendors/_datatables.min.scss */
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}
@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-info > div {
  padding: 1em;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button, div.dt-button, a.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.333em;
  margin-bottom: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background-color: #e9e9e9;
  background-image: -webkit-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -moz-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -ms-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='white', EndColorStr='#e9e9e9');
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button.disabled, div.dt-button.disabled, a.dt-button.disabled {
  color: #999;
  border: 1px solid #d0d0d0;
  cursor: default;
  background-color: #f9f9f9;
  background-image: -webkit-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: -moz-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: -ms-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#ffffff', EndColorStr='#f9f9f9');
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button.active:not(.disabled) {
  background-color: #e2e2e2;
  background-image: -webkit-linear-gradient(top, #f3f3f3 0%, #e2e2e2 100%);
  background-image: -moz-linear-gradient(top, #f3f3f3 0%, #e2e2e2 100%);
  background-image: -ms-linear-gradient(top, #f3f3f3 0%, #e2e2e2 100%);
  background-image: -o-linear-gradient(top, #f3f3f3 0%, #e2e2e2 100%);
  background-image: linear-gradient(to bottom, #f3f3f3 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f3f3f3', EndColorStr='#e2e2e2');
  box-shadow: inset 1px 1px 3px #999999;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button:active:not(.disabled):hover:not(.disabled), button.dt-button.active:not(.disabled):hover:not(.disabled), div.dt-button:active:not(.disabled):hover:not(.disabled), div.dt-button.active:not(.disabled):hover:not(.disabled), a.dt-button:active:not(.disabled):hover:not(.disabled), a.dt-button.active:not(.disabled):hover:not(.disabled) {
  box-shadow: inset 1px 1px 3px #999999;
  background-color: #cccccc;
  background-image: -webkit-linear-gradient(top, #eaeaea 0%, #ccc 100%);
  background-image: -moz-linear-gradient(top, #eaeaea 0%, #ccc 100%);
  background-image: -ms-linear-gradient(top, #eaeaea 0%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eaeaea 0%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eaeaea 0%, #cccccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#eaeaea', EndColorStr='#cccccc');
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button:hover, div.dt-button:hover, a.dt-button:hover {
  text-decoration: none;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled), a.dt-button:hover:not(.disabled) {
  border: 1px solid #666;
  background-color: #e0e0e0;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #e0e0e0 100%);
  background-image: -moz-linear-gradient(top, #f9f9f9 0%, #e0e0e0 100%);
  background-image: -ms-linear-gradient(top, #f9f9f9 0%, #e0e0e0 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0%, #e0e0e0 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f9f9f9', EndColorStr='#e0e0e0');
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled), a.dt-button:focus:not(.disabled) {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;
  background-color: #79ace9;
  background-image: -webkit-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  background-image: -moz-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  background-image: -ms-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  background-image: -o-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#bddef4', EndColorStr='#79ace9');
}

/* line 16, ../sass/vendors/_datatables.min.scss */
.dt-button embed {
  outline: none;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-buttons {
  position: relative;
  float: left;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-buttons.buttons-right {
  float: right;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection button.dt-button, div.dt-button-collection div.dt-button, div.dt-button-collection a.dt-button {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  float: none;
  margin-bottom: 4px;
  margin-right: 0;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled), div.dt-button-collection div.dt-button:active:not(.disabled), div.dt-button-collection div.dt-button.active:not(.disabled), div.dt-button-collection a.dt-button:active:not(.disabled), div.dt-button-collection a.dt-button.active:not(.disabled) {
  background-color: #dadada;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
  background-image: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
  background-image: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
  background-image: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f0f0f0', EndColorStr='#dadada');
  box-shadow: inset 1px 1px 3px #666;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.two-column {
  width: 300px;
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.three-column {
  width: 450px;
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection.four-column {
  width: 600px;
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-collection .dt-button {
  border-radius: 0;
}

/* line 16, ../sass/vendors/_datatables.min.scss */
div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.3)), color-stop(1, rgba(0, 0, 0, 0.7)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 2001;
}

@media screen and (max-width: 640px) {
  /* line 16, ../sass/vendors/_datatables.min.scss */
  div.dt-buttons {
    float: none !important;
    text-align: center;
  }
}
/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button.processing, div.dt-button.processing, a.dt-button.processing {
  color: rgba(0, 0, 0, 0.2);
}

/* line 16, ../sass/vendors/_datatables.min.scss */
button.dt-button.processing:after, div.dt-button.processing:after, a.dt-button.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

/* line 19, ../sass/vendors/_datatables.min.scss */
table.DTFC_Cloned thead, table.DTFC_Cloned tfoot {
  background-color: white;
}

/* line 19, ../sass/vendors/_datatables.min.scss */
div.DTFC_Blocker {
  background-color: white;
}

/* line 19, ../sass/vendors/_datatables.min.scss */
div.DTFC_LeftWrapper table.dataTable, div.DTFC_RightWrapper table.dataTable {
  margin-bottom: 0;
  z-index: 2;
}

/* line 19, ../sass/vendors/_datatables.min.scss */
div.DTFC_LeftWrapper table.dataTable.no-footer, div.DTFC_RightWrapper table.dataTable.no-footer {
  border-bottom: none;
}

/* line 22, ../sass/vendors/_datatables.min.scss */
table.fixedHeader-floating {
  position: fixed !important;
  background-color: white;
}

/* line 22, ../sass/vendors/_datatables.min.scss */
table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}

/* line 22, ../sass/vendors/_datatables.min.scss */
table.fixedHeader-locked {
  position: absolute !important;
  background-color: white;
}

@media print {
  /* line 22, ../sass/vendors/_datatables.min.scss */
  table.fixedHeader-floating {
    display: none;
  }
}
/* line 25, ../sass/vendors/_datatables.min.scss */
table.dataTable tbody th.focus, table.dataTable tbody td.focus {
  box-shadow: inset 0 0 1px 2px #3366FF;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: 9px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '-';
  background-color: #d33333;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-column > tbody > tr > td.control, table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable.dtr-column > tbody > tr.parent td.control:before, table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: '-';
  background-color: #d33333;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

/* line 28, ../sass/vendors/_datatables.min.scss */
div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  /* line 28, ../sass/vendors/_datatables.min.scss */
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
/*==============================*/
/*EXTENDS*/
/*==============================*/
/* line 7, ../sass/_extends.scss */
.last-child-no-margin > *:last-child, .bk-type-txt > *:last-child, .bk-type > *:last-child, .bk-warning > *:last-child, .bk-info > *:last-child, .browsehappy > *:last-child, .framed > *:last-child {
  margin-bottom: 0;
}

/* line 11, ../sass/_extends.scss */
.bk-type-txt {
  display: block;
  margin: 0 0 1.5em;
}

/* line 16, ../sass/_extends.scss */
.bk-type, .bk-warning, .bk-info, .browsehappy, .framed {
  display: block;
  margin: 0 0 25px;
}

/* line 22, ../sass/_extends.scss */
.ta-l {
  text-align: left;
}

/* line 23, ../sass/_extends.scss */
.ta-r {
  text-align: right;
}

/* line 24, ../sass/_extends.scss */
.ta-c {
  text-align: center;
}

/* line 25, ../sass/_extends.scss */
.float-l {
  float: left;
}

/* line 26, ../sass/_extends.scss */
.float-r {
  float: right;
}

/* line 27, ../sass/_extends.scss */
.uppercase {
  text-transform: uppercase;
}

/* line 28, ../sass/_extends.scss */
.txt-big {
  font-size: 1.5625em;
  line-height: normal;
}

/* line 32, ../sass/_extends.scss */
.out {
  position: absolute;
  left: -5000px;
}

/* line 38, ../sass/_extends.scss */
.bullet-list > li, .node-type-laureate .wysiwyg-container ul > li, .node-type-laureate-list #liste-laureats > li .description ul > li {
  margin-bottom: 0;
  margin-left: 12px;
  position: relative;
}
/* line 43, ../sass/_extends.scss */
.bullet-list > li:before, .node-type-laureate .wysiwyg-container ul > li:before, .node-type-laureate-list #liste-laureats > li .description ul > li:before {
  content: "\2022";
  color: #3855a0;
  margin-left: -12px;
  font-size: 1.25em;
}
/* line 50, ../sass/_extends.scss */
.bullet-list > li ul, .node-type-laureate .wysiwyg-container ul > li ul, .node-type-laureate-list #liste-laureats > li .description ul > li ul {
  margin-bottom: 0;
}
/* line 53, ../sass/_extends.scss */
.bullet-list > li ul li, .node-type-laureate .wysiwyg-container ul > li ul li, .node-type-laureate-list #liste-laureats > li .description ul > li ul li {
  margin-bottom: 0;
  margin-left: 12px;
  position: relative;
}
/* line 58, ../sass/_extends.scss */
.bullet-list > li ul li:before, .node-type-laureate .wysiwyg-container ul > li ul li:before, .node-type-laureate-list #liste-laureats > li .description ul > li ul li:before {
  content: "\2022";
  color: #808080;
  margin-left: -12px;
  font-size: 1.25em;
}

/* line 70, ../sass/_extends.scss */
.square-list li {
  padding-left: 15px;
  position: relative;
}
/* line 73, ../sass/_extends.scss */
.square-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 6px;
  border-top: 6px solid currentColor;
}
/* line 81, ../sass/_extends.scss */
.square-list li li {
  padding-left: 10px;
}
/* line 83, ../sass/_extends.scss */
.square-list li li:before {
  width: 3px;
  border-width: 3px;
}

/* line 90, ../sass/_extends.scss */
.inline-list, #page-hp .grid-list, .prix .breadcrumb-prix .breadcrumb-list,
.node-type-award .breadcrumb-prix .breadcrumb-list,
.node-type-laureate .breadcrumb-prix .breadcrumb-list, .node-type-laureate .laureat-top-block .left-part .link-list, .award-menu-container .award-menu, .breadcrumb .breadcrumb-list, [class*="node-type-fondation"] .hp-grid-container .grid-list,
.page-theming .hp-grid-container .grid-list,
.layout-fondation .hp-grid-container .grid-list {
  letter-spacing: -0.31em;
}
/* line 92, ../sass/_extends.scss */
.inline-list > li, #page-hp .grid-list > li, .prix .breadcrumb-prix .breadcrumb-list > li,
.node-type-award .breadcrumb-prix .breadcrumb-list > li,
.node-type-laureate .breadcrumb-prix .breadcrumb-list > li, .node-type-laureate .laureat-top-block .left-part .link-list > li, .award-menu-container .award-menu > li, .breadcrumb .breadcrumb-list > li, [class*="node-type-fondation"] .hp-grid-container .grid-list > li,
.page-theming .hp-grid-container .grid-list > li,
.layout-fondation .hp-grid-container .grid-list > li {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  margin-bottom: 0;
  letter-spacing: normal;
}

/* line 99, ../sass/_extends.scss */
.arrow-list li {
  margin-bottom: 0;
  list-style-type: none;
}
/* line 104, ../sass/_extends.scss */
.arrow-list li:before {
  margin-right: 0.5em;
  color: #0095CA;
  font-size: 0.75em;
  vertical-align: top;
}

/* line 113, ../sass/_extends.scss */
.lk-no-underline {
  text-decoration: none;
}
/* line 115, ../sass/_extends.scss */
.lk-no-underline a {
  text-decoration: none;
}

/* line 119, ../sass/_extends.scss */
.text-info {
  padding: 5px 10px;
  background-color: #FAE692;
  border: 1px solid orange;
  color: black;
}

/* line 125, ../sass/_extends.scss */
.text-error {
  color: #900;
}

/* line 128, ../sass/_extends.scss */
.text-success {
  color: green;
}

/* line 134, ../sass/_extends.scss */
.vertical-center:before, .garantie-title .right-part:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/* line 141, ../sass/_extends.scss */
.vertical-center > div, .garantie-title .right-part > div,
.vertical-center > span,
.garantie-title .right-part > span,
.vertical-center > button,
.garantie-title .right-part > button {
  display: inline-block;
  max-width: 96%;
  vertical-align: middle;
}

/* line 152, ../sass/_extends.scss */
.vertical-bottom:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
}
/* line 159, ../sass/_extends.scss */
.vertical-bottom > div {
  display: inline-block;
  max-width: 97%;
  vertical-align: bottom;
}

@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 166, ../sass/_extends.scss */
  .padding-text-mobile, #page-actu .text-intro, #page-actu .publish-date, #page-magazine .intro, #page-magazine .magazine-block {
    padding: 0 10px;
  }
}

/* line 173, ../sass/_extends.scss */
.intro-plus {
  color: #3855a0;
  font-size: 1.1875em;
  font-weight: bold;
}

/*==============================*/
/*BLOCKS*/
/*==============================*/
/* line 185, ../sass/_extends.scss */
.bk-warning {
  padding: 20px;
  background-color: #ecc2ca;
  border: 1px solid #900;
}

/* line 191, ../sass/_extends.scss */
.bk-info, .browsehappy {
  padding: 20px;
  background-color: #FAE692;
  border: 1px solid orange;
  color: black;
}

/* line 198, ../sass/_extends.scss */
.framed {
  border: 1px solid grey;
  padding: 20px;
}
/* line 202, ../sass/_extends.scss */
.framed.grey {
  background-color: #ececec;
}

/* line 206, ../sass/_extends.scss */
.block, #page-actu .communique-intro, #page-magazine .magazine-block {
  margin-bottom: 1.5em;
}

/*==============================*/
/*BUTTONS*/
/*==============================*/
/* line 216, ../sass/_extends.scss */
.btn, .btn-orange, #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .pdf-dl-btn, .btn-green, #page-garantie.green .line-bg-right-btn,
#page-garantie.green .pdf-dl-btn, .btn-light-blue, #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .pdf-dl-btn, .btn-pink, #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .pdf-dl-btn, .btn-purple, #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .pdf-dl-btn, .btn-light-grey, .btn-grey, .btn-light-green, .node-type-laureate-list #liste-laureats > li.laureat .video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .project-btn {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  padding: 10px;
  position: relative;
  background-color: #3855a0;
  border: 1px solid #3855a0;
  color: white;
  font-size: 1.5625em;
  line-height: 1.5625em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-decoration: none;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 167, ../sass/_mixins.scss */
.btn:hover, .btn-orange:hover, #page-garantie.orange .line-bg-right-btn:hover,
#page-garantie.orange .pdf-dl-btn:hover, .btn-green:hover, #page-garantie.green .line-bg-right-btn:hover,
#page-garantie.green .pdf-dl-btn:hover, .btn-light-blue:hover, #page-garantie.light-blue .line-bg-right-btn:hover,
#page-garantie.light-blue .pdf-dl-btn:hover, .btn-pink:hover, #page-garantie.pink .line-bg-right-btn:hover,
#page-garantie.pink .pdf-dl-btn:hover, .btn-purple:hover, #page-garantie.purple .line-bg-right-btn:hover,
#page-garantie.purple .pdf-dl-btn:hover, .btn-light-grey:hover, .btn-grey:hover, .btn-light-green:hover, .node-type-laureate-list #liste-laureats > li.laureat .video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .project-btn:hover, .btn:focus, .btn-orange:focus, #page-garantie.orange .line-bg-right-btn:focus,
#page-garantie.orange .pdf-dl-btn:focus, .btn-green:focus, #page-garantie.green .line-bg-right-btn:focus,
#page-garantie.green .pdf-dl-btn:focus, .btn-light-blue:focus, #page-garantie.light-blue .line-bg-right-btn:focus,
#page-garantie.light-blue .pdf-dl-btn:focus, .btn-pink:focus, #page-garantie.pink .line-bg-right-btn:focus,
#page-garantie.pink .pdf-dl-btn:focus, .btn-purple:focus, #page-garantie.purple .line-bg-right-btn:focus,
#page-garantie.purple .pdf-dl-btn:focus, .btn-light-grey:focus, .btn-grey:focus, .btn-light-green:focus, .node-type-laureate-list #liste-laureats > li.laureat .video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .project-btn:focus {
  background: white;
  color: #3855a0;
}
/* line 177, ../sass/_mixins.scss */
.contraste .btn:hover, .contraste .btn-orange:hover, .contraste #page-garantie.orange .line-bg-right-btn:hover, #page-garantie.orange .contraste .line-bg-right-btn:hover,
.contraste #page-garantie.orange .pdf-dl-btn:hover,
#page-garantie.orange .contraste .pdf-dl-btn:hover, .contraste .btn-green:hover, .contraste #page-garantie.green .line-bg-right-btn:hover, #page-garantie.green .contraste .line-bg-right-btn:hover,
.contraste #page-garantie.green .pdf-dl-btn:hover,
#page-garantie.green .contraste .pdf-dl-btn:hover, .contraste .btn-light-blue:hover, .contraste #page-garantie.light-blue .line-bg-right-btn:hover, #page-garantie.light-blue .contraste .line-bg-right-btn:hover,
.contraste #page-garantie.light-blue .pdf-dl-btn:hover,
#page-garantie.light-blue .contraste .pdf-dl-btn:hover, .contraste .btn-pink:hover, .contraste #page-garantie.pink .line-bg-right-btn:hover, #page-garantie.pink .contraste .line-bg-right-btn:hover,
.contraste #page-garantie.pink .pdf-dl-btn:hover,
#page-garantie.pink .contraste .pdf-dl-btn:hover, .contraste .btn-purple:hover, .contraste #page-garantie.purple .line-bg-right-btn:hover, #page-garantie.purple .contraste .line-bg-right-btn:hover,
.contraste #page-garantie.purple .pdf-dl-btn:hover,
#page-garantie.purple .contraste .pdf-dl-btn:hover, .contraste .btn-light-grey:hover, .contraste .btn-grey:hover, .contraste .btn-light-green:hover, .contraste .node-type-laureate-list #liste-laureats > li.laureat .video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat .contraste .video-btn:hover,
.contraste .node-type-laureate-list #liste-laureats > li.laureat .project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .contraste .project-btn:hover, .contraste .btn:focus, .contraste .btn-orange:focus, .contraste #page-garantie.orange .line-bg-right-btn:focus, #page-garantie.orange .contraste .line-bg-right-btn:focus,
.contraste #page-garantie.orange .pdf-dl-btn:focus,
#page-garantie.orange .contraste .pdf-dl-btn:focus, .contraste .btn-green:focus, .contraste #page-garantie.green .line-bg-right-btn:focus, #page-garantie.green .contraste .line-bg-right-btn:focus,
.contraste #page-garantie.green .pdf-dl-btn:focus,
#page-garantie.green .contraste .pdf-dl-btn:focus, .contraste .btn-light-blue:focus, .contraste #page-garantie.light-blue .line-bg-right-btn:focus, #page-garantie.light-blue .contraste .line-bg-right-btn:focus,
.contraste #page-garantie.light-blue .pdf-dl-btn:focus,
#page-garantie.light-blue .contraste .pdf-dl-btn:focus, .contraste .btn-pink:focus, .contraste #page-garantie.pink .line-bg-right-btn:focus, #page-garantie.pink .contraste .line-bg-right-btn:focus,
.contraste #page-garantie.pink .pdf-dl-btn:focus,
#page-garantie.pink .contraste .pdf-dl-btn:focus, .contraste .btn-purple:focus, .contraste #page-garantie.purple .line-bg-right-btn:focus, #page-garantie.purple .contraste .line-bg-right-btn:focus,
.contraste #page-garantie.purple .pdf-dl-btn:focus,
#page-garantie.purple .contraste .pdf-dl-btn:focus, .contraste .btn-light-grey:focus, .contraste .btn-grey:focus, .contraste .btn-light-green:focus, .contraste .node-type-laureate-list #liste-laureats > li.laureat .video-btn:focus, .node-type-laureate-list #liste-laureats > li.laureat .contraste .video-btn:focus,
.contraste .node-type-laureate-list #liste-laureats > li.laureat .project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .contraste .project-btn:focus {
  background: white;
  color: #404040;
}
/* line 230, ../sass/_extends.scss */
.contraste .btn, .contraste .btn-orange, .contraste #page-garantie.orange .line-bg-right-btn, #page-garantie.orange .contraste .line-bg-right-btn,
.contraste #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange .contraste .pdf-dl-btn, .contraste .btn-green, .contraste #page-garantie.green .line-bg-right-btn, #page-garantie.green .contraste .line-bg-right-btn,
.contraste #page-garantie.green .pdf-dl-btn,
#page-garantie.green .contraste .pdf-dl-btn, .contraste .btn-light-blue, .contraste #page-garantie.light-blue .line-bg-right-btn, #page-garantie.light-blue .contraste .line-bg-right-btn,
.contraste #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue .contraste .pdf-dl-btn, .contraste .btn-pink, .contraste #page-garantie.pink .line-bg-right-btn, #page-garantie.pink .contraste .line-bg-right-btn,
.contraste #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink .contraste .pdf-dl-btn, .contraste .btn-purple, .contraste #page-garantie.purple .line-bg-right-btn, #page-garantie.purple .contraste .line-bg-right-btn,
.contraste #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple .contraste .pdf-dl-btn, .contraste .btn-light-grey, .contraste .btn-grey, .contraste .btn-light-green, .contraste .node-type-laureate-list #liste-laureats > li.laureat .video-btn, .node-type-laureate-list #liste-laureats > li.laureat .contraste .video-btn,
.contraste .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .contraste .project-btn {
  border-color: #404040;
  background-color: #404040;
}

/* line 236, ../sass/_extends.scss */
.btn-orange, #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .pdf-dl-btn {
  background-color: #EA670A;
  border: 1px solid #EA670A;
}
/* line 241, ../sass/_extends.scss */
.btn-orange:hover, #page-garantie.orange .line-bg-right-btn:hover,
#page-garantie.orange .pdf-dl-btn:hover, .btn-orange:focus, #page-garantie.orange .line-bg-right-btn:focus,
#page-garantie.orange .pdf-dl-btn:focus {
  color: #EA670A;
}

/* line 247, ../sass/_extends.scss */
.btn-green, #page-garantie.green .line-bg-right-btn,
#page-garantie.green .pdf-dl-btn {
  background-color: #36A312;
  border: 1px solid #36A312;
}
/* line 252, ../sass/_extends.scss */
.btn-green:hover, #page-garantie.green .line-bg-right-btn:hover,
#page-garantie.green .pdf-dl-btn:hover, .btn-green:focus, #page-garantie.green .line-bg-right-btn:focus,
#page-garantie.green .pdf-dl-btn:focus {
  color: #36A312;
}

/* line 258, ../sass/_extends.scss */
.btn-light-blue, #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .pdf-dl-btn {
  background-color: #0095CA;
  border: 1px solid #0095CA;
}
/* line 263, ../sass/_extends.scss */
.btn-light-blue:hover, #page-garantie.light-blue .line-bg-right-btn:hover,
#page-garantie.light-blue .pdf-dl-btn:hover, .btn-light-blue:focus, #page-garantie.light-blue .line-bg-right-btn:focus,
#page-garantie.light-blue .pdf-dl-btn:focus {
  color: #0095CA;
}

/* line 269, ../sass/_extends.scss */
.btn-pink, #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .pdf-dl-btn {
  background-color: #E40070;
  border: 1px solid #E40070;
}
/* line 274, ../sass/_extends.scss */
.btn-pink:hover, #page-garantie.pink .line-bg-right-btn:hover,
#page-garantie.pink .pdf-dl-btn:hover, .btn-pink:focus, #page-garantie.pink .line-bg-right-btn:focus,
#page-garantie.pink .pdf-dl-btn:focus {
  color: #E40070;
}

/* line 280, ../sass/_extends.scss */
.btn-purple, #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .pdf-dl-btn {
  background-color: #6C1CAE;
  border: 1px solid #6C1CAE;
}
/* line 285, ../sass/_extends.scss */
.btn-purple:hover, #page-garantie.purple .line-bg-right-btn:hover,
#page-garantie.purple .pdf-dl-btn:hover, .btn-purple:focus, #page-garantie.purple .line-bg-right-btn:focus,
#page-garantie.purple .pdf-dl-btn:focus {
  color: #6C1CAE;
}

/* line 291, ../sass/_extends.scss */
.btn-light-grey {
  background-color: #f3f2f1 !important;
  border: 1px solid #f3f2f1 !important;
  color: #404040 !important;
}
/* line 297, ../sass/_extends.scss */
.btn-light-grey:hover, .btn-light-grey:focus {
  color: #a0a0a0 !important;
}

/* line 303, ../sass/_extends.scss */
.btn-grey {
  background-color: #404040 !important;
  border: 1px solid #404040 !important;
}
/* line 308, ../sass/_extends.scss */
.btn-grey:hover, .btn-grey:focus {
  color: #404040 !important;
}

/* line 314, ../sass/_extends.scss */
.btn-light-green {
  background-color: #75c200;
  border: 1px solid #75c200;
}
/* line 319, ../sass/_extends.scss */
.btn-light-green:hover, .btn-light-green:focus {
  color: #75c200;
}

/*------------------------------------------------
- Ici, tous les styles propres aux pages.
- Dans cette partie, on cible les pages pour attribuer des styles
------------------------------------------------*/
/*==============================*/
/*PAGE CHART*/
/*==============================*/
/* line 13, ../sass/_pages.scss */
#page-chart .row-3 .col {
  float: left;
  direction: ltr;
  padding-left: 10px;
  padding-right: 10px;
  width: 33.33333%;
}
@media screen and (max-width: 320px) {
  /* line 13, ../sass/_pages.scss */
  #page-chart .row-3 .col {
    width: 100%;
  }
}

/*==============================*/
/*PAGE HOME*/
/*==============================*/
/* line 27, ../sass/_pages.scss */
.front .breadcrumb {
  padding: 0;
  margin-top: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 27, ../sass/_pages.scss */
  .front .breadcrumb {
    margin-top: 97px;
  }
}

/* line 40, ../sass/_pages.scss */
#page-hp .hp-title {
  padding: 15px;
  margin: 0;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1.2;
  color: #3855a0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 40, ../sass/_pages.scss */
  #page-hp .hp-title {
    font-size: 1.375em;
  }
}
/* line 53, ../sass/_pages.scss */
#page-hp .situation-block {
  margin-bottom: 40px;
}
/* line 57, ../sass/_pages.scss */
#page-hp .top-slider-container {
  background: none;
  		/*&.step-1,
  		&.step-2 {
  
  			@include bp($mobile, $desktop) {
  
  				img {
  					display: none;
  				}
  			}
  		}*/
}
/* line 60, ../sass/_pages.scss */
#page-hp .top-slider-container.grid-rich-slider {
  padding: 0;
  margin: 0;
}
/* line 65, ../sass/_pages.scss */
#page-hp .top-slider-container .bx-viewport {
  height: auto !important;
}
/* line 83, ../sass/_pages.scss */
#page-hp .grey-bg .top-slider-container {
  padding: 36px 0;
}
/* line 88, ../sass/_pages.scss */
#page-hp .grid-list {
  margin-bottom: 60px;
  text-align: center;
}
/* line 93, ../sass/_pages.scss */
#page-hp .grid-list .grid {
  width: 100%;
  margin-bottom: 18px;
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 93, ../sass/_pages.scss */
  #page-hp .grid-list .grid {
    margin-right: 0 !important;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 93, ../sass/_pages.scss */
  #page-hp .grid-list .grid {
    display: inline-block;
    vertical-align: top;
    width: 329px;
  }
  /* line 106, ../sass/_pages.scss */
  #page-hp .grid-list .grid.grid-two, #page-hp .grid-list .grid.grid-four {
    width: 676px;
  }
  /* line 111, ../sass/_pages.scss */
  #page-hp .grid-list .grid.grid-three {
    width: 100%;
  }
}
/* line 119, ../sass/_pages.scss */
#page-hp .carrousel-bleu .top-slider-container .next-control button.next, #page-hp .carrousel-bleu .top-slider-container button.prev {
  color: #3855a0;
}
/* line 122, ../sass/_pages.scss */
.contraste #page-hp .carrousel-bleu .top-slider-container .next-control button.next, .contraste #page-hp .carrousel-bleu .top-slider-container button.prev {
  color: #a0a0a0;
}

/*==============================*/
/*PAGE ARTICLE*/
/*==============================*/
/* line 135, ../sass/_pages.scss */
#page-article .padding-mobile {
  padding: 0 4px;
}
/* line 139, ../sass/_pages.scss */
#page-article h1.double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 143, ../sass/_pages.scss */
#page-article .text-intro {
  text-align: justify;
  margin-bottom: 25px;
  padding: 0 10px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 143, ../sass/_pages.scss */
  #page-article .text-intro {
    padding: 0;
  }
}
/* line 153, ../sass/_pages.scss */
#page-article .wysiwyg {
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
#page-article .wysiwyg:before, #page-article .wysiwyg:after {
  content: "";
  display: table;
  clear: both;
}
/* line 157, ../sass/_pages.scss */
#page-article .notation-block {
  margin: 100px 0 50px 0;
}

/*==============================*/
/*PAGE THEMATIQUE*/
/*==============================*/
/* line 169, ../sass/_pages.scss */
#page-thematique .padding-mobile {
  padding: 0 4px;
}
/* line 173, ../sass/_pages.scss */
#page-thematique h1.double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 177, ../sass/_pages.scss */
#page-thematique .thematique-intro {
  padding: 0 30px;
  color: #3855a0;
}
/* line 182, ../sass/_pages.scss */
#page-thematique .infographie-block {
  margin: 0;
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 182, ../sass/_pages.scss */
  #page-thematique .infographie-block {
    background-size: contain !important;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 182, ../sass/_pages.scss */
  #page-thematique .infographie-block {
    margin: 0 35px;
  }
}
/* line 194, ../sass/_pages.scss */
#page-thematique .guide-block {
  margin: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 194, ../sass/_pages.scss */
  #page-thematique .guide-block {
    margin: 55px 0 45px 0;
  }
}
/* line 202, ../sass/_pages.scss */
#page-thematique .situation-block {
  margin-bottom: 75px;
}
/* line 206, ../sass/_pages.scss */
#page-thematique .scoopit-btn-container {
  margin: 30px 0 55px 0;
  text-align: center;
}
/* line 211, ../sass/_pages.scss */
#page-thematique .see-all-actu-link {
  margin-top: 30px;
}
/* line 217, ../sass/_pages.scss */
#page-thematique .triple-slider .slide-title {
  font-size: 1.25em;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 217, ../sass/_pages.scss */
  #page-thematique .triple-slider .slide-title {
    font-size: 1.5625em;
  }
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.blue .double-line-title {
  color: #3855a0;
  border-color: #3855a0;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.blue .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-title-text {
  color: #3855a0;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-block {
  background: rgba(56, 85, 160, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-small-btn {
  background-color: #3855a0;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-title {
  color: #3855a0;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-btn {
  background: #3855a0;
  border: 1px solid #3855a0;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.blue .pdf-dl-btn:focus, #page-thematique.blue .pdf-dl-btn:hover {
  background: white;
  color: #3855a0;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.blue .pdf-dl-btn:focus, .contraste #page-thematique.blue .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.green .double-line-title {
  color: #36A312;
  border-color: #36A312;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.green .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-title-text {
  color: #36A312;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-block {
  background: rgba(54, 163, 18, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-small-btn {
  background-color: #36A312;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-title {
  color: #36A312;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-btn {
  background: #36A312;
  border: 1px solid #36A312;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.green .pdf-dl-btn:focus, #page-thematique.green .pdf-dl-btn:hover {
  background: white;
  color: #36A312;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.green .pdf-dl-btn:focus, .contraste #page-thematique.green .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.orange .double-line-title {
  color: #EA670A;
  border-color: #EA670A;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.orange .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-title-text {
  color: #EA670A;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-block {
  background: rgba(234, 103, 10, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-small-btn {
  background-color: #EA670A;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-title {
  color: #EA670A;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-btn {
  background: #EA670A;
  border: 1px solid #EA670A;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.orange .pdf-dl-btn:focus, #page-thematique.orange .pdf-dl-btn:hover {
  background: white;
  color: #EA670A;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.orange .pdf-dl-btn:focus, .contraste #page-thematique.orange .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.pink .double-line-title {
  color: #E40070;
  border-color: #E40070;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.pink .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-title-text {
  color: #E40070;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-block {
  background: rgba(228, 0, 112, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-small-btn {
  background-color: #E40070;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-title {
  color: #E40070;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-btn {
  background: #E40070;
  border: 1px solid #E40070;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.pink .pdf-dl-btn:focus, #page-thematique.pink .pdf-dl-btn:hover {
  background: white;
  color: #E40070;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.pink .pdf-dl-btn:focus, .contraste #page-thematique.pink .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.purple .double-line-title {
  color: #6C1CAE;
  border-color: #6C1CAE;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.purple .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-title-text {
  color: #6C1CAE;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-block {
  background: rgba(108, 28, 174, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-small-btn {
  background-color: #6C1CAE;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-title {
  color: #6C1CAE;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-btn {
  background: #6C1CAE;
  border: 1px solid #6C1CAE;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.purple .pdf-dl-btn:focus, #page-thematique.purple .pdf-dl-btn:hover {
  background: white;
  color: #6C1CAE;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.purple .pdf-dl-btn:focus, .contraste #page-thematique.purple .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}
/* line 364, ../sass/_mixins.scss */
#page-thematique.light-blue .double-line-title {
  color: #0095CA;
  border-color: #0095CA;
}
/* line 368, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 374, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-title-text {
  color: #0095CA;
}
/* line 377, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-title-text {
  color: #404040;
}
/* line 382, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-block {
  background: rgba(0, 149, 202, 0.3);
}
/* line 386, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-block {
  background: #f3f2f1;
}
/* line 391, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-small-btn {
  background-color: #0095CA;
}
/* line 394, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 399, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-title {
  color: #0095CA;
}
/* line 402, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-title {
  color: #404040;
}
/* line 407, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-btn {
  background: #0095CA;
  border: 1px solid #0095CA;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* line 413, ../sass/_mixins.scss */
#page-thematique.light-blue .pdf-dl-btn:focus, #page-thematique.light-blue .pdf-dl-btn:hover {
  background: white;
  color: #0095CA;
}
/* line 419, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-btn {
  background: #404040;
  border-color: #404040;
}
/* line 423, ../sass/_mixins.scss */
.contraste #page-thematique.light-blue .pdf-dl-btn:focus, .contraste #page-thematique.light-blue .pdf-dl-btn:hover {
  background: white;
  color: #404040;
}

/* line 251, ../sass/_pages.scss */
.infographie-transcription {
  margin-top: 40px;
}

/* line 255, ../sass/_pages.scss */
.infographie-modale-content {
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
.infographie-modale-content:before, .infographie-modale-content:after {
  content: "";
  display: table;
  clear: both;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 258, ../sass/_pages.scss */
  .infographie-modale-content .modale-column {
    float: left;
    width: 50%;
    padding: 20px;
  }
}

/*==============================*/
/*PAGE GARANTIE*/
/*==============================*/
/* line 274, ../sass/_pages.scss */
.node-type-warranty .page-header-wrapper .mobile-special-menu-btn {
  display: block;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 274, ../sass/_pages.scss */
  .node-type-warranty .page-header-wrapper .mobile-special-menu-btn {
    display: none;
  }
}

/* line 284, ../sass/_pages.scss */
#page-garantie {
  /* COLORS */
}
/* line 286, ../sass/_pages.scss */
#page-garantie .wysiwyg {
  margin-bottom: 25px;
}
/* line 290, ../sass/_pages.scss */
#page-garantie .garantie-nav {
  padding: 25px 0;
}
/* line 293, ../sass/_pages.scss */
#page-garantie .garantie-nav .title {
  position: relative;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5625em;
  line-height: 1.2;
  line-height: 50px;
  color: #808080;
  vertical-align: middle;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 293, ../sass/_pages.scss */
  #page-garantie .garantie-nav .title {
    display: inline-block;
    margin-right: 15px;
    line-height: 1.5625rem;
    vertical-align: middle;
  }
}
/* line 309, ../sass/_pages.scss */
#page-garantie .garantie-nav .title:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 60px;
  height: 44px;
  background: url('../images/grantie-menu-btn.png?1633011802') no-repeat center center;
  vertical-align: middle;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 309, ../sass/_pages.scss */
  #page-garantie .garantie-nav .title:before {
    position: absolute;
    left: -75px;
    top: -10px;
  }
}
/* line 326, ../sass/_pages.scss */
#page-garantie .garantie-nav .garantie-link-list {
  width: 100%;
  margin: 15px 0 0 0;
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 326, ../sass/_pages.scss */
  #page-garantie .garantie-nav .garantie-link-list {
    display: inline-block;
    margin: 0;
    width: auto;
    vertical-align: middle;
    text-align: left;
    letter-spacing: -0.25em;
  }
}
/* line 340, ../sass/_pages.scss */
#page-garantie .garantie-nav .garantie-link-list li {
  display: block;
  margin-bottom: 10px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 340, ../sass/_pages.scss */
  #page-garantie .garantie-nav .garantie-link-list li {
    display: inline-block;
    margin: 0;
    letter-spacing: normal;
  }
}
/* line 352, ../sass/_pages.scss */
#page-garantie .garantie-nav .garantie-link {
  padding: 5px 20px;
  border-radius: 15px;
  font-size: 1.25em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 165px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 352, ../sass/_pages.scss */
  #page-garantie .garantie-nav .garantie-link {
    min-width: inherit;
    margin-right: 10px;
  }
}
/* line 368, ../sass/_pages.scss */
#page-garantie .accordion-container {
  margin: 25px 0;
}
/* line 372, ../sass/_pages.scss */
#page-garantie .pdf-dl-block {
  margin-bottom: 35px;
}
/* line 376, ../sass/_pages.scss */
#page-garantie .situation-block {
  margin: 30px 0 60px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 382, ../sass/_pages.scss */
  #page-garantie .pdf-dl-img {
    width: 25%;
  }
  /* line 386, ../sass/_pages.scss */
  #page-garantie .pdf-dl-title,
  #page-garantie .pdf-dl-intro,
  #page-garantie .pdf-dl-text {
    width: 73%;
  }
  /* line 392, ../sass/_pages.scss */
  #page-garantie .situation-block {
    margin: 55px 0 75px 0;
  }
}
/* line 406, ../sass/_pages.scss */
#page-garantie.orange .pdf-dl-btn {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 290, ../sass/_mixins.scss */
#page-garantie.orange .double-line-title {
  color: #EA670A;
  border-color: #EA670A;
}
/* line 294, ../sass/_mixins.scss */
.contraste #page-garantie.orange .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 302, ../sass/_mixins.scss */
#page-garantie.orange .accordion-title .line-bg-txt {
  color: #EA670A;
}
/* line 305, ../sass/_mixins.scss */
.contraste #page-garantie.orange .accordion-title .line-bg-txt {
  color: #404040;
}
/* line 310, ../sass/_mixins.scss */
#page-garantie.orange .accordion-title .line-bg-line {
  background: #EA670A;
}
/* line 313, ../sass/_mixins.scss */
.contraste #page-garantie.orange .accordion-title .line-bg-line {
  background: #404040;
}
/* line 319, ../sass/_mixins.scss */
#page-garantie.orange .pdf-dl-block,
#page-garantie.orange .accordion-block li.even {
  background: rgba(234, 103, 10, 0.3);
}
/* line 324, ../sass/_mixins.scss */
.contraste #page-garantie.orange .pdf-dl-block, .contraste
#page-garantie.orange .accordion-block li.even {
  background: #f3f2f1;
}
/* line 329, ../sass/_mixins.scss */
#page-garantie.orange .pdf-dl-small-btn {
  background-color: #EA670A;
}
/* line 332, ../sass/_mixins.scss */
.contraste #page-garantie.orange .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 337, ../sass/_mixins.scss */
#page-garantie.orange .accordion-btn,
#page-garantie.orange .pdf-dl-title-text {
  color: #EA670A;
}
/* line 341, ../sass/_mixins.scss */
.contraste #page-garantie.orange .accordion-btn, .contraste
#page-garantie.orange .pdf-dl-title-text {
  color: #404040;
}
/* line 347, ../sass/_mixins.scss */
#page-garantie.orange .wysiwyg h2,
#page-garantie.orange .wysiwyg h3,
#page-garantie.orange .wysiwyg h4,
#page-garantie.orange .wysiwyg a,
#page-garantie.orange .wysiwyg .arrow-list li:before,
#page-garantie.orange .wysiwyg .bullet-list li:before,
#page-garantie.orange .wysiwyg .node-type-laureate .wysiwyg-container ul li:before,
.node-type-laureate .wysiwyg-container #page-garantie.orange .wysiwyg ul li:before,
#page-garantie.orange .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before,
.node-type-laureate-list #liste-laureats > li .description #page-garantie.orange .wysiwyg ul li:before {
  color: #EA670A;
}
/* line 355, ../sass/_mixins.scss */
.contraste #page-garantie.orange .wysiwyg h2, .contraste
#page-garantie.orange .wysiwyg h3, .contraste
#page-garantie.orange .wysiwyg h4, .contraste
#page-garantie.orange .wysiwyg a, .contraste
#page-garantie.orange .wysiwyg .arrow-list li:before, .contraste
#page-garantie.orange .wysiwyg .bullet-list li:before, .contraste
#page-garantie.orange .wysiwyg .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container .contraste
#page-garantie.orange .wysiwyg ul li:before, .contraste
#page-garantie.orange .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description .contraste
#page-garantie.orange .wysiwyg ul li:before {
  color: #404040;
}
/* line 290, ../sass/_mixins.scss */
#page-garantie.pink .double-line-title {
  color: #E40070;
  border-color: #E40070;
}
/* line 294, ../sass/_mixins.scss */
.contraste #page-garantie.pink .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 302, ../sass/_mixins.scss */
#page-garantie.pink .accordion-title .line-bg-txt {
  color: #E40070;
}
/* line 305, ../sass/_mixins.scss */
.contraste #page-garantie.pink .accordion-title .line-bg-txt {
  color: #404040;
}
/* line 310, ../sass/_mixins.scss */
#page-garantie.pink .accordion-title .line-bg-line {
  background: #E40070;
}
/* line 313, ../sass/_mixins.scss */
.contraste #page-garantie.pink .accordion-title .line-bg-line {
  background: #404040;
}
/* line 319, ../sass/_mixins.scss */
#page-garantie.pink .pdf-dl-block,
#page-garantie.pink .accordion-block li.even {
  background: rgba(228, 0, 112, 0.3);
}
/* line 324, ../sass/_mixins.scss */
.contraste #page-garantie.pink .pdf-dl-block, .contraste
#page-garantie.pink .accordion-block li.even {
  background: #f3f2f1;
}
/* line 329, ../sass/_mixins.scss */
#page-garantie.pink .pdf-dl-small-btn {
  background-color: #E40070;
}
/* line 332, ../sass/_mixins.scss */
.contraste #page-garantie.pink .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 337, ../sass/_mixins.scss */
#page-garantie.pink .accordion-btn,
#page-garantie.pink .pdf-dl-title-text {
  color: #E40070;
}
/* line 341, ../sass/_mixins.scss */
.contraste #page-garantie.pink .accordion-btn, .contraste
#page-garantie.pink .pdf-dl-title-text {
  color: #404040;
}
/* line 347, ../sass/_mixins.scss */
#page-garantie.pink .wysiwyg h2,
#page-garantie.pink .wysiwyg h3,
#page-garantie.pink .wysiwyg h4,
#page-garantie.pink .wysiwyg a,
#page-garantie.pink .wysiwyg .arrow-list li:before,
#page-garantie.pink .wysiwyg .bullet-list li:before,
#page-garantie.pink .wysiwyg .node-type-laureate .wysiwyg-container ul li:before,
.node-type-laureate .wysiwyg-container #page-garantie.pink .wysiwyg ul li:before,
#page-garantie.pink .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before,
.node-type-laureate-list #liste-laureats > li .description #page-garantie.pink .wysiwyg ul li:before {
  color: #E40070;
}
/* line 355, ../sass/_mixins.scss */
.contraste #page-garantie.pink .wysiwyg h2, .contraste
#page-garantie.pink .wysiwyg h3, .contraste
#page-garantie.pink .wysiwyg h4, .contraste
#page-garantie.pink .wysiwyg a, .contraste
#page-garantie.pink .wysiwyg .arrow-list li:before, .contraste
#page-garantie.pink .wysiwyg .bullet-list li:before, .contraste
#page-garantie.pink .wysiwyg .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container .contraste
#page-garantie.pink .wysiwyg ul li:before, .contraste
#page-garantie.pink .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description .contraste
#page-garantie.pink .wysiwyg ul li:before {
  color: #404040;
}
/* line 290, ../sass/_mixins.scss */
#page-garantie.purple .double-line-title {
  color: #6C1CAE;
  border-color: #6C1CAE;
}
/* line 294, ../sass/_mixins.scss */
.contraste #page-garantie.purple .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 302, ../sass/_mixins.scss */
#page-garantie.purple .accordion-title .line-bg-txt {
  color: #6C1CAE;
}
/* line 305, ../sass/_mixins.scss */
.contraste #page-garantie.purple .accordion-title .line-bg-txt {
  color: #404040;
}
/* line 310, ../sass/_mixins.scss */
#page-garantie.purple .accordion-title .line-bg-line {
  background: #6C1CAE;
}
/* line 313, ../sass/_mixins.scss */
.contraste #page-garantie.purple .accordion-title .line-bg-line {
  background: #404040;
}
/* line 319, ../sass/_mixins.scss */
#page-garantie.purple .pdf-dl-block,
#page-garantie.purple .accordion-block li.even {
  background: rgba(108, 28, 174, 0.3);
}
/* line 324, ../sass/_mixins.scss */
.contraste #page-garantie.purple .pdf-dl-block, .contraste
#page-garantie.purple .accordion-block li.even {
  background: #f3f2f1;
}
/* line 329, ../sass/_mixins.scss */
#page-garantie.purple .pdf-dl-small-btn {
  background-color: #6C1CAE;
}
/* line 332, ../sass/_mixins.scss */
.contraste #page-garantie.purple .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 337, ../sass/_mixins.scss */
#page-garantie.purple .accordion-btn,
#page-garantie.purple .pdf-dl-title-text {
  color: #6C1CAE;
}
/* line 341, ../sass/_mixins.scss */
.contraste #page-garantie.purple .accordion-btn, .contraste
#page-garantie.purple .pdf-dl-title-text {
  color: #404040;
}
/* line 347, ../sass/_mixins.scss */
#page-garantie.purple .wysiwyg h2,
#page-garantie.purple .wysiwyg h3,
#page-garantie.purple .wysiwyg h4,
#page-garantie.purple .wysiwyg a,
#page-garantie.purple .wysiwyg .arrow-list li:before,
#page-garantie.purple .wysiwyg .bullet-list li:before,
#page-garantie.purple .wysiwyg .node-type-laureate .wysiwyg-container ul li:before,
.node-type-laureate .wysiwyg-container #page-garantie.purple .wysiwyg ul li:before,
#page-garantie.purple .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before,
.node-type-laureate-list #liste-laureats > li .description #page-garantie.purple .wysiwyg ul li:before {
  color: #6C1CAE;
}
/* line 355, ../sass/_mixins.scss */
.contraste #page-garantie.purple .wysiwyg h2, .contraste
#page-garantie.purple .wysiwyg h3, .contraste
#page-garantie.purple .wysiwyg h4, .contraste
#page-garantie.purple .wysiwyg a, .contraste
#page-garantie.purple .wysiwyg .arrow-list li:before, .contraste
#page-garantie.purple .wysiwyg .bullet-list li:before, .contraste
#page-garantie.purple .wysiwyg .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container .contraste
#page-garantie.purple .wysiwyg ul li:before, .contraste
#page-garantie.purple .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description .contraste
#page-garantie.purple .wysiwyg ul li:before {
  color: #404040;
}
/* line 290, ../sass/_mixins.scss */
#page-garantie.green .double-line-title {
  color: #36A312;
  border-color: #36A312;
}
/* line 294, ../sass/_mixins.scss */
.contraste #page-garantie.green .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 302, ../sass/_mixins.scss */
#page-garantie.green .accordion-title .line-bg-txt {
  color: #36A312;
}
/* line 305, ../sass/_mixins.scss */
.contraste #page-garantie.green .accordion-title .line-bg-txt {
  color: #404040;
}
/* line 310, ../sass/_mixins.scss */
#page-garantie.green .accordion-title .line-bg-line {
  background: #36A312;
}
/* line 313, ../sass/_mixins.scss */
.contraste #page-garantie.green .accordion-title .line-bg-line {
  background: #404040;
}
/* line 319, ../sass/_mixins.scss */
#page-garantie.green .pdf-dl-block,
#page-garantie.green .accordion-block li.even {
  background: rgba(54, 163, 18, 0.3);
}
/* line 324, ../sass/_mixins.scss */
.contraste #page-garantie.green .pdf-dl-block, .contraste
#page-garantie.green .accordion-block li.even {
  background: #f3f2f1;
}
/* line 329, ../sass/_mixins.scss */
#page-garantie.green .pdf-dl-small-btn {
  background-color: #36A312;
}
/* line 332, ../sass/_mixins.scss */
.contraste #page-garantie.green .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 337, ../sass/_mixins.scss */
#page-garantie.green .accordion-btn,
#page-garantie.green .pdf-dl-title-text {
  color: #36A312;
}
/* line 341, ../sass/_mixins.scss */
.contraste #page-garantie.green .accordion-btn, .contraste
#page-garantie.green .pdf-dl-title-text {
  color: #404040;
}
/* line 347, ../sass/_mixins.scss */
#page-garantie.green .wysiwyg h2,
#page-garantie.green .wysiwyg h3,
#page-garantie.green .wysiwyg h4,
#page-garantie.green .wysiwyg a,
#page-garantie.green .wysiwyg .arrow-list li:before,
#page-garantie.green .wysiwyg .bullet-list li:before,
#page-garantie.green .wysiwyg .node-type-laureate .wysiwyg-container ul li:before,
.node-type-laureate .wysiwyg-container #page-garantie.green .wysiwyg ul li:before,
#page-garantie.green .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before,
.node-type-laureate-list #liste-laureats > li .description #page-garantie.green .wysiwyg ul li:before {
  color: #36A312;
}
/* line 355, ../sass/_mixins.scss */
.contraste #page-garantie.green .wysiwyg h2, .contraste
#page-garantie.green .wysiwyg h3, .contraste
#page-garantie.green .wysiwyg h4, .contraste
#page-garantie.green .wysiwyg a, .contraste
#page-garantie.green .wysiwyg .arrow-list li:before, .contraste
#page-garantie.green .wysiwyg .bullet-list li:before, .contraste
#page-garantie.green .wysiwyg .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container .contraste
#page-garantie.green .wysiwyg ul li:before, .contraste
#page-garantie.green .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description .contraste
#page-garantie.green .wysiwyg ul li:before {
  color: #404040;
}
/* line 290, ../sass/_mixins.scss */
#page-garantie.light-blue .double-line-title {
  color: #0095CA;
  border-color: #0095CA;
}
/* line 294, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 302, ../sass/_mixins.scss */
#page-garantie.light-blue .accordion-title .line-bg-txt {
  color: #0095CA;
}
/* line 305, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .accordion-title .line-bg-txt {
  color: #404040;
}
/* line 310, ../sass/_mixins.scss */
#page-garantie.light-blue .accordion-title .line-bg-line {
  background: #0095CA;
}
/* line 313, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .accordion-title .line-bg-line {
  background: #404040;
}
/* line 319, ../sass/_mixins.scss */
#page-garantie.light-blue .pdf-dl-block,
#page-garantie.light-blue .accordion-block li.even {
  background: rgba(0, 149, 202, 0.3);
}
/* line 324, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .pdf-dl-block, .contraste
#page-garantie.light-blue .accordion-block li.even {
  background: #f3f2f1;
}
/* line 329, ../sass/_mixins.scss */
#page-garantie.light-blue .pdf-dl-small-btn {
  background-color: #0095CA;
}
/* line 332, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .pdf-dl-small-btn {
  background-color: #404040;
}
/* line 337, ../sass/_mixins.scss */
#page-garantie.light-blue .accordion-btn,
#page-garantie.light-blue .pdf-dl-title-text {
  color: #0095CA;
}
/* line 341, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .accordion-btn, .contraste
#page-garantie.light-blue .pdf-dl-title-text {
  color: #404040;
}
/* line 347, ../sass/_mixins.scss */
#page-garantie.light-blue .wysiwyg h2,
#page-garantie.light-blue .wysiwyg h3,
#page-garantie.light-blue .wysiwyg h4,
#page-garantie.light-blue .wysiwyg a,
#page-garantie.light-blue .wysiwyg .arrow-list li:before,
#page-garantie.light-blue .wysiwyg .bullet-list li:before,
#page-garantie.light-blue .wysiwyg .node-type-laureate .wysiwyg-container ul li:before,
.node-type-laureate .wysiwyg-container #page-garantie.light-blue .wysiwyg ul li:before,
#page-garantie.light-blue .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before,
.node-type-laureate-list #liste-laureats > li .description #page-garantie.light-blue .wysiwyg ul li:before {
  color: #0095CA;
}
/* line 355, ../sass/_mixins.scss */
.contraste #page-garantie.light-blue .wysiwyg h2, .contraste
#page-garantie.light-blue .wysiwyg h3, .contraste
#page-garantie.light-blue .wysiwyg h4, .contraste
#page-garantie.light-blue .wysiwyg a, .contraste
#page-garantie.light-blue .wysiwyg .arrow-list li:before, .contraste
#page-garantie.light-blue .wysiwyg .bullet-list li:before, .contraste
#page-garantie.light-blue .wysiwyg .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container .contraste
#page-garantie.light-blue .wysiwyg ul li:before, .contraste
#page-garantie.light-blue .wysiwyg .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description .contraste
#page-garantie.light-blue .wysiwyg ul li:before {
  color: #404040;
}

/* Block title */
/* line 459, ../sass/_pages.scss */
.garantie-title {
  padding: 5px;
  margin-bottom: 30px;
  background: #f3f2f1;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 459, ../sass/_pages.scss */
  .garantie-title {
    *zoom: 1;
    padding: 0;
  }
  /* line 41, ../sass/_mixins.scss */
  .garantie-title:before, .garantie-title:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* line 469, ../sass/_pages.scss */
.garantie-title .left-part {
  width: 100%;
  height: 245px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 469, ../sass/_pages.scss */
  .garantie-title .left-part {
    float: left;
    width: 50%;
    height: 265px;
    background-position: left center !important;
  }
}
/* line 483, ../sass/_pages.scss */
.garantie-title .right-part {
  padding: 20px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 487, ../sass/_pages.scss */
  .garantie-title .right-part:before {
    min-height: 245px;
  }
}
/* line 494, ../sass/_pages.scss */
.garantie-title .right-part .title-center {
  width: 100%;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 483, ../sass/_pages.scss */
  .garantie-title .right-part {
    float: left;
    width: 50%;
    padding: 10px 50px;
  }
}
/* line 504, ../sass/_pages.scss */
.garantie-title .right-part .double-line-title {
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0 0 20px;
}
/* line 509, ../sass/_pages.scss */
#page-garantie-veuvage .garantie-title .right-part .double-line-title {
  color: #EA670A;
  border-color: #EA670A;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 517, ../sass/_pages.scss */
  .garantie-title .right-part .line-bg-right:before {
    display: none;
  }
  /* line 520, ../sass/_pages.scss */
  .garantie-title .right-part .line-bg-right .line-bg-right-btn {
    float: none;
  }
}

/*==============================*/
/*PAGE HP ACTUS*/
/*==============================*/
/* line 534, ../sass/_pages.scss */
#page-hp-actus .double-line-title {
  margin-bottom: 0;
  border-bottom: none;
  border-color: #404040;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #404040;
}
/* line 544, ../sass/_pages.scss */
#page-hp-actus .top-actu-slider .slide-content,
#page-hp-actus .top-actu-slider .line-bg-txt {
  background: #f3f2f1;
}
/* line 549, ../sass/_pages.scss */
#page-hp-actus .top-actu-slider button.next, #page-hp-actus .top-actu-slider button.prev {
  color: #3855a0;
}
/* line 552, ../sass/_pages.scss */
#page-hp-actus .top-actu-slider button.next:hover, #page-hp-actus .top-actu-slider button.next:focus, #page-hp-actus .top-actu-slider button.prev:hover, #page-hp-actus .top-actu-slider button.prev:focus {
  color: #3855a0;
}
/* line 556, ../sass/_pages.scss */
#page-hp-actus .top-actu-slider button.next:hover:before, #page-hp-actus .top-actu-slider button.next:focus:before, #page-hp-actus .top-actu-slider button.prev:hover:before, #page-hp-actus .top-actu-slider button.prev:focus:before {
  color: #3855a0;
}
/* line 563, ../sass/_pages.scss */
#page-hp-actus .top-slider .bx-viewport {
  height: auto !important;
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 567, ../sass/_pages.scss */
  #page-hp-actus .all-actu-list .slide-title {
    font-size: 1.25em;
  }
}
/* line 573, ../sass/_pages.scss */
#page-hp-actus .actu-filters {
  margin-bottom: 50px;
}
/* line 577, ../sass/_pages.scss */
#page-hp-actus .all-actu-container,
#page-hp-actus .scoopit-container {
  margin-bottom: 0;
}
/* line 584, ../sass/_pages.scss */
#page-hp-actus .all-actu-container .search-input-container {
  display: none;
  margin: 0 auto 15px auto;
}
@media print, screen and (min-width: 48em) {
  /* line 584, ../sass/_pages.scss */
  #page-hp-actus .all-actu-container .search-input-container {
    display: block;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 584, ../sass/_pages.scss */
  #page-hp-actus .all-actu-container .search-input-container {
    max-width: 530px;
  }
}
/* line 598, ../sass/_pages.scss */
#page-hp-actus .all-actu .slide .slide-title {
  font-size: 1.5625em;
}
/* line 602, ../sass/_pages.scss */
#page-hp-actus .grey-bg.first {
  padding-bottom: 15px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 602, ../sass/_pages.scss */
  #page-hp-actus .grey-bg.first {
    padding-bottom: 0;
  }
}
/* line 610, ../sass/_pages.scss */
#page-hp-actus .grey-bg.second {
  margin-top: 20px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 610, ../sass/_pages.scss */
  #page-hp-actus .grey-bg.second {
    margin-top: 0;
  }
}
/* line 618, ../sass/_pages.scss */
#page-hp-actus .twitter-widget {
  padding-bottom: 30px;
  margin-bottom: 2.5em;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 618, ../sass/_pages.scss */
  #page-hp-actus .twitter-widget {
    padding-bottom: 65px;
  }
}

/*==============================*/
/*PAGE ACTU*/
/*==============================*/
/* line 632, ../sass/_pages.scss */
#page-actu {
  /* COMMUNIQUE */
  /* SCOOPIT */
  /* DOSSIERS */
  /* COLORS */
}
/* line 634, ../sass/_pages.scss */
#page-actu .padding-mobile {
  padding: 0 4px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 634, ../sass/_pages.scss */
  #page-actu .padding-mobile {
    padding: 0;
  }
}
/* line 642, ../sass/_pages.scss */
#page-actu .double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 646, ../sass/_pages.scss */
  #page-actu .communique-intro {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  #page-actu .communique-intro:before, #page-actu .communique-intro:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* line 654, ../sass/_pages.scss */
#page-actu .communique-block {
  padding: 13px 10px;
  margin-bottom: 20px;
  background: #3855a0;
  color: white;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
}
/* line 662, ../sass/_pages.scss */
.contraste #page-actu .communique-block {
  background: #404040;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 654, ../sass/_pages.scss */
  #page-actu .communique-block {
    float: left;
    display: inline-block;
    padding: 15px 25px;
    margin: 0 15px 15px 0;
    text-align: left;
  }
}
/* line 674, ../sass/_pages.scss */
#page-actu .communique-block .communique-title {
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.8125em;
  line-height: 1.2;
}
/* line 682, ../sass/_pages.scss */
#page-actu .text-intro {
  margin-bottom: 25px;
  font-weight: bold;
  text-align: left;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 682, ../sass/_pages.scss */
  #page-actu .text-intro {
    padding: 0;
  }
}
/* line 693, ../sass/_pages.scss */
#page-actu .publish-date {
  margin-top: 25px;
  font-size: 0.875em;
  line-height: 1;
  color: #b0b0b0;
}
/* line 700, ../sass/_pages.scss */
.contraste #page-actu .publish-date {
  color: #404040;
}
/* line 705, ../sass/_pages.scss */
#page-actu .notation-block {
  margin: 30px 0 55px 0;
}
/* line 709, ../sass/_pages.scss */
#page-actu .scoopit-container {
  margin: 50px 0 0 0;
}
/* line 713, ../sass/_pages.scss */
#page-actu .twitter-widget {
  margin-bottom: 70px;
}
/* line 722, ../sass/_pages.scss */
#page-actu.communique .triple-slider-container {
  margin-bottom: 60px;
}
/* line 729, ../sass/_pages.scss */
#page-actu.scoopit {
  		/*.triple-slider {
  
  			h2.line-title {
  				color: $ocirp-light-blue !important;
  
  				&:before,
  				&:after {
  					background: $ocirp-light-blue !important;
  				}
  			}
  
  			.see-all-actu-link a {
  
  				border-color: $ocirp-light-blue !important;
  				background: $ocirp-light-blue !important;
  
  				&:hover,
  				&:active {
  					background: $white !important;
  					color: $ocirp-light-blue !important;
  				}
  			}
  		}*/
}
/* line 755, ../sass/_pages.scss */
#page-actu.scoopit .actu-scoopit-img-block {
  *zoom: 1;
  margin-top: 15px;
}
/* line 41, ../sass/_mixins.scss */
#page-actu.scoopit .actu-scoopit-img-block:before, #page-actu.scoopit .actu-scoopit-img-block:after {
  content: "";
  display: table;
  clear: both;
}
/* line 760, ../sass/_pages.scss */
#page-actu.scoopit .actu-scoopit-img-block-img {
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 760, ../sass/_pages.scss */
  #page-actu.scoopit .actu-scoopit-img-block-img {
    float: left;
    margin: 0 10px 10px 0;
    max-width: 450px;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 771, ../sass/_pages.scss */
  #page-actu .actu-scoopit-heading {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  #page-actu .actu-scoopit-heading:before, #page-actu .actu-scoopit-heading:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* line 778, ../sass/_pages.scss */
#page-actu .actu-scoopit-heading-block {
  padding: 20px;
  margin-bottom: 10px;
  color: #36A312;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2em;
  line-height: 1;
  text-align: center;
}
/* line 787, ../sass/_pages.scss */
.contraste #page-actu .actu-scoopit-heading-block {
  color: #404040;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 778, ../sass/_pages.scss */
  #page-actu .actu-scoopit-heading-block {
    float: left;
    display: inline-block;
    padding: 25px 15px;
    margin: 0 30px 0 0;
  }
}
/* line 799, ../sass/_pages.scss */
#page-actu .actu-scoopit-heading-text,
#page-actu .actu-scoopit-heading-date {
  padding: 0 30px;
  margin-bottom: 6px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #707070;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 799, ../sass/_pages.scss */
  #page-actu .actu-scoopit-heading-text,
  #page-actu .actu-scoopit-heading-date {
    padding: 0;
  }
}
/* line 811, ../sass/_pages.scss */
#page-actu .actu-scoopit-heading-date {
  margin-bottom: 10px;
  font-size: 0.875em;
  line-height: 1;
}
/* line 816, ../sass/_pages.scss */
#page-actu .actu-scoopit-heading-date .mp-semibold {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: 600;
}
/* line 821, ../sass/_pages.scss */
#page-actu .actu-scoopit-commentaire {
  font-weight: bold;
}
/* line 830, ../sass/_pages.scss */
#page-actu.dossier h1 {
  text-align: center;
}
/* line 834, ../sass/_pages.scss */
#page-actu.dossier h1 + .line-bg .line-bg-txt {
  text-transform: none;
}
/* line 838, ../sass/_pages.scss */
#page-actu.dossier .double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
}
/* line 842, ../sass/_pages.scss */
#page-actu.dossier .dossier-title {
  margin-bottom: 40px;
}
/* line 845, ../sass/_pages.scss */
#page-actu.dossier .dossier-title h1 {
  margin-bottom: 10px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 850, ../sass/_pages.scss */
#page-actu.dossier .dossier-title .line-bg-txt {
  padding: 0 12px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.25em;
  line-height: 1;
}
/* line 858, ../sass/_pages.scss */
#page-actu.dossier .sommaire-container {
  margin-bottom: 0;
}
/* line 863, ../sass/_pages.scss */
#page-actu.dossier .dossier-resume {
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
#page-actu.dossier .dossier-resume:before, #page-actu.dossier .dossier-resume:after {
  content: "";
  display: table;
  clear: both;
}
/* line 866, ../sass/_pages.scss */
#page-actu.dossier .dossier-resume .dossier-resume-block {
  float: left;
  padding: 7px 7px 7px 0;
  margin: 0 10px 0 0;
  color: #808080;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5625em;
  line-height: 1;
  text-transform: uppercase;
}
/* line 882, ../sass/_pages.scss */
#page-actu.dossier.blue .dossier-title h1,
#page-actu.dossier.blue .dossier-title .line-bg-txt {
  color: #3855a0;
}
/* line 887, ../sass/_pages.scss */
#page-actu.dossier.blue .dossier-title .line-bg-line {
  background: #3855a0;
}
/* line 897, ../sass/_pages.scss */
#page-actu.dossier.orange .dossier-title h1,
#page-actu.dossier.orange .dossier-title .line-bg-txt {
  color: #EA670A;
}
/* line 902, ../sass/_pages.scss */
#page-actu.dossier.orange .dossier-title .line-bg-line {
  background: #EA670A;
}
/* line 912, ../sass/_pages.scss */
#page-actu.dossier.green .dossier-title h1,
#page-actu.dossier.green .dossier-title .line-bg-txt {
  color: #36A312;
}
/* line 917, ../sass/_pages.scss */
#page-actu.dossier.green .dossier-title .line-bg-line {
  background: #36A312;
}
/* line 927, ../sass/_pages.scss */
#page-actu.dossier.pink .dossier-title h1,
#page-actu.dossier.pink .dossier-title .line-bg-txt {
  color: #E40070;
}
/* line 932, ../sass/_pages.scss */
#page-actu.dossier.pink .dossier-title .line-bg-line {
  background: #E40070;
}
/* line 942, ../sass/_pages.scss */
#page-actu.dossier.purple .dossier-title h1,
#page-actu.dossier.purple .dossier-title .line-bg-txt {
  color: #6C1CAE;
}
/* line 947, ../sass/_pages.scss */
#page-actu.dossier.purple .dossier-title .line-bg-line {
  background: #6C1CAE;
}
/* line 957, ../sass/_pages.scss */
#page-actu.dossier.light-blue .dossier-title h1,
#page-actu.dossier.light-blue .dossier-title .line-bg-txt {
  color: #0095CA;
}
/* line 962, ../sass/_pages.scss */
#page-actu.dossier.light-blue .dossier-title .line-bg-line {
  background: #0095CA;
}
/* line 972, ../sass/_pages.scss */
#page-actu.dossier.neutre .dossier-title h1,
#page-actu.dossier.neutre .dossier-title .line-bg-txt {
  color: #404040;
}
/* line 977, ../sass/_pages.scss */
#page-actu.dossier.neutre .dossier-title .line-bg-line {
  background: #404040;
}
/* line 986, ../sass/_pages.scss */
.contraste #page-actu.dossier .dossier-title h1,
.contraste #page-actu.dossier .dossier-title .line-bg-txt {
  color: #404040;
}
/* line 991, ../sass/_pages.scss */
.contraste #page-actu.dossier .dossier-title .line-bg-line {
  background: #404040;
}
/* line 997, ../sass/_pages.scss */
#page-actu.dossier .sommaire {
  padding-top: 15px;
  border-top: 1px solid #a0a0a0;
  border-bottom: none;
}
/* line 1002, ../sass/_pages.scss */
#page-actu.dossier .sommaire.is-fixed {
  padding-top: 15px;
  border-bottom: 1px solid #a0a0a0;
}
/* line 630, ../sass/_mixins.scss */
#page-actu.green .double-line-title {
  color: #404040;
  border-color: #36A312;
}
/* line 634, ../sass/_mixins.scss */
.contraste #page-actu.green .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 640, ../sass/_mixins.scss */
#page-actu.green .communique-block {
  background: #36A312;
}
/* line 643, ../sass/_mixins.scss */
.contraste #page-actu.green .communique-block {
  background: #404040;
}
/* line 648, ../sass/_mixins.scss */
#page-actu.green .text-intro {
  color: #404040;
}
/* line 630, ../sass/_mixins.scss */
#page-actu.orange .double-line-title {
  color: #404040;
  border-color: #EA670A;
}
/* line 634, ../sass/_mixins.scss */
.contraste #page-actu.orange .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 640, ../sass/_mixins.scss */
#page-actu.orange .communique-block {
  background: #EA670A;
}
/* line 643, ../sass/_mixins.scss */
.contraste #page-actu.orange .communique-block {
  background: #404040;
}
/* line 648, ../sass/_mixins.scss */
#page-actu.orange .text-intro {
  color: #404040;
}
/* line 630, ../sass/_mixins.scss */
#page-actu.pink .double-line-title {
  color: #404040;
  border-color: #E40070;
}
/* line 634, ../sass/_mixins.scss */
.contraste #page-actu.pink .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 640, ../sass/_mixins.scss */
#page-actu.pink .communique-block {
  background: #E40070;
}
/* line 643, ../sass/_mixins.scss */
.contraste #page-actu.pink .communique-block {
  background: #404040;
}
/* line 648, ../sass/_mixins.scss */
#page-actu.pink .text-intro {
  color: #404040;
}
/* line 630, ../sass/_mixins.scss */
#page-actu.purple .double-line-title {
  color: #404040;
  border-color: #6C1CAE;
}
/* line 634, ../sass/_mixins.scss */
.contraste #page-actu.purple .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 640, ../sass/_mixins.scss */
#page-actu.purple .communique-block {
  background: #6C1CAE;
}
/* line 643, ../sass/_mixins.scss */
.contraste #page-actu.purple .communique-block {
  background: #404040;
}
/* line 648, ../sass/_mixins.scss */
#page-actu.purple .text-intro {
  color: #404040;
}
/* line 630, ../sass/_mixins.scss */
#page-actu.light-blue .double-line-title {
  color: #404040;
  border-color: #0095CA;
}
/* line 634, ../sass/_mixins.scss */
.contraste #page-actu.light-blue .double-line-title {
  color: #404040;
  border-color: #404040;
}
/* line 640, ../sass/_mixins.scss */
#page-actu.light-blue .communique-block {
  background: #0095CA;
}
/* line 643, ../sass/_mixins.scss */
.contraste #page-actu.light-blue .communique-block {
  background: #404040;
}
/* line 648, ../sass/_mixins.scss */
#page-actu.light-blue .text-intro {
  color: #404040;
}

/*==============================*/
/*PAGE LISTE DOSSIER*/
/*==============================*/
/* line 1040, ../sass/_pages.scss */
#page-liste-dossiers .double-line-title {
  margin-bottom: 0;
  border-bottom: none;
  border-color: #404040;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #404040;
}
/* line 1050, ../sass/_pages.scss */
#page-liste-dossiers .top-slider-container .slide-title {
  padding: 13px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
/* line 1056, ../sass/_pages.scss */
#page-liste-dossiers .top-slider-container .next-control button.next, #page-liste-dossiers .top-slider-container button.prev {
  color: #3855a0;
}
/* line 1060, ../sass/_pages.scss */
#page-liste-dossiers .top-slider-container .bx-viewport {
  height: auto !important;
}
/* line 1065, ../sass/_pages.scss */
#page-liste-dossiers .view-filters .search-input-container {
  margin: 0 auto 15px auto;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1065, ../sass/_pages.scss */
  #page-liste-dossiers .view-filters .search-input-container {
    max-width: 530px;
  }
}
/* line 1073, ../sass/_pages.scss */
#page-liste-dossiers .grey-bg {
  padding: 40px 5px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1073, ../sass/_pages.scss */
  #page-liste-dossiers .grey-bg {
    padding: 40px 0;
  }
}
/* line 1083, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li {
  position: relative;
  /* COLORS */
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1083, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li {
    background: white;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1094, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li.even .left-part {
    right: 0;
  }
  /* line 1098, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li.even .right-part {
    margin-left: 0;
  }
}
/* line 1104, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li > a {
  display: block;
  text-decoration: none;
  border: 1px solid #f3f2f1;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  vertical-align: middle;
  position: relative;
}
/* line 1113, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li p {
  margin-bottom: 1em;
}
/* line 1117, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .left-part {
  text-align: center;
  height: 200px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1117, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li .left-part {
    width: 510px;
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    overflow: hidden;
  }
}
/* line 1135, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .right-part {
  padding: 13px;
  background: white;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1135, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li .right-part {
    width: 512px;
    min-height: 300px;
    margin-left: 510px;
    position: relative;
    padding-bottom: 43px;
  }
}
/* line 1148, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-date {
  padding-bottom: 15px;
  border-bottom: 1px solid #d3d2d1;
  color: #d3d2d1;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1;
}
/* line 1156, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-date span {
  display: inline-block;
  vertical-align: middle;
}
/* line 1161, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-date span:before {
  font-size: 1.875em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
/* line 1171, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-content {
  padding: 15px 0 30px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1171, ../sass/_pages.scss */
  #page-liste-dossiers .dossier-list li .dossier-content {
    padding: 15px 45px;
  }
}
/* line 1179, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-title h2 {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1;
  margin-bottom: 0.5em;
}
/* line 1186, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-btn-block {
  margin-top: 15px;
  text-align: right;
}
/* line 1191, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-btn {
  position: absolute;
  bottom: 13px;
  right: 13px;
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
}
/* line 1201, ../sass/_pages.scss */
#page-liste-dossiers .dossier-list li .dossier-btn:before {
  line-height: 30px;
  color: white;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.blue > a:hover, #page-liste-dossiers .dossier-list li.blue > a:focus {
  border-color: #3855a0;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.blue > a:hover, .contraste #page-liste-dossiers .dossier-list li.blue > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.blue .dossier-date span:before,
#page-liste-dossiers .dossier-list li.blue .dossier-title h2 {
  color: #3855a0;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.blue .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.blue .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.blue .dossier-btn {
  background: #3855a0;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.blue .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.blue .slide-title {
  border-color: #3855a0;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.blue .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.orange > a:hover, #page-liste-dossiers .dossier-list li.orange > a:focus {
  border-color: #EA670A;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.orange > a:hover, .contraste #page-liste-dossiers .dossier-list li.orange > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.orange .dossier-date span:before,
#page-liste-dossiers .dossier-list li.orange .dossier-title h2 {
  color: #EA670A;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.orange .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.orange .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.orange .dossier-btn {
  background: #EA670A;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.orange .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.orange .slide-title {
  border-color: #EA670A;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.orange .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.green > a:hover, #page-liste-dossiers .dossier-list li.green > a:focus {
  border-color: #36A312;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.green > a:hover, .contraste #page-liste-dossiers .dossier-list li.green > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.green .dossier-date span:before,
#page-liste-dossiers .dossier-list li.green .dossier-title h2 {
  color: #36A312;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.green .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.green .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.green .dossier-btn {
  background: #36A312;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.green .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.green .slide-title {
  border-color: #36A312;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.green .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.pink > a:hover, #page-liste-dossiers .dossier-list li.pink > a:focus {
  border-color: #E40070;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.pink > a:hover, .contraste #page-liste-dossiers .dossier-list li.pink > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.pink .dossier-date span:before,
#page-liste-dossiers .dossier-list li.pink .dossier-title h2 {
  color: #E40070;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.pink .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.pink .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.pink .dossier-btn {
  background: #E40070;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.pink .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.pink .slide-title {
  border-color: #E40070;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.pink .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.purple > a:hover, #page-liste-dossiers .dossier-list li.purple > a:focus {
  border-color: #6C1CAE;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.purple > a:hover, .contraste #page-liste-dossiers .dossier-list li.purple > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.purple .dossier-date span:before,
#page-liste-dossiers .dossier-list li.purple .dossier-title h2 {
  color: #6C1CAE;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.purple .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.purple .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.purple .dossier-btn {
  background: #6C1CAE;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.purple .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.purple .slide-title {
  border-color: #6C1CAE;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.purple .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.light-blue > a:hover, #page-liste-dossiers .dossier-list li.light-blue > a:focus {
  border-color: #0095CA;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.light-blue > a:hover, .contraste #page-liste-dossiers .dossier-list li.light-blue > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.light-blue .dossier-date span:before,
#page-liste-dossiers .dossier-list li.light-blue .dossier-title h2 {
  color: #0095CA;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.light-blue .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.light-blue .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.light-blue .dossier-btn {
  background: #0095CA;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.light-blue .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.light-blue .slide-title {
  border-color: #0095CA;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.light-blue .slide-title {
  border-color: #404040;
}
/* line 657, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.neutre > a:hover, #page-liste-dossiers .dossier-list li.neutre > a:focus {
  border-color: #404040;
}
/* line 661, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.neutre > a:hover, .contraste #page-liste-dossiers .dossier-list li.neutre > a:focus {
  border-color: #404040;
}
/* line 667, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.neutre .dossier-date span:before,
#page-liste-dossiers .dossier-list li.neutre .dossier-title h2 {
  color: #404040;
}
/* line 671, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.neutre .dossier-date span:before, .contraste
#page-liste-dossiers .dossier-list li.neutre .dossier-title h2 {
  color: #404040;
}
/* line 676, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.neutre .dossier-btn {
  background: #404040;
}
/* line 679, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.neutre .dossier-btn {
  background: #404040;
}
/* line 684, ../sass/_mixins.scss */
#page-liste-dossiers .dossier-list li.neutre .slide-title {
  border-color: #404040;
}
/* line 687, ../sass/_mixins.scss */
.contraste #page-liste-dossiers .dossier-list li.neutre .slide-title {
  border-color: #404040;
}

/*==============================*/
/*PAGE PUBLICATION*/
/*==============================*/
/* line 1247, ../sass/_pages.scss */
#page-publication .padding-mobile {
  padding: 0 5px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1247, ../sass/_pages.scss */
  #page-publication .padding-mobile {
    padding: 0;
  }
}
/* line 1255, ../sass/_pages.scss */
#page-publication h1.double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 1259, ../sass/_pages.scss */
#page-publication .intro {
  margin-top: 20px;
  font-weight: bold;
  color: #3855a0;
}
/* line 1265, ../sass/_pages.scss */
#page-publication .publication-list {
  border-top: 1px solid #3855a0;
}
/* line 1268, ../sass/_pages.scss */
#page-publication .publication-list li {
  position: relative;
  margin: 40px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1268, ../sass/_pages.scss */
  #page-publication .publication-list li {
    padding-right: 235px;
  }
  /* line 1275, ../sass/_pages.scss */
  #page-publication .publication-list li.no-img {
    padding-right: 0;
  }
}
/* line 1282, ../sass/_pages.scss */
#page-publication .publication-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1.3;
  color: #3855a0;
  margin: 0;
}
/* line 1289, ../sass/_pages.scss */
.contraste #page-publication .publication-title {
  color: #404040;
}
/* line 1295, ../sass/_pages.scss */
#page-publication .publication-date {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d3d2d1;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1;
  color: #838282;
}
/* line 1305, ../sass/_pages.scss */
#page-publication .publication-img {
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1305, ../sass/_pages.scss */
  #page-publication .publication-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 227px;
  }
}
/* line 1316, ../sass/_pages.scss */
#page-publication .publication-text {
  margin: 20px 0;
}

/*==============================*/
/*PAGE MAGAZINE*/
/*==============================*/
/* line 1327, ../sass/_pages.scss */
#page-magazine .padding-mobile {
  padding: 0 5px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1327, ../sass/_pages.scss */
  #page-magazine .padding-mobile {
    padding: 0;
  }
}
/* line 1335, ../sass/_pages.scss */
#page-magazine h1.double-line-title {
  margin-bottom: 15px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 1340, ../sass/_pages.scss */
#page-magazine .magazine-sommaire {
  margin: 25px 0;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.1875em;
  line-height: 1;
  color: #808080;
  text-transform: uppercase;
}
/* line 1349, ../sass/_pages.scss */
#page-magazine .intro {
  padding-bottom: 15px;
  font-weight: bold;
  color: #3855a0;
  border-bottom: 1px solid #3855a0;
}
/* line 1356, ../sass/_pages.scss */
#page-magazine .intro p:last-of-type {
  margin-bottom: 0;
}
/* line 1360, ../sass/_pages.scss */
.contraste #page-magazine .intro {
  color: #404040;
  border-color: #404040;
}
/* line 1370, ../sass/_pages.scss */
#page-magazine .magazine-block h3 {
  margin: 30px 0 5px 0;
}
/* line 1375, ../sass/_pages.scss */
#page-magazine .magazine-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1.2;
  color: #3855a0;
}
/* line 1381, ../sass/_pages.scss */
.contraste #page-magazine .magazine-title {
  color: #404040;
}
/* line 1386, ../sass/_pages.scss */
#page-magazine .magazine-date {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d3d2d1;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1;
  color: #838282;
}
/* line 1395, ../sass/_pages.scss */
.contraste #page-magazine .magazine-date {
  color: #404040;
}
/* line 1400, ../sass/_pages.scss */
#page-magazine .magazine-img {
  text-align: center;
}
/* line 1404, ../sass/_pages.scss */
#page-magazine .magazine-text {
  margin-bottom: 40px;
}
/* line 1409, ../sass/_pages.scss */
#page-magazine .magazine-text .bullet-list li:before, #page-magazine .magazine-text .node-type-laureate .wysiwyg-container ul li:before, .node-type-laureate .wysiwyg-container #page-magazine .magazine-text ul li:before, #page-magazine .magazine-text .node-type-laureate-list #liste-laureats > li .description ul li:before, .node-type-laureate-list #liste-laureats > li .description #page-magazine .magazine-text ul li:before {
  color: #404040;
}
/* line 1414, ../sass/_pages.scss */
#page-magazine .magazine-text a {
  color: #3855a0;
}
/* line 1419, ../sass/_pages.scss */
#page-magazine .double-line-title .icon-plus-circle {
  font-size: 0.8125em;
}
/* line 1423, ../sass/_pages.scss */
#page-magazine .echocirp-plus-text {
  color: #3855a0;
}
/* line 1426, ../sass/_pages.scss */
.contraste #page-magazine .echocirp-plus-text {
  color: #404040;
}
/* line 1433, ../sass/_pages.scss */
#page-magazine .double-slider .slick-arrow {
  display: block !important;
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 1440, ../sass/_pages.scss */
  #page-magazine .double-slider .slick-arrow {
    position: absolute;
    bottom: -50px;
    border: 0;
    background: none;
    z-index: 2;
  }
  /* line 1448, ../sass/_pages.scss */
  #page-magazine .double-slider .slick-prev {
    left: 35%;
  }
  /* line 1452, ../sass/_pages.scss */
  #page-magazine .double-slider .slick-next {
    right: 35%;
  }
}

/*==============================*/
/*PAGE CONTACT*/
/*==============================*/
/* line 1468, ../sass/_pages.scss */
#page-contact .padding-mobile {
  padding: 0 5px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1468, ../sass/_pages.scss */
  #page-contact .padding-mobile {
    padding: 0;
  }
}
/* line 1476, ../sass/_pages.scss */
#page-contact h1.double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 1480, ../sass/_pages.scss */
#page-contact .contact-intro {
  font-weight: bold;
}
/* line 1483, ../sass/_pages.scss */
#page-contact .contact-intro .blue {
  color: #3855a0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1480, ../sass/_pages.scss */
  #page-contact .contact-intro {
    padding: 0 35px;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1492, ../sass/_pages.scss */
  #page-contact .contact-block {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  #page-contact .contact-block:before, #page-contact .contact-block:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* line 1499, ../sass/_pages.scss */
#page-contact .contact-infos-block {
  padding: 25px;
  margin-bottom: 25px;
  background: #3855a0;
  color: white;
  text-align: center;
  line-height: 2rem;
}
/* line 1507, ../sass/_pages.scss */
.contraste #page-contact .contact-infos-block {
  background: #404040;
}
/* line 1511, ../sass/_pages.scss */
#page-contact .contact-infos-block .title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1;
  margin: 0;
  color: white;
}
/* line 1519, ../sass/_pages.scss */
#page-contact .contact-infos-block p {
  margin-bottom: 25px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1499, ../sass/_pages.scss */
  #page-contact .contact-infos-block {
    float: right;
    width: 310px;
    padding: 20px 130px 20px 20px;
    background: url('../images/ocirp-contact.png?1633011802') no-repeat right bottom #3855a0;
    text-align: left;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1532, ../sass/_pages.scss */
  #page-contact .contact-form-container {
    float: left;
    width: 690px;
  }
}
/* line 1540, ../sass/_pages.scss */
#page-contact .status-fieldset {
  margin-bottom: 55px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1544, ../sass/_pages.scss */
  #page-contact .contact-form {
    padding-left: 20px;
  }
}
/* line 1550, ../sass/_pages.scss */
#page-contact .contact-form .input-line {
  margin-bottom: 15px;
}
/* line 1554, ../sass/_pages.scss */
#page-contact .contact-form label {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  margin-bottom: 8px;
  font-weight: bold;
}
/* line 1560, ../sass/_pages.scss */
#page-contact .contact-form span.required {
  color: #ff0000;
}
/* line 1564, ../sass/_pages.scss */
#page-contact .contact-form input[type="text"],
#page-contact .contact-form input[type="email"],
#page-contact .contact-form input[type="date"],
#page-contact .contact-form input[type="password"],
#page-contact .contact-form input[type="tel"],
#page-contact .contact-form input[type="url"],
#page-contact .contact-form textarea,
#page-contact .contact-form select,
#page-contact .contact-form .styled-select {
  background: #e5ecf3;
  border-radius: 3px;
  border: none;
}
/* line 1581, ../sass/_pages.scss */
#page-contact .contact-form .styled-select:before {
  color: #3855a0;
  z-index: 2;
  top: 0;
  margin-top: 0;
  pointer-events: none;
}
/* line 1590, ../sass/_pages.scss */
#page-contact .contact-form .styled-select select {
  background: none;
}
/* line 1594, ../sass/_pages.scss */
.ie8 #page-contact .contact-form .styled-select {
  background: url('../images/styled-select-arrow.png?1633011802') no-repeat right center #e5ecf3;
}
/* line 1597, ../sass/_pages.scss */
.ie8 #page-contact .contact-form .styled-select:before {
  display: none;
}
/* line 1603, ../sass/_pages.scss */
#page-contact .contact-form textarea {
  min-height: 120px;
}
/* line 1609, ../sass/_pages.scss */
#page-contact .contact-form fieldset legend {
  font-weight: bold;
  margin-bottom: 10px;
}
/* line 1614, ../sass/_pages.scss */
#page-contact .contact-form fieldset label {
  font-weight: normal;
  margin: 0;
}
/* line 1619, ../sass/_pages.scss */
#page-contact .contact-form fieldset .input-line {
  margin: 12px 0;
}
/* line 1623, ../sass/_pages.scss */
#page-contact .contact-form fieldset input[type="radio"] {
  margin: 0 10px;
}
/* line 1628, ../sass/_pages.scss */
#page-contact .contact-form .description {
  margin-top: 20px;
}
/* line 1633, ../sass/_pages.scss */
#page-contact .contact-mentions {
  margin: 25px 0 100px 0;
  color: #707070;
}

/*==============================*/
/*PAGE RECHERCHE*/
/*==============================*/
/* line 1642, ../sass/_pages.scss */
#page-recherche {
  margin-bottom: 100px;
}
/* line 1645, ../sass/_pages.scss */
#page-recherche .padding-mobile {
  padding: 0 5px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1645, ../sass/_pages.scss */
  #page-recherche .padding-mobile {
    padding: 0;
  }
}
/* line 1653, ../sass/_pages.scss */
#page-recherche h1.double-line-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 1658, ../sass/_pages.scss */
#page-recherche .recherche-form {
  margin: 20px 0 45px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1658, ../sass/_pages.scss */
  #page-recherche .recherche-form {
    padding: 0 30px;
  }
}
/* line 1665, ../sass/_pages.scss */
#page-recherche .recherche-form .search-input-container {
  display: block;
}
/* line 1669, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number {
  margin-top: 20px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25em;
  line-height: 1;
}
/* line 1675, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .strong {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
}
/* line 1679, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .blue {
  color: #3855a0;
}
/* line 1683, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .green {
  color: #36A312;
}
/* line 1687, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .orange {
  color: #EA670A;
}
/* line 1691, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .pink {
  color: #E40070;
}
/* line 1695, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .purple {
  color: #6C1CAE;
}
/* line 1699, ../sass/_pages.scss */
#page-recherche .recherche-form .result-number .light-blue {
  color: #0095CA;
}
/* line 1707, ../sass/_pages.scss */
#page-recherche .result-list li {
  margin: 0;
  padding: 25px 0;
  border-bottom: 1px solid #f3f2f1;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.blue .result-category {
  color: #3855a0;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.blue .result-btn {
  background-color: #3855a0;
  border: 1px solid #3855a0;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.blue .result-btn:hover, #page-recherche .result-list li.blue .result-btn:focus {
  color: #3855a0;
  background-color: white;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.green .result-category {
  color: #36A312;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.green .result-btn {
  background-color: #36A312;
  border: 1px solid #36A312;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.green .result-btn:hover, #page-recherche .result-list li.green .result-btn:focus {
  color: #36A312;
  background-color: white;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.orange .result-category {
  color: #EA670A;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.orange .result-btn {
  background-color: #EA670A;
  border: 1px solid #EA670A;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.orange .result-btn:hover, #page-recherche .result-list li.orange .result-btn:focus {
  color: #EA670A;
  background-color: white;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.pink .result-category {
  color: #E40070;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.pink .result-btn {
  background-color: #E40070;
  border: 1px solid #E40070;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.pink .result-btn:hover, #page-recherche .result-list li.pink .result-btn:focus {
  color: #E40070;
  background-color: white;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.purple .result-category {
  color: #6C1CAE;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.purple .result-btn {
  background-color: #6C1CAE;
  border: 1px solid #6C1CAE;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.purple .result-btn:hover, #page-recherche .result-list li.purple .result-btn:focus {
  color: #6C1CAE;
  background-color: white;
}
/* line 612, ../sass/_mixins.scss */
#page-recherche .result-list li.light-blue .result-category {
  color: #0095CA;
}
/* line 616, ../sass/_mixins.scss */
#page-recherche .result-list li.light-blue .result-btn {
  background-color: #0095CA;
  border: 1px solid #0095CA;
}
/* line 620, ../sass/_mixins.scss */
#page-recherche .result-list li.light-blue .result-btn:hover, #page-recherche .result-list li.light-blue .result-btn:focus {
  color: #0095CA;
  background-color: white;
}
/* line 1737, ../sass/_pages.scss */
#page-recherche .result-list .result-category {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
  line-height: 1;
  text-transform: uppercase;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1737, ../sass/_pages.scss */
  #page-recherche .result-list .result-category {
    padding-right: 70px;
  }
}
/* line 1748, ../sass/_pages.scss */
#page-recherche .result-list .result-link {
  display: block;
  text-decoration: none;
}
/* line 1753, ../sass/_pages.scss */
#page-recherche .result-list .result-title {
  margin: 0 0 4px 0;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1;
  color: #434242;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1753, ../sass/_pages.scss */
  #page-recherche .result-list .result-title {
    padding-right: 70px;
  }
}
/* line 1767, ../sass/_pages.scss */
#page-recherche .result-list .result-desc p {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1765, ../sass/_pages.scss */
  #page-recherche .result-list .result-desc {
    position: relative;
    padding-right: 70px;
  }
}
/* line 1777, ../sass/_pages.scss */
#page-recherche .result-list .result-btn {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1777, ../sass/_pages.scss */
  #page-recherche .result-list .result-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    min-height: 30px;
    border: 1px solid #404040;
    background: #404040;
    color: white;
    text-decoration: none;
    text-align: center;
    -moz-transition-property: color, background-color;
    -o-transition-property: color, background-color;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }
  /* line 1800, ../sass/_pages.scss */
  #page-recherche .result-list .result-btn:before {
    line-height: 1.8;
    font-size: 1em;
  }
  /* line 1805, ../sass/_pages.scss */
  #page-recherche .result-list .result-btn:hover, #page-recherche .result-list .result-btn:focus {
    color: #404040;
    background-color: white;
  }
}
/* line 1814, ../sass/_pages.scss */
#page-recherche .recherche-pager-container {
  margin: 45px 0 50px 0;
}

/*==============================*/
/*PAGE LOGIN*/
/*==============================*/
/* line 1824, ../sass/_pages.scss */
.page-user .padding-mobile {
  max-width: 1024px;
  margin: 0 auto;
}

/*COOKIE*/
/* line 1830, ../sass/_pages.scss */
#infocookie {
  padding: 16px 70px 16px 16px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  background: #FFF;
  -moz-box-shadow: 0px 12px 55px rgba(0, 0, 0, 0.68);
  -webkit-box-shadow: 0px 12px 55px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 12px 55px rgba(0, 0, 0, 0.68);
}
/* line 1839, ../sass/_pages.scss */
#infocookie .close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 50px;
  height: 50px;
  border: none;
  background: transparent;
  padding: 0;
}
@media only screen and (max-width: 20em) {
  /* line 1830, ../sass/_pages.scss */
  #infocookie {
    padding: 70px 16px 016px 16px;
  }
}

/*==============================*/
/*PAGE RSS*/
/*==============================*/
/* line 1862, ../sass/_pages.scss */
.node-type-page-flux-rss .padding-mobile {
  padding: 0 4px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1862, ../sass/_pages.scss */
  .node-type-page-flux-rss .padding-mobile {
    padding: 0;
  }
}

/*==============================*/
/*PAGE PROJET */
/*==============================*/
/* line 1879, ../sass/_pages.scss */
.bk-custom-accordion .custom-accordion-header .custom-accordion-btn {
  padding: 0px 20px;
}
/* line 1887, ../sass/_pages.scss */
.bk-custom-accordion.opened .custom-accordion-header .custom-accordion-btn [class^="icon-"]:before, .bk-custom-accordion.opened .custom-accordion-header .custom-accordion-btn [class*=" icon-"]:before {
  transform: rotateZ(-90deg);
}
/* line 1895, ../sass/_pages.scss */
.bk-custom-accordion .custom-accordion-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  margin-bottom: 20px;
}
/* line 1901, ../sass/_pages.scss */
.bk-custom-accordion .custom-accordion-header .custom-accordion-btn {
  margin: 0px 0px 0px auto;
  color: #3855a0;
  border: none;
  background: transparent;
}
/* line 1907, ../sass/_pages.scss */
.bk-custom-accordion .custom-accordion-header .custom-accordion-btn [class^="icon-"]:before, .bk-custom-accordion .custom-accordion-header .custom-accordion-btn [class*=" icon-"]:before {
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  transform: rotateZ(90deg);
  transition: all 0.3s ease;
}
/* line 1917, ../sass/_pages.scss */
.bk-custom-accordion .custom-accordion-content {
  display: none;
}

/*==============================*/
/*PAGE PRIX GENERAL*/
/*==============================*/
/* line 10, ../sass/_pages-prix.scss */
.prix .breadcrumb,
.node-type-award .breadcrumb,
.node-type-laureate .breadcrumb {
  padding: 0;
}
/* line 14, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix,
.node-type-award .breadcrumb-prix,
.node-type-laureate .breadcrumb-prix {
  margin-top: 10px;
  padding: 15px 0;
}
/* line 18, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix .breadcrumb-list,
.node-type-award .breadcrumb-prix .breadcrumb-list,
.node-type-laureate .breadcrumb-prix .breadcrumb-list {
  margin: 0;
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 18, ../sass/_pages-prix.scss */
  .prix .breadcrumb-prix .breadcrumb-list,
  .node-type-award .breadcrumb-prix .breadcrumb-list,
  .node-type-laureate .breadcrumb-prix .breadcrumb-list {
    text-align: left;
  }
}
/* line 27, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix .breadcrumb-list li,
.node-type-award .breadcrumb-prix .breadcrumb-list li,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li {
  display: inline-block;
  margin: 0;
  color: #707070;
}
/* line 33, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix .breadcrumb-list li:before,
.node-type-award .breadcrumb-prix .breadcrumb-list li:before,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li:before {
  margin: 10px;
  font-size: 0.75em;
}
/* line 39, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix .breadcrumb-list li a,
.prix .breadcrumb-prix .breadcrumb-list li span,
.node-type-award .breadcrumb-prix .breadcrumb-list li a,
.node-type-award .breadcrumb-prix .breadcrumb-list li span,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li a,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li span {
  color: #707070;
}
/* line 46, ../sass/_pages-prix.scss */
.prix .breadcrumb-prix .breadcrumb-list li:first-child:before,
.node-type-award .breadcrumb-prix .breadcrumb-list li:first-child:before,
.node-type-laureate .breadcrumb-prix .breadcrumb-list li:first-child:before {
  display: none;
}
/* line 54, ../sass/_pages-prix.scss */
.prix .main-nav-list > li > a.active-trail,
.prix .side-bar .side-bar-list .side-bar-link,
.prix .side-bar .side-bar-list .print-link,
.prix .side-bar .side-bar-list .side-bar-menu button,
.prix .side-bar .side-bar-list .side-bar-menu a,
.prix #back-top,
.node-type-award .main-nav-list > li > a.active-trail,
.node-type-award .side-bar .side-bar-list .side-bar-link,
.node-type-award .side-bar .side-bar-list .print-link,
.node-type-award .side-bar .side-bar-list .side-bar-menu button,
.node-type-award .side-bar .side-bar-list .side-bar-menu a,
.node-type-award #back-top,
.node-type-laureate .main-nav-list > li > a.active-trail,
.node-type-laureate .side-bar .side-bar-list .side-bar-link,
.node-type-laureate .side-bar .side-bar-list .print-link,
.node-type-laureate .side-bar .side-bar-list .side-bar-menu button,
.node-type-laureate .side-bar .side-bar-list .side-bar-menu a,
.node-type-laureate #back-top {
  color: #75c200;
}
/* line 63, ../sass/_pages-prix.scss */
.prix .main-nav-list > li > a.active-trail:after,
.node-type-award .main-nav-list > li > a.active-trail:after,
.node-type-laureate .main-nav-list > li > a.active-trail:after {
  border-bottom-color: #75c200;
}
/* line 67, ../sass/_pages-prix.scss */
.prix .global-wrapper,
.prix .page-footer-wrapper,
.node-type-award .global-wrapper,
.node-type-award .page-footer-wrapper,
.node-type-laureate .global-wrapper,
.node-type-laureate .page-footer-wrapper {
  background: none;
}
/* line 72, ../sass/_pages-prix.scss */
.prix h1.double-line-title,
.node-type-award h1.double-line-title,
.node-type-laureate h1.double-line-title {
  color: #75c200;
  border-color: #75c200;
}
/* line 77, ../sass/_pages-prix.scss */
.prix .notation-block,
.node-type-award .notation-block,
.node-type-laureate .notation-block {
  border-color: #75c200;
}
/* line 80, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block,
.prix .notation-block .share-block,
.node-type-award .notation-block .mark-block,
.node-type-award .notation-block .share-block,
.node-type-laureate .notation-block .mark-block,
.node-type-laureate .notation-block .share-block {
  color: #75c200;
}
/* line 87, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block .item-list,
.node-type-award .notation-block .mark-block .item-list,
.node-type-laureate .notation-block .mark-block .item-list {
  border-color: #75c200;
}
/* line 90, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block .item-list ul li button,
.prix .notation-block .mark-block .item-list ul li span,
.node-type-award .notation-block .mark-block .item-list ul li button,
.node-type-award .notation-block .mark-block .item-list ul li span,
.node-type-laureate .notation-block .mark-block .item-list ul li button,
.node-type-laureate .notation-block .mark-block .item-list ul li span {
  background: url('../images/star-empty-green.png?1633011802') no-repeat center center;
}
/* line 94, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block .item-list ul li button.user-voted,
.prix .notation-block .mark-block .item-list ul li span.user-voted,
.node-type-award .notation-block .mark-block .item-list ul li button.user-voted,
.node-type-award .notation-block .mark-block .item-list ul li span.user-voted,
.node-type-laureate .notation-block .mark-block .item-list ul li button.user-voted,
.node-type-laureate .notation-block .mark-block .item-list ul li span.user-voted {
  background: url('../images/star-full-full-green.png?1633011802') no-repeat center center #75c200;
}
/* line 98, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block .item-list ul li button:focus,
.prix .notation-block .mark-block .item-list ul li span:focus,
.node-type-award .notation-block .mark-block .item-list ul li button:focus,
.node-type-award .notation-block .mark-block .item-list ul li span:focus,
.node-type-laureate .notation-block .mark-block .item-list ul li button:focus,
.node-type-laureate .notation-block .mark-block .item-list ul li span:focus {
  background: url('../images/star-full-green.png?1633011802') no-repeat center center;
}
/* line 104, ../sass/_pages-prix.scss */
.prix .notation-block .mark-block .rate-info,
.node-type-award .notation-block .mark-block .rate-info,
.node-type-laureate .notation-block .mark-block .rate-info {
  color: #75c200;
}
/* line 112, ../sass/_pages-prix.scss */
.prix.contraste .double-line-title,
.node-type-award.contraste .double-line-title,
.node-type-laureate.contraste .double-line-title {
  color: #404040;
  border-color: #404040;
}

/*==============================*/
/*PAGE PRIX HOME*/
/*==============================*/
/* line 127, ../sass/_pages-prix.scss */
.node-type-award .breadcrumb {
  padding: 0;
}

/*==============================*/
/*PAGE PRIX FICHE LAUREAT*/
/*==============================*/
/* line 138, ../sass/_pages-prix.scss */
.node-type-laureate .padding-mobile {
  padding: 0 4px;
}
/* line 143, ../sass/_pages-prix.scss */
.node-type-laureate .back-to-list {
  margin-top: 40px;
  color: #75c200;
  font-size: 1.4375em;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-decoration: none;
}
/* line 152, ../sass/_pages-prix.scss */
.node-type-laureate .back-to-list:before {
  font-size: 80%;
  margin-right: 7px;
}
/* line 159, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block {
  margin: 38px 0 50px 0;
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
.node-type-laureate .laureat-top-block:before, .node-type-laureate .laureat-top-block:after {
  content: "";
  display: table;
  clear: both;
}
/* line 163, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part,
.node-type-laureate .laureat-top-block .right-part {
  position: relative;
  width: 96%;
  margin: 10px 2%;
  display: table;
}
@media print, screen and (min-width: 48em) {
  /* line 163, ../sass/_pages-prix.scss */
  .node-type-laureate .laureat-top-block .left-part,
  .node-type-laureate .laureat-top-block .right-part {
    float: left;
    width: 46%;
  }
}
/* line 175, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .vert-center,
.node-type-laureate .laureat-top-block .right-part .vert-center {
  display: table-cell;
  vertical-align: middle;
}
/* line 181, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part {
  padding: 20px 15px;
  border-top: 1px solid #75c200;
  border-bottom: 1px solid #75c200;
  letter-spacing: -0.30em;
}
/* line 187, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .logo-img {
  display: inline-block;
  width: 33%;
  vertical-align: middle;
  letter-spacing: normal;
}
/* line 194, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .content {
  display: inline-block;
  width: 67%;
  padding-left: 20px;
  vertical-align: middle;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 203, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .title {
  margin-bottom: 20px;
  font-size: 1.6875em;
  color: #75c200;
}
/* line 209, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .discover {
  text-decoration: none;
  color: #585858;
  font-size: 1.25em;
  text-transform: uppercase;
}
/* line 215, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .discover span {
  margin-left: 10px;
}
/* line 218, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .discover span:before {
  font-size: 80%;
}
/* line 224, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .link-list {
  margin-bottom: 0;
}
/* line 228, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .link-list li {
  margin-top: 10px;
  margin-right: 8px;
}
/* line 232, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .link-list li a {
  text-decoration: none;
  display: block;
  width: 33px;
  height: 33px;
  text-indent: -9999px;
}
/* line 239, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .link-list li a.fb {
  background: url('../images/square-fb.png?1633011802');
}
/* line 243, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .left-part .link-list li a.tw {
  background: url('../images/square-tw.png?1633011802');
}
/* line 251, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .right-part {
  padding: 22px;
  background: #f3f2f1;
}
/* line 257, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .right-part.loved .loved-block {
  display: inline-block;
}
/* line 262, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .right-part .loved-block {
  display: none;
  margin-bottom: 12px;
}
/* line 267, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .right-part .content {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25em;
  line-height: 1.2;
  color: #525252;
}
/* line 274, ../sass/_pages-prix.scss */
.node-type-laureate .laureat-top-block .right-part .uppercase {
  text-transform: uppercase;
}
/* line 280, ../sass/_pages-prix.scss */
.node-type-laureate .wysiwyg-container {
  max-width: 760px;
  margin: 0 auto 50px auto;
}
/* line 289, ../sass/_pages-prix.scss */
.node-type-laureate .navigation-laureats {
  position: relative;
}
/* line 292, ../sass/_pages-prix.scss */
.node-type-laureate .navigation-laureats .prev-link,
.node-type-laureate .navigation-laureats .next-link {
  color: #3855a0;
  font-size: 1.4375em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
}
/* line 300, ../sass/_pages-prix.scss */
.node-type-laureate .navigation-laureats .prev-link span,
.node-type-laureate .navigation-laureats .next-link span {
  font-size: 80%;
}
/* line 305, ../sass/_pages-prix.scss */
.node-type-laureate .navigation-laureats .next-link {
  position: absolute;
  top: 0;
  right: 0;
}
/* line 312, ../sass/_pages-prix.scss */
.node-type-laureate .sub-title-container {
  margin-top: 65px;
  text-align: center;
}
/* line 316, ../sass/_pages-prix.scss */
.node-type-laureate .sub-title-container .double-line-title {
  margin-bottom: 25px;
  display: inline-block;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 327, ../sass/_pages-prix.scss */
.node-type-laureate.contraste .back-to-list {
  color: #404040;
}
/* line 331, ../sass/_pages-prix.scss */
.node-type-laureate.contraste .left-part {
  border-color: #404040;
}
/* line 334, ../sass/_pages-prix.scss */
.node-type-laureate.contraste .left-part .title {
  color: #404040;
}

/* line 341, ../sass/_pages-prix.scss */
.loved-block {
  display: inline-block;
  padding: 8px 10px;
  background: #75c200;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.0625em;
  line-height: 1;
  color: white;
  text-transform: uppercase;
}
/* line 351, ../sass/_pages-prix.scss */
.loved-block span {
  margin-right: 5px;
}
/* line 355, ../sass/_pages-prix.scss */
.contraste .loved-block {
  background: #404040;
}

/*==============================*/
/*PAGE PRIX LIST LAUREAT*/
/*==============================*/
/* line 365, ../sass/_pages-prix.scss */
.node-type-laureate-list .padding-mobile {
  padding: 0 4px;
}
/* line 369, ../sass/_pages-prix.scss */
.node-type-laureate-list .introduction {
  max-width: 760px;
  margin: 0 auto 20px auto;
  color: #3855a0;
  font-weight: bold;
}
/* line 376, ../sass/_pages-prix.scss */
.node-type-laureate-list .laureat-search-block {
  margin-bottom: 85px;
}
/* line 380, ../sass/_pages-prix.scss */
.node-type-laureate-list .sort-and-number-block {
  *zoom: 1;
  margin-bottom: 5px;
}
/* line 41, ../sass/_mixins.scss */
.node-type-laureate-list .sort-and-number-block:before, .node-type-laureate-list .sort-and-number-block:after {
  content: "";
  display: table;
  clear: both;
}
/* line 385, ../sass/_pages-prix.scss */
.node-type-laureate-list .laureat-sort-block {
  margin-bottom: 10px;
  *zoom: 1;
  font-size: 1.4375em;
  color: #a7a7a7;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 41, ../sass/_mixins.scss */
.node-type-laureate-list .laureat-sort-block:before, .node-type-laureate-list .laureat-sort-block:after {
  content: "";
  display: table;
  clear: both;
}
/* line 392, ../sass/_pages-prix.scss */
.node-type-laureate-list .laureat-sort-block fieldset {
  margin: 0;
}
@media print, screen and (min-width: 55em) {
  /* line 396, ../sass/_pages-prix.scss */
  .node-type-laureate-list .laureat-sort-block legend {
    float: left;
  }
}
@media print, screen and (min-width: 55em) {
  /* line 403, ../sass/_pages-prix.scss */
  .node-type-laureate-list .laureat-sort-block .radio-container {
    display: inline-block;
    margin-left: 22px;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 385, ../sass/_pages-prix.scss */
  .node-type-laureate-list .laureat-sort-block {
    display: inline-block;
    float: left;
    margin: 0.7em 0 0 0;
  }
}
/* line 418, ../sass/_pages-prix.scss */
.node-type-laureate-list .nombre-elements {
  font-size: 1.375em;
  color: #75c200;
}
/* line 422, ../sass/_pages-prix.scss */
.node-type-laureate-list .nombre-elements span {
  font-size: 200%;
  line-height: 1;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 418, ../sass/_pages-prix.scss */
  .node-type-laureate-list .nombre-elements {
    display: inline-block;
    float: right;
  }
}
/* line 435, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li {
  position: relative;
  padding: 15px 4% 30px 4%;
  margin: 0;
  border-top: 1px solid #bdbdbd;
  background: #f9f9f9;
}
/* line 442, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li .loved-block {
  display: none;
}
/* line 446, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li .heading {
  *zoom: 1;
  margin-bottom: 25px;
}
/* line 41, ../sass/_mixins.scss */
.node-type-laureate-list #liste-laureats > li .heading:before, .node-type-laureate-list #liste-laureats > li .heading:after {
  content: "";
  display: table;
  clear: both;
}
/* line 450, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li .heading .heading-text {
  font-size: 1.25em;
  color: #525252;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 457, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li .title {
  margin-bottom: 8px;
  font-size: 1.6875em;
  line-height: 1.2;
  color: #525252;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 464, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li .title .color-light-green {
  color: #75c200;
}
/* line 478, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li.loved .loved-block {
  display: inline-block;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 48em) {
  /* line 478, ../sass/_pages-prix.scss */
  .node-type-laureate-list #liste-laureats > li.loved .loved-block {
    float: left;
    margin: 0 18px 10px 0;
  }
}
/* line 488, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li.loved .heading-text {
  display: block;
}
@media print, screen and (min-width: 48em) {
  /* line 488, ../sass/_pages-prix.scss */
  .node-type-laureate-list #liste-laureats > li.loved .heading-text {
    display: inline-block;
    padding-top: 5px;
  }
}
/* line 498, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li.laureat {
  background: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 498, ../sass/_pages-prix.scss */
  .node-type-laureate-list #liste-laureats > li.laureat {
    padding-right: 280px;
  }
}
/* line 505, ../sass/_pages-prix.scss */
.node-type-laureate-list #liste-laureats > li.laureat .btn-container {
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 505, ../sass/_pages-prix.scss */
  .node-type-laureate-list #liste-laureats > li.laureat .btn-container {
    display: inline-block;
    position: absolute;
    right: 50px;
    top: 80px;
  }
}
/* line 527, ../sass/_pages-prix.scss */
.node-type-laureate-list.contraste .nombre-elements,
.node-type-laureate-list.contraste #liste-laureats li .title .color-light-green,
.node-type-laureate-list.contraste .laureat-sort-block {
  color: #404040;
}

/*==============================*/
/*PAGE HP ACTUS PRIX*/
/*==============================*/
/* line 544, ../sass/_pages-prix.scss */
.node-type-award-news-list .top-slider.top-actu-slider {
  margin-bottom: 40px;
  background: white;
}
/* line 548, ../sass/_pages-prix.scss */
.node-type-award-news-list .top-slider.top-actu-slider .line-bg-txt {
  background: white;
}
/* line 555, ../sass/_pages-prix.scss */
.node-type-award-news-list .all-actu .search-input-container {
  max-width: 530px;
  margin: 0 auto 35px auto;
}
/* line 560, ../sass/_pages-prix.scss */
.node-type-award-news-list .all-actu .pager {
  margin-bottom: 20px;
}

/*------------------------------------------------
- Ici, tous les styles relatifs à la structure de la page. La base, la "coquille" du site. (header / content / footer)
------------------------------------------------*/
/* line 6, ../sass/_layout.scss */
body {
  background-color: white;
  min-width: 320px;
  color: #404040;
  overflow-x: hidden;
}

/* line 17, ../sass/_layout.scss */
.site-content {
  max-width: 1024px;
  margin: 0 auto;
  background: white;
}

/* line 22, ../sass/_layout.scss */
.skip-links {
  margin: 0;
}
/* line 25, ../sass/_layout.scss */
.skip-links li {
  margin: 0;
}
/* line 29, ../sass/_layout.scss */
.skip-links a {
  position: absolute;
  left: -50000px;
}
/* line 33, ../sass/_layout.scss */
.skip-links a:focus {
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 15;
  clip: auto;
  overflow: visible;
  height: auto;
}

/*==============================*/
/*HEADER*/
/*==============================*/
/* line 50, ../sass/_layout.scss */
.page-header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f3f2f1;
  z-index: 10;
  /* SUB MENU */
}
/* line 58, ../sass/_layout.scss */
.page-header-wrapper > .site-content {
  position: relative;
  padding: 0;
  background: #f3f2f1;
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
.page-header-wrapper > .site-content:before, .page-header-wrapper > .site-content:after {
  content: "";
  display: table;
  clear: both;
}
/* line 65, ../sass/_layout.scss */
.page-header-wrapper .mobile-special-menu-btn {
  display: none;
  position: absolute;
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  background: url('../images/grantie-menu-btn.png?1633011802') no-repeat center center;
  vertical-align: top;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 65, ../sass/_layout.scss */
  .page-header-wrapper .mobile-special-menu-btn {
    display: none !important;
  }
}
/* line 81, ../sass/_layout.scss */
.page-header-wrapper .mobile-special-menu-btn.is-active:after {
  content: "x";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  line-height: 60px;
  font-size: 1.125em;
  text-align: center;
  color: #3855a0;
}
/* line 97, ../sass/_layout.scss */
.page-header-wrapper .header-logo {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -67px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 97, ../sass/_layout.scss */
  .page-header-wrapper .header-logo {
    position: relative;
    left: 0;
    margin-left: 0;
  }
}
/* line 109, ../sass/_layout.scss */
.page-header-wrapper .header-logo img {
  height: 44px;
  margin: 8px 0;
  vertical-align: middle;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 109, ../sass/_layout.scss */
  .page-header-wrapper .header-logo img {
    height: auto;
    margin: 28px 0 9px 0;
  }
}
/* line 122, ../sass/_layout.scss */
.page-header-wrapper .main-nav {
  display: none;
  position: absolute;
  width: 85%;
  right: -85%;
  top: 100%;
  background: #e7e5e4;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 122, ../sass/_layout.scss */
  .page-header-wrapper .main-nav {
    display: block !important;
    width: auto;
    height: 100%;
    top: auto;
    bottom: 0;
    right: 10px !important;
    background: none;
    overflow: hidden;
  }
}
/* line 144, ../sass/_layout.scss */
.page-header-wrapper .main-nav .top-adjust {
  visibility: hidden;
  padding: 0;
  line-height: 50px;
  font-size: 1.5625em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 144, ../sass/_layout.scss */
  .page-header-wrapper .main-nav .top-adjust {
    display: none;
  }
}
/* line 157, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list {
  margin: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 157, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list {
    height: 100%;
  }
}
/* line 164, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li {
  position: relative;
  margin-bottom: 0;
  border-bottom: 1px solid #c3c2c1;
}
/* line 171, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.has-child:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: 46px;
  background: #c3c2c1;
}
/* line 180, ../sass/_layout.scss */
.ie8 .page-header-wrapper .main-nav-list > li.has-child:before {
  display: none;
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 187, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.has-child > a:before {
    content: "+";
    position: absolute;
    width: 46px;
    height: 54px;
    top: 0;
    right: 0;
    padding-top: 10px;
    text-align: center;
    font-size: 1.25em;
    line-height: 1;
    color: #3855a0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-transition: -moz-transform 0.3s ease;
    -o-transition: -o-transform 0.3s ease;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
  }
  /* line 204, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.has-child > a.is-active:before {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /* line 207, ../sass/_layout.scss */
  .ie8 .page-header-wrapper .main-nav-list > li.has-child > a.is-active:before {
    content: "x";
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 164, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li {
    display: inline-block;
    margin-top: 45px;
    border-bottom: none;
    border-left: 1px solid #3855a0;
  }
  /* line 220, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li:before {
    display: none;
    content: ".";
  }
}
/* line 226, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li > a {
  position: relative;
  display: block;
  padding: 15px 65px 15px 20px;
  text-decoration: none;
  font-size: 1.4375em;
  color: #3855a0;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 226, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li > a {
    height: 38px;
    padding: 0 22px 0 6px;
  }
  /* line 239, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li > a:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: bottom;
  }
  /* line 246, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li > a .bottom-align {
    display: inline-block;
    vertical-align: bottom;
  }
  /* line 251, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li > a .small {
    display: block;
    font-size: 0.6875em;
    line-height: 1;
  }
  /* line 262, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li > a.active-trail:after, .page-header-wrapper .main-nav-list > li > a.is-active:after, .page-header-wrapper .main-nav-list > li > a:hover:after, .page-header-wrapper .main-nav-list > li > a:focus:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 7px;
    left: -1px;
    bottom: -7px;
    border-left: 1px solid #3855a0;
    border-bottom: 4px solid #3855a0;
    z-index: 5;
  }
}
/* line 277, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.search-tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  border: none;
}
/* line 285, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.search-tab:before {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 291, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right.is-active,
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right:hover,
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right:focus {
    background: #005dba;
  }
  /* line 298, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right.is-active .ico-link:before,
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right:hover .ico-link:before,
  .page-header-wrapper .main-nav-list > li.search-tab .menu-icon-right:focus .ico-link:before {
    content: "\e800 ";
  }
}
/* line 306, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.search-tab > button {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 306, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab > button {
    display: block;
  }
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 314, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .sub-menu {
    display: block !important;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 314, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .sub-menu {
    background: #005dba;
  }
}
/* line 325, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.search-tab .site-content {
  padding: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 325, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .site-content {
    background: #005dba;
  }
}
/* line 333, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.search-tab .search-form {
  position: relative;
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
.page-header-wrapper .main-nav-list > li.search-tab .search-form:before, .page-header-wrapper .main-nav-list > li.search-tab .search-form:after {
  content: "";
  display: table;
  clear: both;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 333, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .search-form {
    margin: 110px auto;
    max-width: 525px;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 344, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .search-form .icon-loupe:before {
    color: #005dba;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 353, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .search-input {
    border: none;
  }
}
/* line 362, ../sass/_layout.scss */
.contraste .page-header-wrapper .main-nav-list > li.search-tab .search-submit {
  background: white;
  color: #005dba;
  border: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 360, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .search-submit {
    color: #005dba;
  }
  /* line 371, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.search-tab .search-submit:hover, .page-header-wrapper .main-nav-list > li.search-tab .search-submit:focus {
    background: #005dba;
    color: white;
  }
}
/* line 381, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.access-tab {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 381, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab {
    display: inline-block;
    border: 0;
    height: 100%;
  }
  /* line 389, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right.is-active,
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right:hover,
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right:focus {
    background: black;
  }
  /* line 396, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right.is-active .ico-link:before,
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right:hover .ico-link:before,
  .page-header-wrapper .main-nav-list > li.access-tab .menu-icon-right:focus .ico-link:before {
    content: "\e80a ";
  }
  /* line 402, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .sub-menu {
    background: black;
  }
  /* line 406, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .site-content {
    background: black;
    color: white;
    padding: 90px 0;
  }
  /* line 411, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .site-content .sub-menu-list {
    text-align: center;
  }
  /* line 414, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .site-content .sub-menu-list li {
    display: inline-block;
  }
  /* line 420, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .round-icon-btn,
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn li button {
    color: white;
    background: none;
    border: none;
    line-height: 80px;
    font-weight: normal;
    font-style: normal;
    font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.5625em;
    line-height: 1;
  }
  /* line 430, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .round-icon-btn span,
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn li button span {
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
  }
  /* line 435, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .round-icon-btn span:before,
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn li button span:before {
    font-size: 3.125em;
    line-height: 1;
  }
  /* line 442, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn {
    display: inline-block;
    margin-left: 70px;
  }
  /* line 447, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn-heading {
    line-height: 80px;
    font-weight: normal;
    font-style: normal;
    font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.5625em;
    line-height: 1;
  }
  /* line 454, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn-list {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
  /* line 459, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.access-tab .font-btn-list li {
    display: inline-block;
  }
}
/* line 468, ../sass/_layout.scss */
.page-header-wrapper .main-nav-list > li.tools .role-div {
  height: 100%;
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 468, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.tools .role-div {
    display: block;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 466, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.tools {
    position: relative;
    width: 80px;
    height: 100%;
    margin-top: 0;
    background: #e7e5e4;
    vertical-align: top;
    border: none;
    text-align: center;
  }
  /* line 489, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.tools .menu-icon-right {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    color: #3855a0;
  }
  /* line 500, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.tools .menu-icon-right .ico-link:before {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 2.1875em;
  }
  /* line 516, ../sass/_layout.scss */
  .page-header-wrapper .main-nav-list > li.tools .menu-icon-right.is-active .ico-link:before, .page-header-wrapper .main-nav-list > li.tools .menu-icon-right:hover .ico-link:before, .page-header-wrapper .main-nav-list > li.tools .menu-icon-right:focus .ico-link:before {
    color: white;
  }
}
/* line 529, ../sass/_layout.scss */
.page-header-wrapper .sub-menu {
  position: relative;
  display: none;
  background: #f3f2f1;
  z-index: 4;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 529, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu {
    position: fixed;
    top: 90px;
    left: 0;
  }
}
/* line 541, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .site-content {
  background: #f3f2f1;
  padding: 15px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 541, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu .site-content {
    padding: 25px 30px;
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  .page-header-wrapper .sub-menu .site-content:before, .page-header-wrapper .sub-menu .site-content:after {
    content: "";
    display: table;
    clear: both;
  }
  /* line 549, ../sass/_layout.scss */
  .ie8 .page-header-wrapper .sub-menu .site-content {
    width: 1044px;
  }
}
/* line 555, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-list {
  margin: 0;
}
/* line 558, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-list li {
  margin: 0;
}
/* line 561, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-list li a {
  display: block;
  padding: 10px;
  color: #3855a0;
  text-decoration: none;
  font-size: 1.5625em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 570, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-list li a:after {
  color: #a0a0a0;
  margin: 0 0 0 10px;
  font-size: 0.6875em;
}
/* line 580, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .left-part,
.page-header-wrapper .sub-menu .right-part {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 580, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu .left-part,
  .page-header-wrapper .sub-menu .right-part {
    display: block;
  }
}
/* line 588, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .left-part.no-content,
.page-header-wrapper .sub-menu .right-part.no-content {
  display: none;
}
/* line 591, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .left-part.no-content + .sub-menu-list,
.page-header-wrapper .sub-menu .right-part.no-content + .sub-menu-list {
  border-left: none;
}
/* line 597, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .left-part {
  display: block;
  padding: 0 10px;
}
/* line 601, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .left-part img {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 601, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu .left-part img {
    display: block;
  }
}
/* line 611, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 611, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu .sub-menu-footer {
    display: block;
  }
}
/* line 618, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .infos-block {
  background: #e7e5e4;
  text-align: center;
  color: #3855a0;
}
/* line 623, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .infos-block .infos-txt {
  display: inline-block;
  padding: 25px 0;
}
/* line 627, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .infos-block .infos-txt p {
  margin: 0;
}
/* line 633, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .close-block {
  background: #3855a0;
  text-align: center;
}
/* line 637, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .close-block .close-sub-menu-btn {
  padding: 9px 0;
  border: none;
  background: none;
  color: white;
}
/* line 644, ../sass/_layout.scss */
.page-header-wrapper .sub-menu .sub-menu-footer .close-block .close-sub-menu-btn:before {
  margin-right: 6px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 656, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.ocirp .left-part,
  .page-header-wrapper .sub-menu.ocirp .sub-menu-list {
    float: left;
    width: 33%;
    padding: 0 22px;
  }
  /* line 663, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.ocirp .sub-menu-list {
    border-left: 1px solid #c0c0c0;
  }
}
/* line 672, ../sass/_layout.scss */
.page-header-wrapper .sub-menu.garanties .sub-menu-list img, .page-header-wrapper .sub-menu.information .sub-menu-list img {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 677, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .left-part, .page-header-wrapper .sub-menu.information .left-part {
    padding: 30px 20px 30px 0;
  }
  /* line 683, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .left-part p, .page-header-wrapper .sub-menu.information .left-part p {
    margin: 0;
  }
  /* line 688, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list, .page-header-wrapper .sub-menu.information .sub-menu-list {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list:before, .page-header-wrapper .sub-menu.garanties .sub-menu-list:after, .page-header-wrapper .sub-menu.information .sub-menu-list:before, .page-header-wrapper .sub-menu.information .sub-menu-list:after {
    content: "";
    display: table;
    clear: both;
  }
  /* line 693, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li, .page-header-wrapper .sub-menu.information .sub-menu-list li {
    float: left;
    width: 20%;
  }
  /* line 698, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li a, .page-header-wrapper .sub-menu.information .sub-menu-list li a {
    text-align: center;
    text-transform: uppercase;
    padding: 0;
    color: white;
  }
  /* line 704, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li a img, .page-header-wrapper .sub-menu.information .sub-menu-list li a img {
    display: block;
    width: 100%;
  }
  /* line 708, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li a .title, .page-header-wrapper .sub-menu.information .sub-menu-list li a .title {
    display: block;
    padding: 3px;
  }
  /* line 713, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li a:after, .page-header-wrapper .sub-menu.information .sub-menu-list li a:after {
    display: none;
  }
  /* line 718, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li.orange a, .page-header-wrapper .sub-menu.information .sub-menu-list li.orange a {
    background-color: #EA670A;
  }
  /* line 722, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li.pink a, .page-header-wrapper .sub-menu.information .sub-menu-list li.pink a {
    background-color: #E40070;
  }
  /* line 726, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li.purple a, .page-header-wrapper .sub-menu.information .sub-menu-list li.purple a {
    background-color: #6C1CAE;
  }
  /* line 730, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li.green a, .page-header-wrapper .sub-menu.information .sub-menu-list li.green a {
    background-color: #36A312;
  }
  /* line 734, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.garanties .sub-menu-list li.light-blue a, .page-header-wrapper .sub-menu.information .sub-menu-list li.light-blue a {
    background-color: #0095CA;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 746, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .left-part,
  .page-header-wrapper .sub-menu.actualites .sub-menu-list,
  .page-header-wrapper .sub-menu.actualites .right-part {
    float: left;
    width: 33%;
  }
  /* line 753, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .left-part {
    padding: 0 13px 0 0;
  }
  /* line 757, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .sub-menu-list {
    padding: 30px 50px 0 25px;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
  }
  /* line 763, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list {
    margin: 0;
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list:before, .page-header-wrapper .sub-menu.actualites .mag-list:after {
    content: "";
    display: table;
    clear: both;
  }
  /* line 767, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list li {
    float: left;
    width: 50%;
    padding: 0 20px 0 10px;
  }
  /* line 772, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list li:first-child {
    padding: 0 10px 0 20px;
  }
  /* line 776, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list li a {
    display: block;
    color: #3855a0;
    text-decoration: none;
    font-size: 1.25em;
    font-weight: normal;
    font-style: normal;
    font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
    font-weight: normal;
  }
  /* line 783, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list li a .small {
    font-size: 0.8125em;
  }
  /* line 788, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.actualites .mag-list li img {
    width: 100%;
  }
}
/* line 800, ../sass/_layout.scss */
.page-header-wrapper .sub-menu.sites .sub-menu-list .site-img,
.page-header-wrapper .sub-menu.sites .sub-menu-list .site-desc {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 808, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list:before, .page-header-wrapper .sub-menu.sites .sub-menu-list:after {
    content: "";
    display: table;
    clear: both;
  }
  /* line 811, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li {
    float: left;
    width: 25%;
  }
  /* line 815, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li a {
    text-align: center;
  }
  /* line 818, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li a:after {
    display: none;
  }
  /* line 823, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li .site-img {
    display: block;
    height: 90px;
    background-repeat: no-repeat !important;
  }
  /* line 830, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li .site-name {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }
  /* line 834, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .sub-menu-list li .site-desc {
    display: inline-block;
    margin: 0;
    color: #404040;
    font-weight: normal;
    font-style: normal;
    font-family: Arial, helvetica, sans-serif;
    font-size: 0.625em;
  }
  /* line 844, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .infos-block {
    background: #0095CA;
  }
  /* line 847, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .infos-block div.infos-txt {
    padding: 10px 0;
    margin: 0;
    vertical-align: middle;
    color: white;
  }
  /* line 855, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .social-list {
    margin: 0 0 0 25px;
    vertical-align: middle;
  }
  /* line 859, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .social-list li {
    vertical-align: middle;
  }
  /* line 862, ../sass/_layout.scss */
  .page-header-wrapper .sub-menu.sites .social-list li a {
    color: white;
  }
}
/* line 871, ../sass/_layout.scss */
.page-header-wrapper .mobile-extra-menu {
  margin: 0;
}
/* line 874, ../sass/_layout.scss */
.page-header-wrapper .mobile-extra-menu li {
  margin: 0;
}
/* line 877, ../sass/_layout.scss */
.page-header-wrapper .mobile-extra-menu li a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 871, ../sass/_layout.scss */
  .page-header-wrapper .mobile-extra-menu {
    display: none;
  }
}
/* line 890, ../sass/_layout.scss */
.page-header-wrapper .mobile-menu-btn, .page-header-wrapper .margin-top-adjust {
  position: relative;
  float: right;
  min-width: 60px;
  min-height: 60px;
  border: none;
  background: #e7e5e4;
}
/* line 898, ../sass/_layout.scss */
.page-header-wrapper .mobile-menu-btn .icon-bars, .page-header-wrapper .margin-top-adjust .icon-bars {
  font-size: 2em;
  color: #3855a0;
}
/* line 903, ../sass/_layout.scss */
.page-header-wrapper .mobile-menu-btn .mobile-menu-btn-txt, .page-header-wrapper .margin-top-adjust .mobile-menu-btn-txt {
  font-size: 0.6875em;
  color: #3855a0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 890, ../sass/_layout.scss */
  .page-header-wrapper .mobile-menu-btn, .page-header-wrapper .margin-top-adjust {
    display: none;
  }
}

/*==============================*/
/*SECOND NAV*/
/*==============================*/
/* line 926, ../sass/_layout.scss */
.second-nav {
  display: none;
  position: fixed;
  width: 100%;
  top: 60px;
  left: 0;
  background: white;
  z-index: 2;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 926, ../sass/_layout.scss */
  .second-nav {
    display: block !important;
    position: relative;
    top: auto;
  }
}

/*==============================*/
/*PRIX NAV*/
/*==============================*/
/* line 946, ../sass/_layout.scss */
.award-menu-container {
  position: relative;
  text-align: center;
}
/* line 950, ../sass/_layout.scss */
.award-menu-container .logo-prix {
  margin: 10px 0;
}
/* line 953, ../sass/_layout.scss */
.award-menu-container .logo-prix img {
  vertical-align: top;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 950, ../sass/_layout.scss */
  .award-menu-container .logo-prix {
    position: absolute;
    bottom: -35px;
    left: 0;
    z-index: 2;
  }
}
/* line 965, ../sass/_layout.scss */
.award-menu-container .award-menu {
  margin-bottom: 20px;
}
/* line 970, ../sass/_layout.scss */
.award-menu-container .award-menu li {
  margin: 0 15px;
}
/* line 973, ../sass/_layout.scss */
.award-menu-container .award-menu li a {
  position: relative;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #3855a0;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1875em;
}
/* line 981, ../sass/_layout.scss */
.award-menu-container .award-menu li a:hover, .award-menu-container .award-menu li a:focus, .award-menu-container .award-menu li a.active {
  color: #75c200;
}
/* line 986, ../sass/_layout.scss */
.award-menu-container .award-menu li a:hover:before, .award-menu-container .award-menu li a:focus:before, .award-menu-container .award-menu li a.active:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background: #75c200;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 965, ../sass/_layout.scss */
  .award-menu-container .award-menu {
    margin: 0;
    padding: 35px 0 20px 230px;
    text-align: left;
  }
}

/*==============================*/
/*SIDEBAR*/
/*==============================*/
/* line 1011, ../sass/_layout.scss */
.side-bar {
  display: none;
}
@media print, screen and (min-width: 78.125em) {
  /* line 1011, ../sass/_layout.scss */
  .side-bar {
    display: block;
    position: fixed;
    top: 250px;
    right: 2%;
    z-index: 9;
  }
  /* line 1021, ../sass/_layout.scss */
  .side-bar .side-bar-list {
    margin: 0;
  }
  /* line 1024, ../sass/_layout.scss */
  .side-bar .side-bar-list li {
    position: relative;
    min-width: 44px;
    min-height: 44px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 44px;
    overflow: visible;
  }
  /* line 1034, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-link,
  .side-bar .side-bar-list .print-link {
    padding: 0;
    color: #0095CA;
    border: none;
    background: none;
    text-decoration: none;
    font-size: 1.875em;
  }
  /* line 1044, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu {
    position: absolute;
    display: none;
    padding: 0 10px;
    right: 0;
    top: -6px;
    height: 100%;
    -moz-box-shadow: grey 0 0 10px;
    -webkit-box-shadow: grey 0 0 10px;
    box-shadow: grey 0 0 10px;
    background: white;
    white-space: nowrap;
    color: #707070;
    font-size: 0.875em;
  }
  /* line 1057, ../sass/_layout.scss */
  .ie8 .side-bar .side-bar-list .side-bar-menu {
    border: 1px solid grey;
  }
  /* line 1061, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu.share-menu ul {
    margin: 0;
    display: inline-block;
  }
  /* line 1065, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu.share-menu ul li {
    margin-bottom: 0;
    display: inline-block;
  }
  /* line 1069, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu.share-menu ul li .icon-fb,
  .side-bar .side-bar-list .side-bar-menu.share-menu ul li .icon-twitter,
  .side-bar .side-bar-list .side-bar-menu.share-menu ul li .icon-gplus,
  .side-bar .side-bar-list .side-bar-menu.share-menu ul li .icon-linkedin {
    font-size: 1.25em;
  }
  /* line 1078, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu button,
  .side-bar .side-bar-list .side-bar-menu a {
    display: inline-block;
    margin-left: 10px;
    background: none;
    border: none;
    color: #0095CA;
    text-decoration: none;
  }
  /* line 1088, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .icon-close, .side-bar .side-bar-list .side-bar-menu .page-header-wrapper .sub-menu .sub-menu-footer .close-block .close-sub-menu-btn, .page-header-wrapper .sub-menu .sub-menu-footer .close-block .side-bar .side-bar-list .side-bar-menu .close-sub-menu-btn, .side-bar .side-bar-list .side-bar-menu .page-header-wrapper .mobile-menu-btn.is-open .icon-bars, .page-header-wrapper .mobile-menu-btn.is-open .side-bar .side-bar-list .side-bar-menu .icon-bars, .side-bar .side-bar-list .side-bar-menu .page-header-wrapper .is-open.margin-top-adjust .icon-bars, .page-header-wrapper .is-open.margin-top-adjust .side-bar .side-bar-list .side-bar-menu .icon-bars {
    color: #707070;
  }
  /* line 1092, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .font-btn-list {
    margin: 0;
    display: inline-block;
  }
  /* line 1096, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .font-btn-list li {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
  }
  /* line 1101, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .font-btn-list li .icon-font-lower:before {
    content: "\e904";
    font-size: 1em;
    line-height: 18px;
  }
  /* line 1107, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .font-btn-list li .icon-font-default:before {
    content: "\e903";
    font-size: 1.125em;
    line-height: 18px;
  }
  /* line 1113, ../sass/_layout.scss */
  .side-bar .side-bar-list .side-bar-menu .font-btn-list li .icon-font-increase:before {
    content: "\e901";
    font-size: 1.25em;
    line-height: 18px;
  }
}

/*==============================*/
/*BREADCRUMB*/
/*==============================*/
/* line 1131, ../sass/_layout.scss */
.breadcrumb {
  padding: 15px 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1131, ../sass/_layout.scss */
  .breadcrumb {
    margin-top: 97px;
  }
}
/* line 1138, ../sass/_layout.scss */
.breadcrumb .breadcrumb-list {
  margin: 0;
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1138, ../sass/_layout.scss */
  .breadcrumb .breadcrumb-list {
    text-align: left;
  }
}
/* line 1147, ../sass/_layout.scss */
.breadcrumb .breadcrumb-list li {
  display: inline-block;
  margin: 0;
  color: #707070;
}
/* line 1153, ../sass/_layout.scss */
.breadcrumb .breadcrumb-list li:before {
  margin: 10px;
  font-size: 0.75em;
}
/* line 1159, ../sass/_layout.scss */
.breadcrumb .breadcrumb-list li a,
.breadcrumb .breadcrumb-list li span {
  color: #707070;
}
/* line 1166, ../sass/_layout.scss */
.breadcrumb .breadcrumb-list li:first-child:before {
  display: none;
}

/*==============================*/
/*CONTENT*/
/*==============================*/
@media print, screen and (min-width: 64.0625em) {
  /* line 1178, ../sass/_layout.scss */
  .global-wrapper {
    background: url('../images/heading-bg-desktop.png?1633013057') no-repeat center top;
  }
}

/* line 1185, ../sass/_layout.scss */
.margin-top-adjust {
  padding: 5px;
  visibility: hidden;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1185, ../sass/_layout.scss */
  .margin-top-adjust {
    display: none;
  }
}

@media print, screen and (min-width: 64.0625em) {
  /* line 1195, ../sass/_layout.scss */
  .page-content-wrapper {
    padding: 0;
  }
}

/*==============================*/
/*AWARD STEP*/
/*==============================*/
/* line 1208, ../sass/_layout.scss */
.award-step-1 {
  position: relative;
  background: url('../images/award-banner-bg.jpg?1633011802') no-repeat center center;
  background-size: cover;
}
/* line 1215, ../sass/_layout.scss */
.contraste .award-step-1:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
/* line 1226, ../sass/_layout.scss */
.award-step-1 .site-content {
  background: none;
}
/* line 1230, ../sass/_layout.scss */
.award-step-1 .le-prix-block {
  background: none;
}
/* line 1233, ../sass/_layout.scss */
.award-step-1 .le-prix-block .double-line-title {
  color: white;
  border-color: white;
}
/* line 1239, ../sass/_layout.scss */
.award-step-1 .title-container {
  text-align: center;
}
/* line 1242, ../sass/_layout.scss */
.award-step-1 .title-container .double-line-title {
  display: inline-block;
  padding: 10px 0;
  color: white !important;
  border-color: white !important;
  border-width: 3px;
  text-transform: uppercase;
  font-size: 2.5em;
}
@media print, screen and (min-width: 48em) {
  /* line 1242, ../sass/_layout.scss */
  .award-step-1 .title-container .double-line-title {
    font-size: 4.375em;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1242, ../sass/_layout.scss */
  .award-step-1 .title-container .double-line-title {
    font-size: 5.9375em;
  }
}
/* line 1259, ../sass/_layout.scss */
.award-step-1 .title-container .double-line-title sup {
  font-size: 50%;
}

/*==============================*/
/*COLORBOX*/
/*==============================*/
/* line 1271, ../sass/_layout.scss */
#colorbox {
  background: rgba(0, 0, 0, 0.9);
}
/* line 1274, ../sass/_layout.scss */
.ie8 #colorbox {
  background: url('../images/slide-desc-bg.png?1633011802');
}
/* line 1278, ../sass/_layout.scss */
#colorbox #cboxLoadedContent {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  padding: 10px;
  color: white;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1278, ../sass/_layout.scss */
  #colorbox #cboxLoadedContent {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  #colorbox #cboxLoadedContent:before, #colorbox #cboxLoadedContent:after {
    content: "";
    display: table;
    clear: both;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1290, ../sass/_layout.scss */
  #colorbox .video-modale-column {
    float: left;
    width: 50%;
    padding: 20px;
  }
}
/* line 1299, ../sass/_layout.scss */
#colorbox .double-line-title {
  color: white;
  border-color: white;
}
/* line 1304, ../sass/_layout.scss */
#colorbox #cboxClose {
  position: absolute;
  padding: 5px;
  top: 10px;
  left: 10px;
  border: none;
  background: none;
  color: white;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
/* line 128, ../sass/_mixins.scss */
#colorbox #cboxClose:hover, #colorbox #cboxClose:focus {
  color: #3855a0;
}
/* line 135, ../sass/_mixins.scss */
.contraste #colorbox #cboxClose:hover, .contraste #colorbox #cboxClose:focus {
  color: #404040;
}
/* line 1315, ../sass/_layout.scss */
#colorbox #cboxClose:before {
  content: "X";
  font-size: 1.25em;
  margin-right: 10px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1304, ../sass/_layout.scss */
  #colorbox #cboxClose {
    top: 30px;
    left: 30px;
  }
}
/* line 1327, ../sass/_layout.scss */
#colorbox .modale-margin-adjust {
  visibility: hidden;
  margin-bottom: 40px;
  font-size: 1.25em;
}
/* line 1333, ../sass/_layout.scss */
#colorbox .site-content {
  background: none;
}

/*==============================*/
/*FOOTER*/
/*==============================*/
/* line 1345, ../sass/_layout.scss */
#back-top {
  display: none;
  right: 2%;
  min-width: 44px;
  text-align: center;
  color: #0095CA;
  text-decoration: none;
  font-size: 1.875em;
  z-index: 5;
}
/* line 1355, ../sass/_layout.scss */
#back-top:hover {
  cursor: pointer;
}

/* line 1360, ../sass/_layout.scss */
.page-footer-wrapper {
  padding: 0 15px;
  margin-top: 50px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1360, ../sass/_layout.scss */
  .page-footer-wrapper {
    background: url('../images/footer-bg-desktop.png?1633011802') no-repeat center bottom;
  }
}
/* line 1369, ../sass/_layout.scss */
.page-footer-wrapper .line-bg-txt {
  font-size: 1.25em;
}
/* line 1372, ../sass/_layout.scss */
.page-footer-wrapper .line-bg-txt h1 {
  font-size: inherit;
  margin: 0;
}
/* line 1378, ../sass/_layout.scss */
.page-footer-wrapper .footer-desktop {
  display: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1378, ../sass/_layout.scss */
  .page-footer-wrapper .footer-desktop {
    display: block;
  }
}
/* line 1386, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-list {
  *zoom: 1;
  letter-spacing: -0.3em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 41, ../sass/_mixins.scss */
.page-footer-wrapper .footer-map-list:before, .page-footer-wrapper .footer-map-list:after {
  content: "";
  display: table;
  clear: both;
}
/* line 1391, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-list > li {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  letter-spacing: normal;
}
/* line 1401, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-list > li:nth-child(3n+1) {
  border-left: none;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1391, ../sass/_layout.scss */
  .page-footer-wrapper .footer-map-list > li {
    width: 33%;
    margin-bottom: 20px;
    border-left: 2px dotted #e4e4e4;
    padding-left: 7px;
  }
  /* line 1411, ../sass/_layout.scss */
  .page-footer-wrapper .footer-map-list > li:first-child {
    border-left: none;
    padding-left: 0;
  }
}
/* line 1417, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-list > li .footer-map-list-title,
.page-footer-wrapper .footer-map-list > li > a {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-decoration: none;
}
/* line 1425, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-sublist {
  margin-bottom: 0px;
}
/* line 1430, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-sublist li a {
  text-decoration: none;
  font-size: 0.9375em;
}
/* line 1435, ../sass/_layout.scss */
.page-footer-wrapper .footer-map-sublist li:before {
  content: "-";
  margin-right: 5px;
}
/* line 1442, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-1 {
  text-align: center;
}
/* line 1445, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-1 li {
  display: inline-block;
  margin: 10px 16px;
}
/* line 1449, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-1 li a {
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
}
/* line 1457, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-2 {
  text-align: center;
  background: #f3f2f1;
  margin: 0;
  padding: 8px;
}
/* line 1463, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-2 li {
  display: inline-block;
  margin: 10px 16px;
}
/* line 1467, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-2 li a {
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  color: #808080;
}
/* line 1473, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-2 li a.rss-link {
  color: #EA670A;
}
/* line 1477, ../sass/_layout.scss */
.page-footer-wrapper .footer-list-2 li a.rss-link:before {
  margin-right: 5px;
}
/* line 1486, ../sass/_layout.scss */
.page-footer-wrapper .footer-site-list {
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1486, ../sass/_layout.scss */
  .page-footer-wrapper .footer-site-list {
    text-align: left;
  }
}
/* line 1493, ../sass/_layout.scss */
.page-footer-wrapper .footer-site-list li {
  display: inline-block;
  vertical-align: top;
  margin: 0 16px;
}
@media print, screen and (min-width: 48em) {
  /* line 1493, ../sass/_layout.scss */
  .page-footer-wrapper .footer-site-list li {
    margin: 0 0 0 15px;
  }
  /* line 1501, ../sass/_layout.scss */
  .page-footer-wrapper .footer-site-list li:first-child {
    margin-left: 0;
  }
}
/* line 1508, ../sass/_layout.scss */
.page-footer-wrapper .footer-bottom {
  padding-top: 40px;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1508, ../sass/_layout.scss */
  .page-footer-wrapper .footer-bottom {
    *zoom: 1;
  }
  /* line 41, ../sass/_mixins.scss */
  .page-footer-wrapper .footer-bottom:before, .page-footer-wrapper .footer-bottom:after {
    content: "";
    display: table;
    clear: both;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1516, ../sass/_layout.scss */
  .page-footer-wrapper .footer-bottom-left {
    float: left;
    width: 58%;
    margin-right: 2%;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1525, ../sass/_layout.scss */
  .page-footer-wrapper .footer-bottom-right {
    float: left;
    width: 40%;
  }
}
/* line 1535, ../sass/_layout.scss */
.page-footer-wrapper .footer-bottom-right .social-list {
  width: 100%;
  text-align: center;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1535, ../sass/_layout.scss */
  .page-footer-wrapper .footer-bottom-right .social-list {
    text-align: left;
  }
}
/* line 1543, ../sass/_layout.scss */
.page-footer-wrapper .footer-bottom-right .social-list a {
  position: relative;
}
/* line 1546, ../sass/_layout.scss */
.page-footer-wrapper .footer-bottom-right .social-list a .social-name {
  display: none;
  position: absolute;
  bottom: -25px;
  left: -28px;
  width: 100px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 0.5em;
}
/* line 1561, ../sass/_layout.scss */
.page-footer-wrapper .footer-bottom-right .social-list a:hover .social-name, .page-footer-wrapper .footer-bottom-right .social-list a:focus .social-name {
  display: block;
}

/*
 * Layout for Fondation pages
 */
/* line 5, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"],
.page-theming,
.layout-fondation {
  /* Page Scoopit */
  /**************************************
  ---- MENU BO
  **************************************/
  /**************************************
  ---- PEOPLE LIST
  **************************************/
  /**************************************
  ---- PEOPLE DETAIL
  **************************************/
  /**************************************
  ---- CARROUSEL
  **************************************/
  /**************************************
  ---- FONDATION HEADER
  **************************************/
  /**************************************
  ---- MENU
  **************************************/
  /**************************************
  ---- BREADCRUMB
  **************************************/
  /**************************************
  ---- TITLES
  **************************************/
  /**************************************
  ---- TEXT INTRO
  **************************************/
  /**************************************
  ---- SEARCH BAR
  **************************************/
  /**************************************
  ---- NO RESULTS
  **************************************/
  /**************************************
  ---- RSS FEED
  **************************************/
  /**************************************
  ---- SEARCH BAR + RSS FEED
  **************************************/
  /**************************************
  ---- BLOCS
  **************************************/
  /**************************************
  ---- BLOCS SLIDER
  **************************************/
  /**************************************
  ---- COLOR GRID INFOS
  **************************************/
  /**************************************
  ---- SOMMAIRE BTN
  **************************************/
  /**************************************
  ---- BACK TO TOP
  **************************************/
  /**************************************
  ---- FOOTER
  **************************************/
  /**************************************
  ---- THUMBNAILS
  **************************************/
  /**************************************
  ---- TRIPLE SLIDER
  **************************************/
  /**************************************
  ---- PAGER
  **************************************/
  /**************************************
  ---- FILTER LIST
  **************************************/
  /**************************************
  ---- LIST ACTUALITES
  **************************************/
  /**************************************
  ---- WYSIWYG
  **************************************/
  /**************************************
  ---- DETAIL RESSOURCE
  **************************************/
  /**************************************
  ---- NOTATION AND SHARE
  **************************************/
}
/* line 12, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .color-grid.orange,
.page-theming .color-grid.orange,
.layout-fondation .color-grid.orange {
  background-color: #163375;
}
/* line 16, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .color-grid.pink,
.page-theming .color-grid.pink,
.layout-fondation .color-grid.pink {
  background-color: #EA670A;
}
/* line 22, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"].node-type-scoopit .breadcrumb,
.page-theming.node-type-scoopit .breadcrumb,
.layout-fondation.node-type-scoopit .breadcrumb {
  margin-top: 90px;
}
/* line 25, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"].node-type-scoopit .tabs,
.page-theming.node-type-scoopit .tabs,
.layout-fondation.node-type-scoopit .tabs {
  margin-top: 0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 30, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .global-wrapper,
  .page-theming .global-wrapper,
  .layout-fondation .global-wrapper {
    background: url('../images/heading-bg-desktop-fondation.png?1633013057') no-repeat center top;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 39, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .tabs,
  .page-theming .tabs,
  .layout-fondation .tabs {
    margin-top: 120px;
  }
}
/* line 50, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sub-intro,
.page-theming .sub-intro,
.layout-fondation .sub-intro {
  margin-bottom: 25px;
  color: #3855a0;
}
/* line 54, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .people-list-wrapper,
.page-theming .people-list-wrapper,
.layout-fondation .people-list-wrapper {
  overflow: hidden;
}
/* line 57, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .people-list,
.page-theming .people-list,
.layout-fondation .people-list {
  *zoom: 1;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
  letter-spacing: -0.31em;
}
/* line 41, ../sass/_mixins.scss */
[class*="node-type-fondation"] .people-list:before, [class*="node-type-fondation"] .people-list:after,
.page-theming .people-list:before,
.page-theming .people-list:after,
.layout-fondation .people-list:before,
.layout-fondation .people-list:after {
  content: "";
  display: table;
  clear: both;
}
/* line 63, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .people-list li,
.page-theming .people-list li,
.layout-fondation .people-list li {
  display: inline-block;
  letter-spacing: normal;
  width: 176px;
  vertical-align: top;
  padding: 0 15px 15px;
  text-align: left;
}
/* line 73, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people .people-name,
.page-theming .bk-people .people-name,
.layout-fondation .bk-people .people-name {
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
/* line 77, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people .people-name a,
.page-theming .bk-people .people-name a,
.layout-fondation .bk-people .people-name a {
  text-decoration: none;
  line-height: 1;
}
/* line 80, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people .people-name a:hover, [class*="node-type-fondation"] .bk-people .people-name a:focus,
.page-theming .bk-people .people-name a:hover,
.page-theming .bk-people .people-name a:focus,
.layout-fondation .bk-people .people-name a:hover,
.layout-fondation .bk-people .people-name a:focus {
  text-decoration: underline;
}
/* line 85, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people .people-site,
.page-theming .bk-people .people-site,
.layout-fondation .bk-people .people-site {
  color: #a09a9a;
}
/* line 94, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail,
.page-theming .bk-people-detail,
.layout-fondation .bk-people-detail {
  border: 15px solid #ebf1f3;
  padding: 30px;
  color: #3855a0;
}
/* line 98, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content,
.page-theming .bk-people-detail .people-content,
.layout-fondation .bk-people-detail .people-content {
  max-width: 660px;
  margin: 0 auto 50px;
  *zoom: 1;
}
/* line 41, ../sass/_mixins.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content:before, [class*="node-type-fondation"] .bk-people-detail .people-content:after,
.page-theming .bk-people-detail .people-content:before,
.page-theming .bk-people-detail .people-content:after,
.layout-fondation .bk-people-detail .people-content:before,
.layout-fondation .bk-people-detail .people-content:after {
  content: "";
  display: table;
  clear: both;
}
/* line 102, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content .people-visu,
.page-theming .bk-people-detail .people-content .people-visu,
.layout-fondation .bk-people-detail .people-content .people-visu {
  width: 173px;
  float: left;
  margin-right: 30px;
}
/* line 106, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content .people-visu img,
.page-theming .bk-people-detail .people-content .people-visu img,
.layout-fondation .bk-people-detail .people-content .people-visu img {
  width: 100%;
}
/* line 110, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content .people-text,
.page-theming .bk-people-detail .people-content .people-text,
.layout-fondation .bk-people-detail .people-content .people-text {
  float: left;
  width: calc(100% - 203px);
}
/* line 113, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content .people-text .people-name,
.page-theming .bk-people-detail .people-content .people-text .people-name,
.layout-fondation .bk-people-detail .people-content .people-text .people-name {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 7px;
}
/* line 119, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .people-content .people-text .people-fonction,
.page-theming .bk-people-detail .people-content .people-text .people-fonction,
.layout-fondation .bk-people-detail .people-content .people-text .people-fonction {
  margin-bottom: 15px;
  color: #a09a9a;
  font-size: 20px;
  font-weight: normal;
  font-family: Arial;
}
/* line 129, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .ta-c .btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-orange, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn, #page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-green, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn, #page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn,
#page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-blue, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn, #page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-pink, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn, #page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-purple, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn, #page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-grey, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-grey, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-green, [class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .video-btn,
[class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .project-btn,
.page-theming .bk-people-detail .ta-c .btn,
.page-theming .bk-people-detail .ta-c .btn-orange,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .line-bg-right-btn,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .pdf-dl-btn,
.page-theming .bk-people-detail .ta-c .btn-green,
.page-theming .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn,
#page-garantie.green .page-theming .bk-people-detail .ta-c .line-bg-right-btn,
.page-theming .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn,
#page-garantie.green .page-theming .bk-people-detail .ta-c .pdf-dl-btn,
.page-theming .bk-people-detail .ta-c .btn-light-blue,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .line-bg-right-btn,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .pdf-dl-btn,
.page-theming .bk-people-detail .ta-c .btn-pink,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .line-bg-right-btn,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .pdf-dl-btn,
.page-theming .bk-people-detail .ta-c .btn-purple,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .line-bg-right-btn,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .pdf-dl-btn,
.page-theming .bk-people-detail .ta-c .btn-light-grey,
.page-theming .bk-people-detail .ta-c .btn-grey,
.page-theming .bk-people-detail .ta-c .btn-light-green,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .video-btn,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .project-btn,
.layout-fondation .bk-people-detail .ta-c .btn,
.layout-fondation .bk-people-detail .ta-c .btn-orange,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn,
.layout-fondation .bk-people-detail .ta-c .btn-green,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn,
.layout-fondation .bk-people-detail .ta-c .btn-light-blue,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn,
.layout-fondation .bk-people-detail .ta-c .btn-pink,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn,
.layout-fondation .bk-people-detail .ta-c .btn-purple,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn,
.layout-fondation .bk-people-detail .ta-c .btn-light-grey,
.layout-fondation .bk-people-detail .ta-c .btn-grey,
.layout-fondation .bk-people-detail .ta-c .btn-light-green,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .video-btn,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .project-btn {
  background-color: #163375;
  border: 1px solid #163375;
  color: white;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 10px 60px;
}
/* line 138, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-people-detail .ta-c .btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-orange:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:focus, #page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:focus,
#page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-green:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:focus, #page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:focus,
#page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-blue:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:focus, #page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:focus,
#page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-pink:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:focus, #page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:focus,
#page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-purple:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:focus, #page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:focus,
#page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-grey:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-grey:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-green:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:focus, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .video-btn:focus,
[class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .project-btn:focus, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-orange:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:hover, #page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:hover,
#page-garantie.orange [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-green:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:hover, #page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:hover,
#page-garantie.green [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-blue:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:hover, #page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:hover,
#page-garantie.light-blue [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-pink:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:hover, #page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:hover,
#page-garantie.pink [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-purple:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:hover, #page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .line-bg-right-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:hover,
#page-garantie.purple [class*="node-type-fondation"] .bk-people-detail .ta-c .pdf-dl-btn:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-grey:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-grey:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .btn-light-green:hover, [class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .video-btn:hover,
[class*="node-type-fondation"] .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-people-detail .ta-c .project-btn:hover,
.page-theming .bk-people-detail .ta-c .btn:focus,
.page-theming .bk-people-detail .ta-c .btn-orange:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:focus,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .line-bg-right-btn:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:focus,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .pdf-dl-btn:focus,
.page-theming .bk-people-detail .ta-c .btn-green:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:focus,
#page-garantie.green .page-theming .bk-people-detail .ta-c .line-bg-right-btn:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:focus,
#page-garantie.green .page-theming .bk-people-detail .ta-c .pdf-dl-btn:focus,
.page-theming .bk-people-detail .ta-c .btn-light-blue:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:focus,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .line-bg-right-btn:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:focus,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .pdf-dl-btn:focus,
.page-theming .bk-people-detail .ta-c .btn-pink:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:focus,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .line-bg-right-btn:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:focus,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .pdf-dl-btn:focus,
.page-theming .bk-people-detail .ta-c .btn-purple:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:focus,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .line-bg-right-btn:focus,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:focus,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .pdf-dl-btn:focus,
.page-theming .bk-people-detail .ta-c .btn-light-grey:focus,
.page-theming .bk-people-detail .ta-c .btn-grey:focus,
.page-theming .bk-people-detail .ta-c .btn-light-green:focus,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .video-btn:focus,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .project-btn:focus,
.page-theming .bk-people-detail .ta-c .btn:hover,
.page-theming .bk-people-detail .ta-c .btn-orange:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:hover,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .line-bg-right-btn:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:hover,
#page-garantie.orange .page-theming .bk-people-detail .ta-c .pdf-dl-btn:hover,
.page-theming .bk-people-detail .ta-c .btn-green:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:hover,
#page-garantie.green .page-theming .bk-people-detail .ta-c .line-bg-right-btn:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:hover,
#page-garantie.green .page-theming .bk-people-detail .ta-c .pdf-dl-btn:hover,
.page-theming .bk-people-detail .ta-c .btn-light-blue:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:hover,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .line-bg-right-btn:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:hover,
#page-garantie.light-blue .page-theming .bk-people-detail .ta-c .pdf-dl-btn:hover,
.page-theming .bk-people-detail .ta-c .btn-pink:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:hover,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .line-bg-right-btn:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:hover,
#page-garantie.pink .page-theming .bk-people-detail .ta-c .pdf-dl-btn:hover,
.page-theming .bk-people-detail .ta-c .btn-purple:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:hover,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .line-bg-right-btn:hover,
.page-theming .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:hover,
#page-garantie.purple .page-theming .bk-people-detail .ta-c .pdf-dl-btn:hover,
.page-theming .bk-people-detail .ta-c .btn-light-grey:hover,
.page-theming .bk-people-detail .ta-c .btn-grey:hover,
.page-theming .bk-people-detail .ta-c .btn-light-green:hover,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .video-btn:hover,
.page-theming .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-people-detail .ta-c .project-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-orange:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:focus,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:focus,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-green:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:focus,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:focus,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-light-blue:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:focus,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:focus,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-pink:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:focus,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:focus,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-purple:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:focus,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:focus,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:focus,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn-light-grey:focus,
.layout-fondation .bk-people-detail .ta-c .btn-grey:focus,
.layout-fondation .bk-people-detail .ta-c .btn-light-green:focus,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .video-btn:focus,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .project-btn:focus,
.layout-fondation .bk-people-detail .ta-c .btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-orange:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .line-bg-right-btn:hover,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.orange .pdf-dl-btn:hover,
#page-garantie.orange .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-green:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .line-bg-right-btn:hover,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.green .pdf-dl-btn:hover,
#page-garantie.green .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-light-blue:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .line-bg-right-btn:hover,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.light-blue .pdf-dl-btn:hover,
#page-garantie.light-blue .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-pink:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .line-bg-right-btn:hover,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.pink .pdf-dl-btn:hover,
#page-garantie.pink .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-purple:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .line-bg-right-btn:hover,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .line-bg-right-btn:hover,
.layout-fondation .bk-people-detail .ta-c #page-garantie.purple .pdf-dl-btn:hover,
#page-garantie.purple .layout-fondation .bk-people-detail .ta-c .pdf-dl-btn:hover,
.layout-fondation .bk-people-detail .ta-c .btn-light-grey:hover,
.layout-fondation .bk-people-detail .ta-c .btn-grey:hover,
.layout-fondation .bk-people-detail .ta-c .btn-light-green:hover,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .video-btn:hover,
.layout-fondation .bk-people-detail .ta-c .node-type-laureate-list #liste-laureats > li.laureat .project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-people-detail .ta-c .project-btn:hover {
  background-color: white;
  color: #163375;
  border: 1px solid #163375;
}
@media only screen and (max-width: 48em) {
  /* line 148, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-people-detail .people-content .people-visu,
  .page-theming .bk-people-detail .people-content .people-visu,
  .layout-fondation .bk-people-detail .people-content .people-visu {
    float: none;
    margin: 0 auto 20px;
  }
  /* line 152, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-people-detail .people-content .people-text,
  .page-theming .bk-people-detail .people-content .people-text,
  .layout-fondation .bk-people-detail .people-content .people-text {
    float: none;
    width: 100%;
  }
  /* line 155, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-people-detail .people-content .people-text .people-name, [class*="node-type-fondation"] .bk-people-detail .people-content .people-text .people-fonction,
  .page-theming .bk-people-detail .people-content .people-text .people-name,
  .page-theming .bk-people-detail .people-content .people-text .people-fonction,
  .layout-fondation .bk-people-detail .people-content .people-text .people-name,
  .layout-fondation .bk-people-detail .people-content .people-text .people-fonction {
    text-align: center;
  }
}
/* line 162, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .ta-c,
.page-theming .ta-c,
.layout-fondation .ta-c {
  text-align: center;
}
/* line 172, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bx-pager-top .bx-pager,
.page-theming .carrousel-bleu .top-slider .bx-pager-top .bx-pager,
.layout-fondation .carrousel-bleu .top-slider .bx-pager-top .bx-pager {
  top: 0;
}
/* line 176, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:focus, [class*="node-type-fondation"] .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:hover, [class*="node-type-fondation"] .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button.active,
.page-theming .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:focus,
.page-theming .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:hover,
.page-theming .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button.active,
.layout-fondation .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:focus,
.layout-fondation .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button:hover,
.layout-fondation .carrousel-bleu .top-slider .bx-pager-top .bx-pager li button.active {
  background: #EA670A;
}
/* line 185, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .auto-controls,
.page-theming .carrousel-bleu .top-slider .auto-controls,
.layout-fondation .carrousel-bleu .top-slider .auto-controls {
  top: 10px;
}
/* line 188, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .auto-controls button:before,
.page-theming .carrousel-bleu .top-slider .auto-controls button:before,
.layout-fondation .carrousel-bleu .top-slider .auto-controls button:before {
  color: #EA670A;
}
/* line 196, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper {
  padding: 40px 0 60px;
}
@media only screen and (max-width: 64em) {
  /* line 196, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper,
  .page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper,
  .layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-content-wrapper {
    padding: 0;
  }
}
/* line 202, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title {
  border-top: none;
  border-bottom: 5px solid #EA670A;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  text-align: left;
  text-transform: initial;
  width: 100%;
}
@media only screen and (max-width: 64em) {
  /* line 202, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title,
  .page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title,
  .layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title {
    text-align: center;
  }
}
/* line 213, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title .slide-title-link,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title .slide-title-link,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-title .slide-title-link {
  text-decoration: none;
  display: inline;
}
/* line 218, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .slide-text,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .slide-text,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .slide-text {
  color: #3855a0;
  font-size: 1em;
  width: 100%;
}
/* line 224, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .text-center,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .text-center,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .text-center {
  bottom: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 224, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .text-center,
  .page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .text-center,
  .layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .text-center {
    bottom: 0;
  }
}
/* line 229, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link {
  background-color: #163375;
  border-color: #163375;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  text-transform: uppercase;
}
/* line 235, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:focus, [class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:hover,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:focus,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:hover,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:focus,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content .text-center .slide-link:hover {
  background: white;
  color: #163375;
}
/* line 241, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content.blue,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content.blue,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content.blue {
  background-color: #ebf1f3;
}
/* line 245, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .bxslider li .slide-content.black .slide-text,
.page-theming .carrousel-bleu .top-slider .bxslider li .slide-content.black .slide-text,
.layout-fondation .carrousel-bleu .top-slider .bxslider li .slide-content.black .slide-text {
  color: white;
}
/* line 253, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .next-control .next,
.page-theming .carrousel-bleu .top-slider .next-control .next,
.layout-fondation .carrousel-bleu .top-slider .next-control .next {
  bottom: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 253, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .carrousel-bleu .top-slider .next-control .next,
  .page-theming .carrousel-bleu .top-slider .next-control .next,
  .layout-fondation .carrousel-bleu .top-slider .next-control .next {
    bottom: 20px;
  }
}
/* line 259, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .next-control .next:focus:before, [class*="node-type-fondation"] .carrousel-bleu .top-slider .next-control .next:hover:before,
.page-theming .carrousel-bleu .top-slider .next-control .next:focus:before,
.page-theming .carrousel-bleu .top-slider .next-control .next:hover:before,
.layout-fondation .carrousel-bleu .top-slider .next-control .next:focus:before,
.layout-fondation .carrousel-bleu .top-slider .next-control .next:hover:before {
  color: #EA670A;
}
/* line 266, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .prev-control .prev,
.page-theming .carrousel-bleu .top-slider .prev-control .prev,
.layout-fondation .carrousel-bleu .top-slider .prev-control .prev {
  bottom: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 266, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .carrousel-bleu .top-slider .prev-control .prev,
  .page-theming .carrousel-bleu .top-slider .prev-control .prev,
  .layout-fondation .carrousel-bleu .top-slider .prev-control .prev {
    bottom: 20px;
  }
}
/* line 272, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .carrousel-bleu .top-slider .prev-control .prev:focus:before, [class*="node-type-fondation"] .carrousel-bleu .top-slider .prev-control .prev:hover:before,
.page-theming .carrousel-bleu .top-slider .prev-control .prev:focus:before,
.page-theming .carrousel-bleu .top-slider .prev-control .prev:hover:before,
.layout-fondation .carrousel-bleu .top-slider .prev-control .prev:focus:before,
.layout-fondation .carrousel-bleu .top-slider .prev-control .prev:hover:before {
  color: #EA670A;
}
/* line 283, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .side-bar .side-bar-list .side-bar-link,
[class*="node-type-fondation"] .side-bar .side-bar-list .print-link,
.page-theming .side-bar .side-bar-list .side-bar-link,
.page-theming .side-bar .side-bar-list .print-link,
.layout-fondation .side-bar .side-bar-list .side-bar-link,
.layout-fondation .side-bar .side-bar-list .print-link {
  color: #3855a0;
}
/* line 293, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-header,
.page-theming .fondation-header,
.layout-fondation .fondation-header {
  margin-top: 120px;
  text-align: center;
}
@media only screen and (max-width: 64em) {
  /* line 293, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-header,
  .page-theming .fondation-header,
  .layout-fondation .fondation-header {
    margin-top: 30px;
  }
}
/* line 300, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-header .fondation-header-wrapper,
.page-theming .fondation-header .fondation-header-wrapper,
.layout-fondation .fondation-header .fondation-header-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
@media only screen and (max-width: 64em) {
  /* line 300, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-header .fondation-header-wrapper,
  .page-theming .fondation-header .fondation-header-wrapper,
  .layout-fondation .fondation-header .fondation-header-wrapper {
    align-items: center;
  }
}
/* line 308, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-header .fondation-header-wrapper .fondation-header-title,
.page-theming .fondation-header .fondation-header-wrapper .fondation-header-title,
.layout-fondation .fondation-header .fondation-header-wrapper .fondation-header-title {
  font-size: 4.375em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  color: #3855a0;
  line-height: normal;
}
@media only screen and (max-width: 64em) {
  /* line 308, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-header .fondation-header-wrapper .fondation-header-title,
  .page-theming .fondation-header .fondation-header-wrapper .fondation-header-title,
  .layout-fondation .fondation-header .fondation-header-wrapper .fondation-header-title {
    font-size: 3.125em;
  }
}
/* line 322, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-header .fondation-header-wrapper .fondation-header-subtitle,
.page-theming .fondation-header .fondation-header-wrapper .fondation-header-subtitle,
.layout-fondation .fondation-header .fondation-header-wrapper .fondation-header-subtitle {
  color: #3855a0;
  font-size: 1em;
}
/* line 333, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-navigation ul,
.page-theming .fondation-navigation ul,
.layout-fondation .fondation-navigation ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ebf1f3;
  margin-top: 40px;
}
@media only screen and (max-width: 64em) {
  /* line 333, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-navigation ul,
  .page-theming .fondation-navigation ul,
  .layout-fondation .fondation-navigation ul {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}
/* line 344, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-navigation ul li,
.page-theming .fondation-navigation ul li,
.layout-fondation .fondation-navigation ul li {
  margin-bottom: 0;
}
@media only screen and (max-width: 64em) {
  /* line 347, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-navigation ul li:not(:last-child):after,
  .page-theming .fondation-navigation ul li:not(:last-child):after,
  .layout-fondation .fondation-navigation ul li:not(:last-child):after {
    content: '-';
    display: inline-block;
    margin: 0 5px;
  }
}
/* line 353, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-navigation ul li a,
.page-theming .fondation-navigation ul li a,
.layout-fondation .fondation-navigation ul li a {
  padding: 25px 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  color: #3855a0;
  font-size: 1.25em;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
/* line 360, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .fondation-navigation ul li a.active, [class*="node-type-fondation"] .fondation-navigation ul li a:focus, [class*="node-type-fondation"] .fondation-navigation ul li a:hover,
.page-theming .fondation-navigation ul li a.active,
.page-theming .fondation-navigation ul li a:focus,
.page-theming .fondation-navigation ul li a:hover,
.layout-fondation .fondation-navigation ul li a.active,
.layout-fondation .fondation-navigation ul li a:focus,
.layout-fondation .fondation-navigation ul li a:hover {
  background-color: #EA670A;
  color: white;
}
@media only screen and (max-width: 64em) {
  /* line 353, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .fondation-navigation ul li a,
  .page-theming .fondation-navigation ul li a,
  .layout-fondation .fondation-navigation ul li a {
    padding: 6px 10px;
  }
}
/* line 377, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .breadcrumb,
.page-theming .breadcrumb,
.layout-fondation .breadcrumb {
  margin-top: 0;
}
@media only screen and (max-width: 64em) {
  /* line 377, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .breadcrumb,
  .page-theming .breadcrumb,
  .layout-fondation .breadcrumb {
    padding-top: 0;
  }
}
@media only screen and (max-width: 64em) {
  /* line 382, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .breadcrumb .breadcrumb-list,
  .page-theming .breadcrumb .breadcrumb-list,
  .layout-fondation .breadcrumb .breadcrumb-list {
    padding: 0 20px;
    text-align: left;
  }
}
/* line 388, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .breadcrumb .breadcrumb-list li a,
[class*="node-type-fondation"] .breadcrumb .breadcrumb-list li span,
.page-theming .breadcrumb .breadcrumb-list li a,
.page-theming .breadcrumb .breadcrumb-list li span,
.layout-fondation .breadcrumb .breadcrumb-list li a,
.layout-fondation .breadcrumb .breadcrumb-list li span {
  color: #3855a0;
}
/* line 399, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .h1-fondation,
.page-theming .h1-fondation,
.layout-fondation .h1-fondation {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3.75em;
  color: #163375;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 64em) {
  /* line 399, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .h1-fondation,
  .page-theming .h1-fondation,
  .layout-fondation .h1-fondation {
    font-size: 2.8125em;
    margin-bottom: 20px;
  }
}
/* line 411, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .h2-fondation,
.page-theming .h2-fondation,
.layout-fondation .h2-fondation {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 3em;
  color: #163375;
  width: 100%;
  margin-bottom: 10px;
  line-height: 1.4;
}
@media only screen and (max-width: 64em) {
  /* line 411, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .h2-fondation,
  .page-theming .h2-fondation,
  .layout-fondation .h2-fondation {
    font-size: 2.5em;
  }
}
/* line 422, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .h3-fondation,
.page-theming .h3-fondation,
.layout-fondation .h3-fondation {
  font-family: Arial, helvetica, sans-serif;
  font-size: 1.5em;
  color: #3855a0;
  width: 100%;
  margin-bottom: 15px;
  line-height: 1.4;
}
/* line 430, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .h4-fondation,
.page-theming .h4-fondation,
.layout-fondation .h4-fondation {
  margin: 0 0 0.7em;
  line-height: 1.5em;
  color: #3855a0;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
}
/* line 439, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.pink, [class*="node-type-fondation"] .pink.btn-orange, [class*="node-type-fondation"] #page-garantie.orange .pink.line-bg-right-btn, #page-garantie.orange [class*="node-type-fondation"] .pink.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.orange .pink.pdf-dl-btn,
#page-garantie.orange [class*="node-type-fondation"] .pink.pdf-dl-btn, [class*="node-type-fondation"] .pink.btn-green, [class*="node-type-fondation"] #page-garantie.green .pink.line-bg-right-btn, #page-garantie.green [class*="node-type-fondation"] .pink.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.green .pink.pdf-dl-btn,
#page-garantie.green [class*="node-type-fondation"] .pink.pdf-dl-btn, [class*="node-type-fondation"] .pink.btn-light-blue, [class*="node-type-fondation"] #page-garantie.light-blue .pink.line-bg-right-btn, #page-garantie.light-blue [class*="node-type-fondation"] .pink.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.light-blue .pink.pdf-dl-btn,
#page-garantie.light-blue [class*="node-type-fondation"] .pink.pdf-dl-btn, [class*="node-type-fondation"] .pink.btn-pink, [class*="node-type-fondation"] #page-garantie.pink .pink.line-bg-right-btn, #page-garantie.pink [class*="node-type-fondation"] .pink.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.pink .pink.pdf-dl-btn,
#page-garantie.pink [class*="node-type-fondation"] .pink.pdf-dl-btn, [class*="node-type-fondation"] .pink.btn-purple, [class*="node-type-fondation"] #page-garantie.purple .pink.line-bg-right-btn, #page-garantie.purple [class*="node-type-fondation"] .pink.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.purple .pink.pdf-dl-btn,
#page-garantie.purple [class*="node-type-fondation"] .pink.pdf-dl-btn, [class*="node-type-fondation"] .pink.btn-light-grey, [class*="node-type-fondation"] .pink.btn-grey, [class*="node-type-fondation"] .pink.btn-light-green, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.video-btn,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.project-btn,
.page-theming .btn.pink,
.page-theming .pink.btn-orange,
.page-theming #page-garantie.orange .pink.line-bg-right-btn,
#page-garantie.orange .page-theming .pink.line-bg-right-btn,
.page-theming #page-garantie.orange .pink.pdf-dl-btn,
#page-garantie.orange .page-theming .pink.pdf-dl-btn,
.page-theming .pink.btn-green,
.page-theming #page-garantie.green .pink.line-bg-right-btn,
#page-garantie.green .page-theming .pink.line-bg-right-btn,
.page-theming #page-garantie.green .pink.pdf-dl-btn,
#page-garantie.green .page-theming .pink.pdf-dl-btn,
.page-theming .pink.btn-light-blue,
.page-theming #page-garantie.light-blue .pink.line-bg-right-btn,
#page-garantie.light-blue .page-theming .pink.line-bg-right-btn,
.page-theming #page-garantie.light-blue .pink.pdf-dl-btn,
#page-garantie.light-blue .page-theming .pink.pdf-dl-btn,
.page-theming .pink.btn-pink,
.page-theming #page-garantie.pink .pink.line-bg-right-btn,
#page-garantie.pink .page-theming .pink.line-bg-right-btn,
.page-theming #page-garantie.pink .pink.pdf-dl-btn,
#page-garantie.pink .page-theming .pink.pdf-dl-btn,
.page-theming .pink.btn-purple,
.page-theming #page-garantie.purple .pink.line-bg-right-btn,
#page-garantie.purple .page-theming .pink.line-bg-right-btn,
.page-theming #page-garantie.purple .pink.pdf-dl-btn,
#page-garantie.purple .page-theming .pink.pdf-dl-btn,
.page-theming .pink.btn-light-grey,
.page-theming .pink.btn-grey,
.page-theming .pink.btn-light-green,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.video-btn,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.project-btn,
.layout-fondation .btn.pink,
.layout-fondation .pink.btn-orange,
.layout-fondation #page-garantie.orange .pink.line-bg-right-btn,
#page-garantie.orange .layout-fondation .pink.line-bg-right-btn,
.layout-fondation #page-garantie.orange .pink.pdf-dl-btn,
#page-garantie.orange .layout-fondation .pink.pdf-dl-btn,
.layout-fondation .pink.btn-green,
.layout-fondation #page-garantie.green .pink.line-bg-right-btn,
#page-garantie.green .layout-fondation .pink.line-bg-right-btn,
.layout-fondation #page-garantie.green .pink.pdf-dl-btn,
#page-garantie.green .layout-fondation .pink.pdf-dl-btn,
.layout-fondation .pink.btn-light-blue,
.layout-fondation #page-garantie.light-blue .pink.line-bg-right-btn,
#page-garantie.light-blue .layout-fondation .pink.line-bg-right-btn,
.layout-fondation #page-garantie.light-blue .pink.pdf-dl-btn,
#page-garantie.light-blue .layout-fondation .pink.pdf-dl-btn,
.layout-fondation .pink.btn-pink,
.layout-fondation #page-garantie.pink .pink.line-bg-right-btn,
#page-garantie.pink .layout-fondation .pink.line-bg-right-btn,
.layout-fondation #page-garantie.pink .pink.pdf-dl-btn,
#page-garantie.pink .layout-fondation .pink.pdf-dl-btn,
.layout-fondation .pink.btn-purple,
.layout-fondation #page-garantie.purple .pink.line-bg-right-btn,
#page-garantie.purple .layout-fondation .pink.line-bg-right-btn,
.layout-fondation #page-garantie.purple .pink.pdf-dl-btn,
#page-garantie.purple .layout-fondation .pink.pdf-dl-btn,
.layout-fondation .pink.btn-light-grey,
.layout-fondation .pink.btn-grey,
.layout-fondation .pink.btn-light-green,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.video-btn,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.project-btn {
  background-color: #EA670A;
  border: 1px solid #EA670A;
  color: white;
}
/* line 443, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.pink:focus, [class*="node-type-fondation"] .pink.btn-orange:focus, [class*="node-type-fondation"] #page-garantie.orange .pink.line-bg-right-btn:focus, #page-garantie.orange [class*="node-type-fondation"] .pink.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.orange .pink.pdf-dl-btn:focus,
#page-garantie.orange [class*="node-type-fondation"] .pink.pdf-dl-btn:focus, [class*="node-type-fondation"] .pink.btn-green:focus, [class*="node-type-fondation"] #page-garantie.green .pink.line-bg-right-btn:focus, #page-garantie.green [class*="node-type-fondation"] .pink.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.green .pink.pdf-dl-btn:focus,
#page-garantie.green [class*="node-type-fondation"] .pink.pdf-dl-btn:focus, [class*="node-type-fondation"] .pink.btn-light-blue:focus, [class*="node-type-fondation"] #page-garantie.light-blue .pink.line-bg-right-btn:focus, #page-garantie.light-blue [class*="node-type-fondation"] .pink.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.light-blue .pink.pdf-dl-btn:focus,
#page-garantie.light-blue [class*="node-type-fondation"] .pink.pdf-dl-btn:focus, [class*="node-type-fondation"] .pink.btn-pink:focus, [class*="node-type-fondation"] #page-garantie.pink .pink.line-bg-right-btn:focus, #page-garantie.pink [class*="node-type-fondation"] .pink.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.pink .pink.pdf-dl-btn:focus,
#page-garantie.pink [class*="node-type-fondation"] .pink.pdf-dl-btn:focus, [class*="node-type-fondation"] .pink.btn-purple:focus, [class*="node-type-fondation"] #page-garantie.purple .pink.line-bg-right-btn:focus, #page-garantie.purple [class*="node-type-fondation"] .pink.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.purple .pink.pdf-dl-btn:focus,
#page-garantie.purple [class*="node-type-fondation"] .pink.pdf-dl-btn:focus, [class*="node-type-fondation"] .pink.btn-light-grey:focus, [class*="node-type-fondation"] .pink.btn-grey:focus, [class*="node-type-fondation"] .pink.btn-light-green:focus, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:focus, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.video-btn:focus,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.project-btn:focus, [class*="node-type-fondation"] .btn.pink:hover, [class*="node-type-fondation"] .pink.btn-orange:hover, [class*="node-type-fondation"] #page-garantie.orange .pink.line-bg-right-btn:hover, #page-garantie.orange [class*="node-type-fondation"] .pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.orange .pink.pdf-dl-btn:hover,
#page-garantie.orange [class*="node-type-fondation"] .pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .pink.btn-green:hover, [class*="node-type-fondation"] #page-garantie.green .pink.line-bg-right-btn:hover, #page-garantie.green [class*="node-type-fondation"] .pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.green .pink.pdf-dl-btn:hover,
#page-garantie.green [class*="node-type-fondation"] .pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .pink.btn-light-blue:hover, [class*="node-type-fondation"] #page-garantie.light-blue .pink.line-bg-right-btn:hover, #page-garantie.light-blue [class*="node-type-fondation"] .pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.light-blue .pink.pdf-dl-btn:hover,
#page-garantie.light-blue [class*="node-type-fondation"] .pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .pink.btn-pink:hover, [class*="node-type-fondation"] #page-garantie.pink .pink.line-bg-right-btn:hover, #page-garantie.pink [class*="node-type-fondation"] .pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.pink .pink.pdf-dl-btn:hover,
#page-garantie.pink [class*="node-type-fondation"] .pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .pink.btn-purple:hover, [class*="node-type-fondation"] #page-garantie.purple .pink.line-bg-right-btn:hover, #page-garantie.purple [class*="node-type-fondation"] .pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.purple .pink.pdf-dl-btn:hover,
#page-garantie.purple [class*="node-type-fondation"] .pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .pink.btn-light-grey:hover, [class*="node-type-fondation"] .pink.btn-grey:hover, [class*="node-type-fondation"] .pink.btn-light-green:hover, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.video-btn:hover,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .pink.project-btn:hover,
.page-theming .btn.pink:focus,
.page-theming .pink.btn-orange:focus,
.page-theming #page-garantie.orange .pink.line-bg-right-btn:focus,
#page-garantie.orange .page-theming .pink.line-bg-right-btn:focus,
.page-theming #page-garantie.orange .pink.pdf-dl-btn:focus,
#page-garantie.orange .page-theming .pink.pdf-dl-btn:focus,
.page-theming .pink.btn-green:focus,
.page-theming #page-garantie.green .pink.line-bg-right-btn:focus,
#page-garantie.green .page-theming .pink.line-bg-right-btn:focus,
.page-theming #page-garantie.green .pink.pdf-dl-btn:focus,
#page-garantie.green .page-theming .pink.pdf-dl-btn:focus,
.page-theming .pink.btn-light-blue:focus,
.page-theming #page-garantie.light-blue .pink.line-bg-right-btn:focus,
#page-garantie.light-blue .page-theming .pink.line-bg-right-btn:focus,
.page-theming #page-garantie.light-blue .pink.pdf-dl-btn:focus,
#page-garantie.light-blue .page-theming .pink.pdf-dl-btn:focus,
.page-theming .pink.btn-pink:focus,
.page-theming #page-garantie.pink .pink.line-bg-right-btn:focus,
#page-garantie.pink .page-theming .pink.line-bg-right-btn:focus,
.page-theming #page-garantie.pink .pink.pdf-dl-btn:focus,
#page-garantie.pink .page-theming .pink.pdf-dl-btn:focus,
.page-theming .pink.btn-purple:focus,
.page-theming #page-garantie.purple .pink.line-bg-right-btn:focus,
#page-garantie.purple .page-theming .pink.line-bg-right-btn:focus,
.page-theming #page-garantie.purple .pink.pdf-dl-btn:focus,
#page-garantie.purple .page-theming .pink.pdf-dl-btn:focus,
.page-theming .pink.btn-light-grey:focus,
.page-theming .pink.btn-grey:focus,
.page-theming .pink.btn-light-green:focus,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.video-btn:focus,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.project-btn:focus,
.page-theming .btn.pink:hover,
.page-theming .pink.btn-orange:hover,
.page-theming #page-garantie.orange .pink.line-bg-right-btn:hover,
#page-garantie.orange .page-theming .pink.line-bg-right-btn:hover,
.page-theming #page-garantie.orange .pink.pdf-dl-btn:hover,
#page-garantie.orange .page-theming .pink.pdf-dl-btn:hover,
.page-theming .pink.btn-green:hover,
.page-theming #page-garantie.green .pink.line-bg-right-btn:hover,
#page-garantie.green .page-theming .pink.line-bg-right-btn:hover,
.page-theming #page-garantie.green .pink.pdf-dl-btn:hover,
#page-garantie.green .page-theming .pink.pdf-dl-btn:hover,
.page-theming .pink.btn-light-blue:hover,
.page-theming #page-garantie.light-blue .pink.line-bg-right-btn:hover,
#page-garantie.light-blue .page-theming .pink.line-bg-right-btn:hover,
.page-theming #page-garantie.light-blue .pink.pdf-dl-btn:hover,
#page-garantie.light-blue .page-theming .pink.pdf-dl-btn:hover,
.page-theming .pink.btn-pink:hover,
.page-theming #page-garantie.pink .pink.line-bg-right-btn:hover,
#page-garantie.pink .page-theming .pink.line-bg-right-btn:hover,
.page-theming #page-garantie.pink .pink.pdf-dl-btn:hover,
#page-garantie.pink .page-theming .pink.pdf-dl-btn:hover,
.page-theming .pink.btn-purple:hover,
.page-theming #page-garantie.purple .pink.line-bg-right-btn:hover,
#page-garantie.purple .page-theming .pink.line-bg-right-btn:hover,
.page-theming #page-garantie.purple .pink.pdf-dl-btn:hover,
#page-garantie.purple .page-theming .pink.pdf-dl-btn:hover,
.page-theming .pink.btn-light-grey:hover,
.page-theming .pink.btn-grey:hover,
.page-theming .pink.btn-light-green:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.video-btn:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .pink.project-btn:hover,
.layout-fondation .btn.pink:focus,
.layout-fondation .pink.btn-orange:focus,
.layout-fondation #page-garantie.orange .pink.line-bg-right-btn:focus,
#page-garantie.orange .layout-fondation .pink.line-bg-right-btn:focus,
.layout-fondation #page-garantie.orange .pink.pdf-dl-btn:focus,
#page-garantie.orange .layout-fondation .pink.pdf-dl-btn:focus,
.layout-fondation .pink.btn-green:focus,
.layout-fondation #page-garantie.green .pink.line-bg-right-btn:focus,
#page-garantie.green .layout-fondation .pink.line-bg-right-btn:focus,
.layout-fondation #page-garantie.green .pink.pdf-dl-btn:focus,
#page-garantie.green .layout-fondation .pink.pdf-dl-btn:focus,
.layout-fondation .pink.btn-light-blue:focus,
.layout-fondation #page-garantie.light-blue .pink.line-bg-right-btn:focus,
#page-garantie.light-blue .layout-fondation .pink.line-bg-right-btn:focus,
.layout-fondation #page-garantie.light-blue .pink.pdf-dl-btn:focus,
#page-garantie.light-blue .layout-fondation .pink.pdf-dl-btn:focus,
.layout-fondation .pink.btn-pink:focus,
.layout-fondation #page-garantie.pink .pink.line-bg-right-btn:focus,
#page-garantie.pink .layout-fondation .pink.line-bg-right-btn:focus,
.layout-fondation #page-garantie.pink .pink.pdf-dl-btn:focus,
#page-garantie.pink .layout-fondation .pink.pdf-dl-btn:focus,
.layout-fondation .pink.btn-purple:focus,
.layout-fondation #page-garantie.purple .pink.line-bg-right-btn:focus,
#page-garantie.purple .layout-fondation .pink.line-bg-right-btn:focus,
.layout-fondation #page-garantie.purple .pink.pdf-dl-btn:focus,
#page-garantie.purple .layout-fondation .pink.pdf-dl-btn:focus,
.layout-fondation .pink.btn-light-grey:focus,
.layout-fondation .pink.btn-grey:focus,
.layout-fondation .pink.btn-light-green:focus,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.video-btn:focus,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.project-btn:focus,
.layout-fondation .btn.pink:hover,
.layout-fondation .pink.btn-orange:hover,
.layout-fondation #page-garantie.orange .pink.line-bg-right-btn:hover,
#page-garantie.orange .layout-fondation .pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.orange .pink.pdf-dl-btn:hover,
#page-garantie.orange .layout-fondation .pink.pdf-dl-btn:hover,
.layout-fondation .pink.btn-green:hover,
.layout-fondation #page-garantie.green .pink.line-bg-right-btn:hover,
#page-garantie.green .layout-fondation .pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.green .pink.pdf-dl-btn:hover,
#page-garantie.green .layout-fondation .pink.pdf-dl-btn:hover,
.layout-fondation .pink.btn-light-blue:hover,
.layout-fondation #page-garantie.light-blue .pink.line-bg-right-btn:hover,
#page-garantie.light-blue .layout-fondation .pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.light-blue .pink.pdf-dl-btn:hover,
#page-garantie.light-blue .layout-fondation .pink.pdf-dl-btn:hover,
.layout-fondation .pink.btn-pink:hover,
.layout-fondation #page-garantie.pink .pink.line-bg-right-btn:hover,
#page-garantie.pink .layout-fondation .pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.pink .pink.pdf-dl-btn:hover,
#page-garantie.pink .layout-fondation .pink.pdf-dl-btn:hover,
.layout-fondation .pink.btn-purple:hover,
.layout-fondation #page-garantie.purple .pink.line-bg-right-btn:hover,
#page-garantie.purple .layout-fondation .pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.purple .pink.pdf-dl-btn:hover,
#page-garantie.purple .layout-fondation .pink.pdf-dl-btn:hover,
.layout-fondation .pink.btn-light-grey:hover,
.layout-fondation .pink.btn-grey:hover,
.layout-fondation .pink.btn-light-green:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.video-btn:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .pink.project-btn:hover {
  background-color: white;
  color: #EA670A;
}
/* line 449, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.orange, [class*="node-type-fondation"] .orange.btn-orange, [class*="node-type-fondation"] #page-garantie.orange .orange.line-bg-right-btn, #page-garantie.orange [class*="node-type-fondation"] .orange.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.orange .orange.pdf-dl-btn,
#page-garantie.orange [class*="node-type-fondation"] .orange.pdf-dl-btn, [class*="node-type-fondation"] .orange.btn-green, [class*="node-type-fondation"] #page-garantie.green .orange.line-bg-right-btn, #page-garantie.green [class*="node-type-fondation"] .orange.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.green .orange.pdf-dl-btn,
#page-garantie.green [class*="node-type-fondation"] .orange.pdf-dl-btn, [class*="node-type-fondation"] .orange.btn-light-blue, [class*="node-type-fondation"] #page-garantie.light-blue .orange.line-bg-right-btn, #page-garantie.light-blue [class*="node-type-fondation"] .orange.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.light-blue .orange.pdf-dl-btn,
#page-garantie.light-blue [class*="node-type-fondation"] .orange.pdf-dl-btn, [class*="node-type-fondation"] .orange.btn-pink, [class*="node-type-fondation"] #page-garantie.pink .orange.line-bg-right-btn, #page-garantie.pink [class*="node-type-fondation"] .orange.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.pink .orange.pdf-dl-btn,
#page-garantie.pink [class*="node-type-fondation"] .orange.pdf-dl-btn, [class*="node-type-fondation"] .orange.btn-purple, [class*="node-type-fondation"] #page-garantie.purple .orange.line-bg-right-btn, #page-garantie.purple [class*="node-type-fondation"] .orange.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.purple .orange.pdf-dl-btn,
#page-garantie.purple [class*="node-type-fondation"] .orange.pdf-dl-btn, [class*="node-type-fondation"] .orange.btn-light-grey, [class*="node-type-fondation"] .orange.btn-grey, [class*="node-type-fondation"] .orange.btn-light-green, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.video-btn,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.project-btn,
.page-theming .btn.orange,
.page-theming .orange.btn-orange,
.page-theming #page-garantie.orange .orange.line-bg-right-btn,
#page-garantie.orange .page-theming .orange.line-bg-right-btn,
.page-theming #page-garantie.orange .orange.pdf-dl-btn,
#page-garantie.orange .page-theming .orange.pdf-dl-btn,
.page-theming .orange.btn-green,
.page-theming #page-garantie.green .orange.line-bg-right-btn,
#page-garantie.green .page-theming .orange.line-bg-right-btn,
.page-theming #page-garantie.green .orange.pdf-dl-btn,
#page-garantie.green .page-theming .orange.pdf-dl-btn,
.page-theming .orange.btn-light-blue,
.page-theming #page-garantie.light-blue .orange.line-bg-right-btn,
#page-garantie.light-blue .page-theming .orange.line-bg-right-btn,
.page-theming #page-garantie.light-blue .orange.pdf-dl-btn,
#page-garantie.light-blue .page-theming .orange.pdf-dl-btn,
.page-theming .orange.btn-pink,
.page-theming #page-garantie.pink .orange.line-bg-right-btn,
#page-garantie.pink .page-theming .orange.line-bg-right-btn,
.page-theming #page-garantie.pink .orange.pdf-dl-btn,
#page-garantie.pink .page-theming .orange.pdf-dl-btn,
.page-theming .orange.btn-purple,
.page-theming #page-garantie.purple .orange.line-bg-right-btn,
#page-garantie.purple .page-theming .orange.line-bg-right-btn,
.page-theming #page-garantie.purple .orange.pdf-dl-btn,
#page-garantie.purple .page-theming .orange.pdf-dl-btn,
.page-theming .orange.btn-light-grey,
.page-theming .orange.btn-grey,
.page-theming .orange.btn-light-green,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.video-btn,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.project-btn,
.layout-fondation .btn.orange,
.layout-fondation .orange.btn-orange,
.layout-fondation #page-garantie.orange .orange.line-bg-right-btn,
#page-garantie.orange .layout-fondation .orange.line-bg-right-btn,
.layout-fondation #page-garantie.orange .orange.pdf-dl-btn,
#page-garantie.orange .layout-fondation .orange.pdf-dl-btn,
.layout-fondation .orange.btn-green,
.layout-fondation #page-garantie.green .orange.line-bg-right-btn,
#page-garantie.green .layout-fondation .orange.line-bg-right-btn,
.layout-fondation #page-garantie.green .orange.pdf-dl-btn,
#page-garantie.green .layout-fondation .orange.pdf-dl-btn,
.layout-fondation .orange.btn-light-blue,
.layout-fondation #page-garantie.light-blue .orange.line-bg-right-btn,
#page-garantie.light-blue .layout-fondation .orange.line-bg-right-btn,
.layout-fondation #page-garantie.light-blue .orange.pdf-dl-btn,
#page-garantie.light-blue .layout-fondation .orange.pdf-dl-btn,
.layout-fondation .orange.btn-pink,
.layout-fondation #page-garantie.pink .orange.line-bg-right-btn,
#page-garantie.pink .layout-fondation .orange.line-bg-right-btn,
.layout-fondation #page-garantie.pink .orange.pdf-dl-btn,
#page-garantie.pink .layout-fondation .orange.pdf-dl-btn,
.layout-fondation .orange.btn-purple,
.layout-fondation #page-garantie.purple .orange.line-bg-right-btn,
#page-garantie.purple .layout-fondation .orange.line-bg-right-btn,
.layout-fondation #page-garantie.purple .orange.pdf-dl-btn,
#page-garantie.purple .layout-fondation .orange.pdf-dl-btn,
.layout-fondation .orange.btn-light-grey,
.layout-fondation .orange.btn-grey,
.layout-fondation .orange.btn-light-green,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.video-btn,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.project-btn {
  background-color: #163375;
  border: 1px solid #163375;
  color: white;
}
/* line 453, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.orange:focus, [class*="node-type-fondation"] .orange.btn-orange:focus, [class*="node-type-fondation"] #page-garantie.orange .orange.line-bg-right-btn:focus, #page-garantie.orange [class*="node-type-fondation"] .orange.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.orange .orange.pdf-dl-btn:focus,
#page-garantie.orange [class*="node-type-fondation"] .orange.pdf-dl-btn:focus, [class*="node-type-fondation"] .orange.btn-green:focus, [class*="node-type-fondation"] #page-garantie.green .orange.line-bg-right-btn:focus, #page-garantie.green [class*="node-type-fondation"] .orange.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.green .orange.pdf-dl-btn:focus,
#page-garantie.green [class*="node-type-fondation"] .orange.pdf-dl-btn:focus, [class*="node-type-fondation"] .orange.btn-light-blue:focus, [class*="node-type-fondation"] #page-garantie.light-blue .orange.line-bg-right-btn:focus, #page-garantie.light-blue [class*="node-type-fondation"] .orange.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.light-blue .orange.pdf-dl-btn:focus,
#page-garantie.light-blue [class*="node-type-fondation"] .orange.pdf-dl-btn:focus, [class*="node-type-fondation"] .orange.btn-pink:focus, [class*="node-type-fondation"] #page-garantie.pink .orange.line-bg-right-btn:focus, #page-garantie.pink [class*="node-type-fondation"] .orange.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.pink .orange.pdf-dl-btn:focus,
#page-garantie.pink [class*="node-type-fondation"] .orange.pdf-dl-btn:focus, [class*="node-type-fondation"] .orange.btn-purple:focus, [class*="node-type-fondation"] #page-garantie.purple .orange.line-bg-right-btn:focus, #page-garantie.purple [class*="node-type-fondation"] .orange.line-bg-right-btn:focus,
[class*="node-type-fondation"] #page-garantie.purple .orange.pdf-dl-btn:focus,
#page-garantie.purple [class*="node-type-fondation"] .orange.pdf-dl-btn:focus, [class*="node-type-fondation"] .orange.btn-light-grey:focus, [class*="node-type-fondation"] .orange.btn-grey:focus, [class*="node-type-fondation"] .orange.btn-light-green:focus, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:focus, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.video-btn:focus,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.project-btn:focus, [class*="node-type-fondation"] .btn.orange:hover, [class*="node-type-fondation"] .orange.btn-orange:hover, [class*="node-type-fondation"] #page-garantie.orange .orange.line-bg-right-btn:hover, #page-garantie.orange [class*="node-type-fondation"] .orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.orange .orange.pdf-dl-btn:hover,
#page-garantie.orange [class*="node-type-fondation"] .orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .orange.btn-green:hover, [class*="node-type-fondation"] #page-garantie.green .orange.line-bg-right-btn:hover, #page-garantie.green [class*="node-type-fondation"] .orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.green .orange.pdf-dl-btn:hover,
#page-garantie.green [class*="node-type-fondation"] .orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .orange.btn-light-blue:hover, [class*="node-type-fondation"] #page-garantie.light-blue .orange.line-bg-right-btn:hover, #page-garantie.light-blue [class*="node-type-fondation"] .orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.light-blue .orange.pdf-dl-btn:hover,
#page-garantie.light-blue [class*="node-type-fondation"] .orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .orange.btn-pink:hover, [class*="node-type-fondation"] #page-garantie.pink .orange.line-bg-right-btn:hover, #page-garantie.pink [class*="node-type-fondation"] .orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.pink .orange.pdf-dl-btn:hover,
#page-garantie.pink [class*="node-type-fondation"] .orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .orange.btn-purple:hover, [class*="node-type-fondation"] #page-garantie.purple .orange.line-bg-right-btn:hover, #page-garantie.purple [class*="node-type-fondation"] .orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.purple .orange.pdf-dl-btn:hover,
#page-garantie.purple [class*="node-type-fondation"] .orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .orange.btn-light-grey:hover, [class*="node-type-fondation"] .orange.btn-grey:hover, [class*="node-type-fondation"] .orange.btn-light-green:hover, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.video-btn:hover,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .orange.project-btn:hover,
.page-theming .btn.orange:focus,
.page-theming .orange.btn-orange:focus,
.page-theming #page-garantie.orange .orange.line-bg-right-btn:focus,
#page-garantie.orange .page-theming .orange.line-bg-right-btn:focus,
.page-theming #page-garantie.orange .orange.pdf-dl-btn:focus,
#page-garantie.orange .page-theming .orange.pdf-dl-btn:focus,
.page-theming .orange.btn-green:focus,
.page-theming #page-garantie.green .orange.line-bg-right-btn:focus,
#page-garantie.green .page-theming .orange.line-bg-right-btn:focus,
.page-theming #page-garantie.green .orange.pdf-dl-btn:focus,
#page-garantie.green .page-theming .orange.pdf-dl-btn:focus,
.page-theming .orange.btn-light-blue:focus,
.page-theming #page-garantie.light-blue .orange.line-bg-right-btn:focus,
#page-garantie.light-blue .page-theming .orange.line-bg-right-btn:focus,
.page-theming #page-garantie.light-blue .orange.pdf-dl-btn:focus,
#page-garantie.light-blue .page-theming .orange.pdf-dl-btn:focus,
.page-theming .orange.btn-pink:focus,
.page-theming #page-garantie.pink .orange.line-bg-right-btn:focus,
#page-garantie.pink .page-theming .orange.line-bg-right-btn:focus,
.page-theming #page-garantie.pink .orange.pdf-dl-btn:focus,
#page-garantie.pink .page-theming .orange.pdf-dl-btn:focus,
.page-theming .orange.btn-purple:focus,
.page-theming #page-garantie.purple .orange.line-bg-right-btn:focus,
#page-garantie.purple .page-theming .orange.line-bg-right-btn:focus,
.page-theming #page-garantie.purple .orange.pdf-dl-btn:focus,
#page-garantie.purple .page-theming .orange.pdf-dl-btn:focus,
.page-theming .orange.btn-light-grey:focus,
.page-theming .orange.btn-grey:focus,
.page-theming .orange.btn-light-green:focus,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.video-btn:focus,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.project-btn:focus,
.page-theming .btn.orange:hover,
.page-theming .orange.btn-orange:hover,
.page-theming #page-garantie.orange .orange.line-bg-right-btn:hover,
#page-garantie.orange .page-theming .orange.line-bg-right-btn:hover,
.page-theming #page-garantie.orange .orange.pdf-dl-btn:hover,
#page-garantie.orange .page-theming .orange.pdf-dl-btn:hover,
.page-theming .orange.btn-green:hover,
.page-theming #page-garantie.green .orange.line-bg-right-btn:hover,
#page-garantie.green .page-theming .orange.line-bg-right-btn:hover,
.page-theming #page-garantie.green .orange.pdf-dl-btn:hover,
#page-garantie.green .page-theming .orange.pdf-dl-btn:hover,
.page-theming .orange.btn-light-blue:hover,
.page-theming #page-garantie.light-blue .orange.line-bg-right-btn:hover,
#page-garantie.light-blue .page-theming .orange.line-bg-right-btn:hover,
.page-theming #page-garantie.light-blue .orange.pdf-dl-btn:hover,
#page-garantie.light-blue .page-theming .orange.pdf-dl-btn:hover,
.page-theming .orange.btn-pink:hover,
.page-theming #page-garantie.pink .orange.line-bg-right-btn:hover,
#page-garantie.pink .page-theming .orange.line-bg-right-btn:hover,
.page-theming #page-garantie.pink .orange.pdf-dl-btn:hover,
#page-garantie.pink .page-theming .orange.pdf-dl-btn:hover,
.page-theming .orange.btn-purple:hover,
.page-theming #page-garantie.purple .orange.line-bg-right-btn:hover,
#page-garantie.purple .page-theming .orange.line-bg-right-btn:hover,
.page-theming #page-garantie.purple .orange.pdf-dl-btn:hover,
#page-garantie.purple .page-theming .orange.pdf-dl-btn:hover,
.page-theming .orange.btn-light-grey:hover,
.page-theming .orange.btn-grey:hover,
.page-theming .orange.btn-light-green:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.video-btn:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .orange.project-btn:hover,
.layout-fondation .btn.orange:focus,
.layout-fondation .orange.btn-orange:focus,
.layout-fondation #page-garantie.orange .orange.line-bg-right-btn:focus,
#page-garantie.orange .layout-fondation .orange.line-bg-right-btn:focus,
.layout-fondation #page-garantie.orange .orange.pdf-dl-btn:focus,
#page-garantie.orange .layout-fondation .orange.pdf-dl-btn:focus,
.layout-fondation .orange.btn-green:focus,
.layout-fondation #page-garantie.green .orange.line-bg-right-btn:focus,
#page-garantie.green .layout-fondation .orange.line-bg-right-btn:focus,
.layout-fondation #page-garantie.green .orange.pdf-dl-btn:focus,
#page-garantie.green .layout-fondation .orange.pdf-dl-btn:focus,
.layout-fondation .orange.btn-light-blue:focus,
.layout-fondation #page-garantie.light-blue .orange.line-bg-right-btn:focus,
#page-garantie.light-blue .layout-fondation .orange.line-bg-right-btn:focus,
.layout-fondation #page-garantie.light-blue .orange.pdf-dl-btn:focus,
#page-garantie.light-blue .layout-fondation .orange.pdf-dl-btn:focus,
.layout-fondation .orange.btn-pink:focus,
.layout-fondation #page-garantie.pink .orange.line-bg-right-btn:focus,
#page-garantie.pink .layout-fondation .orange.line-bg-right-btn:focus,
.layout-fondation #page-garantie.pink .orange.pdf-dl-btn:focus,
#page-garantie.pink .layout-fondation .orange.pdf-dl-btn:focus,
.layout-fondation .orange.btn-purple:focus,
.layout-fondation #page-garantie.purple .orange.line-bg-right-btn:focus,
#page-garantie.purple .layout-fondation .orange.line-bg-right-btn:focus,
.layout-fondation #page-garantie.purple .orange.pdf-dl-btn:focus,
#page-garantie.purple .layout-fondation .orange.pdf-dl-btn:focus,
.layout-fondation .orange.btn-light-grey:focus,
.layout-fondation .orange.btn-grey:focus,
.layout-fondation .orange.btn-light-green:focus,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.video-btn:focus,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:focus,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.project-btn:focus,
.layout-fondation .btn.orange:hover,
.layout-fondation .orange.btn-orange:hover,
.layout-fondation #page-garantie.orange .orange.line-bg-right-btn:hover,
#page-garantie.orange .layout-fondation .orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.orange .orange.pdf-dl-btn:hover,
#page-garantie.orange .layout-fondation .orange.pdf-dl-btn:hover,
.layout-fondation .orange.btn-green:hover,
.layout-fondation #page-garantie.green .orange.line-bg-right-btn:hover,
#page-garantie.green .layout-fondation .orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.green .orange.pdf-dl-btn:hover,
#page-garantie.green .layout-fondation .orange.pdf-dl-btn:hover,
.layout-fondation .orange.btn-light-blue:hover,
.layout-fondation #page-garantie.light-blue .orange.line-bg-right-btn:hover,
#page-garantie.light-blue .layout-fondation .orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.light-blue .orange.pdf-dl-btn:hover,
#page-garantie.light-blue .layout-fondation .orange.pdf-dl-btn:hover,
.layout-fondation .orange.btn-pink:hover,
.layout-fondation #page-garantie.pink .orange.line-bg-right-btn:hover,
#page-garantie.pink .layout-fondation .orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.pink .orange.pdf-dl-btn:hover,
#page-garantie.pink .layout-fondation .orange.pdf-dl-btn:hover,
.layout-fondation .orange.btn-purple:hover,
.layout-fondation #page-garantie.purple .orange.line-bg-right-btn:hover,
#page-garantie.purple .layout-fondation .orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.purple .orange.pdf-dl-btn:hover,
#page-garantie.purple .layout-fondation .orange.pdf-dl-btn:hover,
.layout-fondation .orange.btn-light-grey:hover,
.layout-fondation .orange.btn-grey:hover,
.layout-fondation .orange.btn-light-green:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.video-btn:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .orange.project-btn:hover {
  background-color: white;
  color: #163375;
}
/* line 459, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.btn-participate, [class*="node-type-fondation"] .btn-participate.btn-orange, [class*="node-type-fondation"] #page-garantie.orange .btn-participate.line-bg-right-btn, #page-garantie.orange [class*="node-type-fondation"] .btn-participate.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.orange .btn-participate.pdf-dl-btn,
#page-garantie.orange [class*="node-type-fondation"] .btn-participate.pdf-dl-btn, [class*="node-type-fondation"] .btn-participate.btn-green, [class*="node-type-fondation"] #page-garantie.green .btn-participate.line-bg-right-btn, #page-garantie.green [class*="node-type-fondation"] .btn-participate.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.green .btn-participate.pdf-dl-btn,
#page-garantie.green [class*="node-type-fondation"] .btn-participate.pdf-dl-btn, [class*="node-type-fondation"] .btn-participate.btn-light-blue, [class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.line-bg-right-btn, #page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.pdf-dl-btn,
#page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.pdf-dl-btn, [class*="node-type-fondation"] .btn-participate.btn-pink, [class*="node-type-fondation"] #page-garantie.pink .btn-participate.line-bg-right-btn, #page-garantie.pink [class*="node-type-fondation"] .btn-participate.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.pink .btn-participate.pdf-dl-btn,
#page-garantie.pink [class*="node-type-fondation"] .btn-participate.pdf-dl-btn, [class*="node-type-fondation"] .btn-participate.btn-purple, [class*="node-type-fondation"] #page-garantie.purple .btn-participate.line-bg-right-btn, #page-garantie.purple [class*="node-type-fondation"] .btn-participate.line-bg-right-btn,
[class*="node-type-fondation"] #page-garantie.purple .btn-participate.pdf-dl-btn,
#page-garantie.purple [class*="node-type-fondation"] .btn-participate.pdf-dl-btn, [class*="node-type-fondation"] .btn-participate.btn-light-grey, [class*="node-type-fondation"] .btn-participate.btn-grey, [class*="node-type-fondation"] .btn-participate.btn-light-green, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.video-btn,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.project-btn,
.page-theming .btn.btn-participate,
.page-theming .btn-participate.btn-orange,
.page-theming #page-garantie.orange .btn-participate.line-bg-right-btn,
#page-garantie.orange .page-theming .btn-participate.line-bg-right-btn,
.page-theming #page-garantie.orange .btn-participate.pdf-dl-btn,
#page-garantie.orange .page-theming .btn-participate.pdf-dl-btn,
.page-theming .btn-participate.btn-green,
.page-theming #page-garantie.green .btn-participate.line-bg-right-btn,
#page-garantie.green .page-theming .btn-participate.line-bg-right-btn,
.page-theming #page-garantie.green .btn-participate.pdf-dl-btn,
#page-garantie.green .page-theming .btn-participate.pdf-dl-btn,
.page-theming .btn-participate.btn-light-blue,
.page-theming #page-garantie.light-blue .btn-participate.line-bg-right-btn,
#page-garantie.light-blue .page-theming .btn-participate.line-bg-right-btn,
.page-theming #page-garantie.light-blue .btn-participate.pdf-dl-btn,
#page-garantie.light-blue .page-theming .btn-participate.pdf-dl-btn,
.page-theming .btn-participate.btn-pink,
.page-theming #page-garantie.pink .btn-participate.line-bg-right-btn,
#page-garantie.pink .page-theming .btn-participate.line-bg-right-btn,
.page-theming #page-garantie.pink .btn-participate.pdf-dl-btn,
#page-garantie.pink .page-theming .btn-participate.pdf-dl-btn,
.page-theming .btn-participate.btn-purple,
.page-theming #page-garantie.purple .btn-participate.line-bg-right-btn,
#page-garantie.purple .page-theming .btn-participate.line-bg-right-btn,
.page-theming #page-garantie.purple .btn-participate.pdf-dl-btn,
#page-garantie.purple .page-theming .btn-participate.pdf-dl-btn,
.page-theming .btn-participate.btn-light-grey,
.page-theming .btn-participate.btn-grey,
.page-theming .btn-participate.btn-light-green,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.video-btn,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.project-btn,
.layout-fondation .btn.btn-participate,
.layout-fondation .btn-participate.btn-orange,
.layout-fondation #page-garantie.orange .btn-participate.line-bg-right-btn,
#page-garantie.orange .layout-fondation .btn-participate.line-bg-right-btn,
.layout-fondation #page-garantie.orange .btn-participate.pdf-dl-btn,
#page-garantie.orange .layout-fondation .btn-participate.pdf-dl-btn,
.layout-fondation .btn-participate.btn-green,
.layout-fondation #page-garantie.green .btn-participate.line-bg-right-btn,
#page-garantie.green .layout-fondation .btn-participate.line-bg-right-btn,
.layout-fondation #page-garantie.green .btn-participate.pdf-dl-btn,
#page-garantie.green .layout-fondation .btn-participate.pdf-dl-btn,
.layout-fondation .btn-participate.btn-light-blue,
.layout-fondation #page-garantie.light-blue .btn-participate.line-bg-right-btn,
#page-garantie.light-blue .layout-fondation .btn-participate.line-bg-right-btn,
.layout-fondation #page-garantie.light-blue .btn-participate.pdf-dl-btn,
#page-garantie.light-blue .layout-fondation .btn-participate.pdf-dl-btn,
.layout-fondation .btn-participate.btn-pink,
.layout-fondation #page-garantie.pink .btn-participate.line-bg-right-btn,
#page-garantie.pink .layout-fondation .btn-participate.line-bg-right-btn,
.layout-fondation #page-garantie.pink .btn-participate.pdf-dl-btn,
#page-garantie.pink .layout-fondation .btn-participate.pdf-dl-btn,
.layout-fondation .btn-participate.btn-purple,
.layout-fondation #page-garantie.purple .btn-participate.line-bg-right-btn,
#page-garantie.purple .layout-fondation .btn-participate.line-bg-right-btn,
.layout-fondation #page-garantie.purple .btn-participate.pdf-dl-btn,
#page-garantie.purple .layout-fondation .btn-participate.pdf-dl-btn,
.layout-fondation .btn-participate.btn-light-grey,
.layout-fondation .btn-participate.btn-grey,
.layout-fondation .btn-participate.btn-light-green,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.video-btn,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.project-btn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
/* line 465, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.btn-participate.pink:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-orange:hover, [class*="node-type-fondation"] #page-garantie.orange .btn-participate.pink.line-bg-right-btn:hover, #page-garantie.orange [class*="node-type-fondation"] .btn-participate.pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.orange .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.orange [class*="node-type-fondation"] .btn-participate.pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-green:hover, [class*="node-type-fondation"] #page-garantie.green .btn-participate.pink.line-bg-right-btn:hover, #page-garantie.green [class*="node-type-fondation"] .btn-participate.pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.green .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.green [class*="node-type-fondation"] .btn-participate.pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-light-blue:hover, [class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.pink.line-bg-right-btn:hover, #page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-pink:hover, [class*="node-type-fondation"] #page-garantie.pink .btn-participate.pink.line-bg-right-btn:hover, #page-garantie.pink [class*="node-type-fondation"] .btn-participate.pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.pink .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.pink [class*="node-type-fondation"] .btn-participate.pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-purple:hover, [class*="node-type-fondation"] #page-garantie.purple .btn-participate.pink.line-bg-right-btn:hover, #page-garantie.purple [class*="node-type-fondation"] .btn-participate.pink.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.purple .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.purple [class*="node-type-fondation"] .btn-participate.pink.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-light-grey:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-grey:hover, [class*="node-type-fondation"] .btn-participate.pink.btn-light-green:hover, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.pink.video-btn:hover,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.pink.project-btn:hover,
.page-theming .btn.btn-participate.pink:hover,
.page-theming .btn-participate.pink.btn-orange:hover,
.page-theming #page-garantie.orange .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.orange .page-theming .btn-participate.pink.line-bg-right-btn:hover,
.page-theming #page-garantie.orange .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.orange .page-theming .btn-participate.pink.pdf-dl-btn:hover,
.page-theming .btn-participate.pink.btn-green:hover,
.page-theming #page-garantie.green .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.green .page-theming .btn-participate.pink.line-bg-right-btn:hover,
.page-theming #page-garantie.green .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.green .page-theming .btn-participate.pink.pdf-dl-btn:hover,
.page-theming .btn-participate.pink.btn-light-blue:hover,
.page-theming #page-garantie.light-blue .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.light-blue .page-theming .btn-participate.pink.line-bg-right-btn:hover,
.page-theming #page-garantie.light-blue .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.light-blue .page-theming .btn-participate.pink.pdf-dl-btn:hover,
.page-theming .btn-participate.pink.btn-pink:hover,
.page-theming #page-garantie.pink .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.pink .page-theming .btn-participate.pink.line-bg-right-btn:hover,
.page-theming #page-garantie.pink .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.pink .page-theming .btn-participate.pink.pdf-dl-btn:hover,
.page-theming .btn-participate.pink.btn-purple:hover,
.page-theming #page-garantie.purple .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.purple .page-theming .btn-participate.pink.line-bg-right-btn:hover,
.page-theming #page-garantie.purple .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.purple .page-theming .btn-participate.pink.pdf-dl-btn:hover,
.page-theming .btn-participate.pink.btn-light-grey:hover,
.page-theming .btn-participate.pink.btn-grey:hover,
.page-theming .btn-participate.pink.btn-light-green:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.pink.video-btn:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.pink.project-btn:hover,
.layout-fondation .btn.btn-participate.pink:hover,
.layout-fondation .btn-participate.pink.btn-orange:hover,
.layout-fondation #page-garantie.orange .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.orange .layout-fondation .btn-participate.pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.orange .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.orange .layout-fondation .btn-participate.pink.pdf-dl-btn:hover,
.layout-fondation .btn-participate.pink.btn-green:hover,
.layout-fondation #page-garantie.green .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.green .layout-fondation .btn-participate.pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.green .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.green .layout-fondation .btn-participate.pink.pdf-dl-btn:hover,
.layout-fondation .btn-participate.pink.btn-light-blue:hover,
.layout-fondation #page-garantie.light-blue .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.light-blue .layout-fondation .btn-participate.pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.light-blue .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.light-blue .layout-fondation .btn-participate.pink.pdf-dl-btn:hover,
.layout-fondation .btn-participate.pink.btn-pink:hover,
.layout-fondation #page-garantie.pink .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.pink .layout-fondation .btn-participate.pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.pink .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.pink .layout-fondation .btn-participate.pink.pdf-dl-btn:hover,
.layout-fondation .btn-participate.pink.btn-purple:hover,
.layout-fondation #page-garantie.purple .btn-participate.pink.line-bg-right-btn:hover,
#page-garantie.purple .layout-fondation .btn-participate.pink.line-bg-right-btn:hover,
.layout-fondation #page-garantie.purple .btn-participate.pink.pdf-dl-btn:hover,
#page-garantie.purple .layout-fondation .btn-participate.pink.pdf-dl-btn:hover,
.layout-fondation .btn-participate.pink.btn-light-grey:hover,
.layout-fondation .btn-participate.pink.btn-grey:hover,
.layout-fondation .btn-participate.pink.btn-light-green:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.pink.video-btn:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.pink.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.pink.project-btn:hover {
  background-color: #EA670A;
  color: white;
}
/* line 471, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.btn-participate.orange:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-orange:hover, [class*="node-type-fondation"] #page-garantie.orange .btn-participate.orange.line-bg-right-btn:hover, #page-garantie.orange [class*="node-type-fondation"] .btn-participate.orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.orange .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.orange [class*="node-type-fondation"] .btn-participate.orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-green:hover, [class*="node-type-fondation"] #page-garantie.green .btn-participate.orange.line-bg-right-btn:hover, #page-garantie.green [class*="node-type-fondation"] .btn-participate.orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.green .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.green [class*="node-type-fondation"] .btn-participate.orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-light-blue:hover, [class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.orange.line-bg-right-btn:hover, #page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-pink:hover, [class*="node-type-fondation"] #page-garantie.pink .btn-participate.orange.line-bg-right-btn:hover, #page-garantie.pink [class*="node-type-fondation"] .btn-participate.orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.pink .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.pink [class*="node-type-fondation"] .btn-participate.orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-purple:hover, [class*="node-type-fondation"] #page-garantie.purple .btn-participate.orange.line-bg-right-btn:hover, #page-garantie.purple [class*="node-type-fondation"] .btn-participate.orange.line-bg-right-btn:hover,
[class*="node-type-fondation"] #page-garantie.purple .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.purple [class*="node-type-fondation"] .btn-participate.orange.pdf-dl-btn:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-light-grey:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-grey:hover, [class*="node-type-fondation"] .btn-participate.orange.btn-light-green:hover, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.video-btn:hover, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.orange.video-btn:hover,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.orange.project-btn:hover,
.page-theming .btn.btn-participate.orange:hover,
.page-theming .btn-participate.orange.btn-orange:hover,
.page-theming #page-garantie.orange .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.orange .page-theming .btn-participate.orange.line-bg-right-btn:hover,
.page-theming #page-garantie.orange .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.orange .page-theming .btn-participate.orange.pdf-dl-btn:hover,
.page-theming .btn-participate.orange.btn-green:hover,
.page-theming #page-garantie.green .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.green .page-theming .btn-participate.orange.line-bg-right-btn:hover,
.page-theming #page-garantie.green .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.green .page-theming .btn-participate.orange.pdf-dl-btn:hover,
.page-theming .btn-participate.orange.btn-light-blue:hover,
.page-theming #page-garantie.light-blue .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.light-blue .page-theming .btn-participate.orange.line-bg-right-btn:hover,
.page-theming #page-garantie.light-blue .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.light-blue .page-theming .btn-participate.orange.pdf-dl-btn:hover,
.page-theming .btn-participate.orange.btn-pink:hover,
.page-theming #page-garantie.pink .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.pink .page-theming .btn-participate.orange.line-bg-right-btn:hover,
.page-theming #page-garantie.pink .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.pink .page-theming .btn-participate.orange.pdf-dl-btn:hover,
.page-theming .btn-participate.orange.btn-purple:hover,
.page-theming #page-garantie.purple .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.purple .page-theming .btn-participate.orange.line-bg-right-btn:hover,
.page-theming #page-garantie.purple .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.purple .page-theming .btn-participate.orange.pdf-dl-btn:hover,
.page-theming .btn-participate.orange.btn-light-grey:hover,
.page-theming .btn-participate.orange.btn-grey:hover,
.page-theming .btn-participate.orange.btn-light-green:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.orange.video-btn:hover,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.orange.project-btn:hover,
.layout-fondation .btn.btn-participate.orange:hover,
.layout-fondation .btn-participate.orange.btn-orange:hover,
.layout-fondation #page-garantie.orange .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.orange .layout-fondation .btn-participate.orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.orange .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.orange .layout-fondation .btn-participate.orange.pdf-dl-btn:hover,
.layout-fondation .btn-participate.orange.btn-green:hover,
.layout-fondation #page-garantie.green .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.green .layout-fondation .btn-participate.orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.green .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.green .layout-fondation .btn-participate.orange.pdf-dl-btn:hover,
.layout-fondation .btn-participate.orange.btn-light-blue:hover,
.layout-fondation #page-garantie.light-blue .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.light-blue .layout-fondation .btn-participate.orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.light-blue .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.light-blue .layout-fondation .btn-participate.orange.pdf-dl-btn:hover,
.layout-fondation .btn-participate.orange.btn-pink:hover,
.layout-fondation #page-garantie.pink .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.pink .layout-fondation .btn-participate.orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.pink .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.pink .layout-fondation .btn-participate.orange.pdf-dl-btn:hover,
.layout-fondation .btn-participate.orange.btn-purple:hover,
.layout-fondation #page-garantie.purple .btn-participate.orange.line-bg-right-btn:hover,
#page-garantie.purple .layout-fondation .btn-participate.orange.line-bg-right-btn:hover,
.layout-fondation #page-garantie.purple .btn-participate.orange.pdf-dl-btn:hover,
#page-garantie.purple .layout-fondation .btn-participate.orange.pdf-dl-btn:hover,
.layout-fondation .btn-participate.orange.btn-light-grey:hover,
.layout-fondation .btn-participate.orange.btn-grey:hover,
.layout-fondation .btn-participate.orange.btn-light-green:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.video-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.orange.video-btn:hover,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.orange.project-btn:hover,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.orange.project-btn:hover {
  background-color: #163375;
  color: white;
}
/* line 476, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .btn.btn-participate .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-orange .btn-participate-image, [class*="node-type-fondation"] #page-garantie.orange .btn-participate.line-bg-right-btn .btn-participate-image, #page-garantie.orange [class*="node-type-fondation"] .btn-participate.line-bg-right-btn .btn-participate-image,
[class*="node-type-fondation"] #page-garantie.orange .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.orange [class*="node-type-fondation"] .btn-participate.pdf-dl-btn .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-green .btn-participate-image, [class*="node-type-fondation"] #page-garantie.green .btn-participate.line-bg-right-btn .btn-participate-image, #page-garantie.green [class*="node-type-fondation"] .btn-participate.line-bg-right-btn .btn-participate-image,
[class*="node-type-fondation"] #page-garantie.green .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.green [class*="node-type-fondation"] .btn-participate.pdf-dl-btn .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-light-blue .btn-participate-image, [class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.line-bg-right-btn .btn-participate-image, #page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.line-bg-right-btn .btn-participate-image,
[class*="node-type-fondation"] #page-garantie.light-blue .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.light-blue [class*="node-type-fondation"] .btn-participate.pdf-dl-btn .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-pink .btn-participate-image, [class*="node-type-fondation"] #page-garantie.pink .btn-participate.line-bg-right-btn .btn-participate-image, #page-garantie.pink [class*="node-type-fondation"] .btn-participate.line-bg-right-btn .btn-participate-image,
[class*="node-type-fondation"] #page-garantie.pink .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.pink [class*="node-type-fondation"] .btn-participate.pdf-dl-btn .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-purple .btn-participate-image, [class*="node-type-fondation"] #page-garantie.purple .btn-participate.line-bg-right-btn .btn-participate-image, #page-garantie.purple [class*="node-type-fondation"] .btn-participate.line-bg-right-btn .btn-participate-image,
[class*="node-type-fondation"] #page-garantie.purple .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.purple [class*="node-type-fondation"] .btn-participate.pdf-dl-btn .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-light-grey .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-grey .btn-participate-image, [class*="node-type-fondation"] .btn-participate.btn-light-green .btn-participate-image, [class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn .btn-participate-image, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.video-btn .btn-participate-image,
[class*="node-type-fondation"] .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn .btn-participate-image,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .btn-participate.project-btn .btn-participate-image,
.page-theming .btn.btn-participate .btn-participate-image,
.page-theming .btn-participate.btn-orange .btn-participate-image,
.page-theming #page-garantie.orange .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.orange .page-theming .btn-participate.line-bg-right-btn .btn-participate-image,
.page-theming #page-garantie.orange .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.orange .page-theming .btn-participate.pdf-dl-btn .btn-participate-image,
.page-theming .btn-participate.btn-green .btn-participate-image,
.page-theming #page-garantie.green .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.green .page-theming .btn-participate.line-bg-right-btn .btn-participate-image,
.page-theming #page-garantie.green .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.green .page-theming .btn-participate.pdf-dl-btn .btn-participate-image,
.page-theming .btn-participate.btn-light-blue .btn-participate-image,
.page-theming #page-garantie.light-blue .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.light-blue .page-theming .btn-participate.line-bg-right-btn .btn-participate-image,
.page-theming #page-garantie.light-blue .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.light-blue .page-theming .btn-participate.pdf-dl-btn .btn-participate-image,
.page-theming .btn-participate.btn-pink .btn-participate-image,
.page-theming #page-garantie.pink .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.pink .page-theming .btn-participate.line-bg-right-btn .btn-participate-image,
.page-theming #page-garantie.pink .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.pink .page-theming .btn-participate.pdf-dl-btn .btn-participate-image,
.page-theming .btn-participate.btn-purple .btn-participate-image,
.page-theming #page-garantie.purple .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.purple .page-theming .btn-participate.line-bg-right-btn .btn-participate-image,
.page-theming #page-garantie.purple .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.purple .page-theming .btn-participate.pdf-dl-btn .btn-participate-image,
.page-theming .btn-participate.btn-light-grey .btn-participate-image,
.page-theming .btn-participate.btn-grey .btn-participate-image,
.page-theming .btn-participate.btn-light-green .btn-participate-image,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn .btn-participate-image,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.video-btn .btn-participate-image,
.page-theming .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn .btn-participate-image,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .btn-participate.project-btn .btn-participate-image,
.layout-fondation .btn.btn-participate .btn-participate-image,
.layout-fondation .btn-participate.btn-orange .btn-participate-image,
.layout-fondation #page-garantie.orange .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.orange .layout-fondation .btn-participate.line-bg-right-btn .btn-participate-image,
.layout-fondation #page-garantie.orange .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.orange .layout-fondation .btn-participate.pdf-dl-btn .btn-participate-image,
.layout-fondation .btn-participate.btn-green .btn-participate-image,
.layout-fondation #page-garantie.green .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.green .layout-fondation .btn-participate.line-bg-right-btn .btn-participate-image,
.layout-fondation #page-garantie.green .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.green .layout-fondation .btn-participate.pdf-dl-btn .btn-participate-image,
.layout-fondation .btn-participate.btn-light-blue .btn-participate-image,
.layout-fondation #page-garantie.light-blue .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.light-blue .layout-fondation .btn-participate.line-bg-right-btn .btn-participate-image,
.layout-fondation #page-garantie.light-blue .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.light-blue .layout-fondation .btn-participate.pdf-dl-btn .btn-participate-image,
.layout-fondation .btn-participate.btn-pink .btn-participate-image,
.layout-fondation #page-garantie.pink .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.pink .layout-fondation .btn-participate.line-bg-right-btn .btn-participate-image,
.layout-fondation #page-garantie.pink .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.pink .layout-fondation .btn-participate.pdf-dl-btn .btn-participate-image,
.layout-fondation .btn-participate.btn-purple .btn-participate-image,
.layout-fondation #page-garantie.purple .btn-participate.line-bg-right-btn .btn-participate-image,
#page-garantie.purple .layout-fondation .btn-participate.line-bg-right-btn .btn-participate-image,
.layout-fondation #page-garantie.purple .btn-participate.pdf-dl-btn .btn-participate-image,
#page-garantie.purple .layout-fondation .btn-participate.pdf-dl-btn .btn-participate-image,
.layout-fondation .btn-participate.btn-light-grey .btn-participate-image,
.layout-fondation .btn-participate.btn-grey .btn-participate-image,
.layout-fondation .btn-participate.btn-light-green .btn-participate-image,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.video-btn .btn-participate-image,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.video-btn .btn-participate-image,
.layout-fondation .node-type-laureate-list #liste-laureats > li.laureat .btn-participate.project-btn .btn-participate-image,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .btn-participate.project-btn .btn-participate-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
/* line 491, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .text-intro,
.page-theming .text-intro,
.layout-fondation .text-intro {
  padding: 0;
  font-size: 1em;
}
@media only screen and (max-width: 64em) {
  /* line 491, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .text-intro,
  .page-theming .text-intro,
  .layout-fondation .text-intro {
    padding: 0 10px;
  }
}
/* line 497, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .text-intro .text-intro-title,
.page-theming .text-intro .text-intro-title,
.layout-fondation .text-intro .text-intro-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  color: #3855a0;
  display: block;
  margin-bottom: 15px;
}
/* line 505, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .big-border,
.page-theming .big-border,
.layout-fondation .big-border {
  border: 20px solid #ebf1f3;
  padding: 15px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 48em) {
  /* line 511, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .img-header-container,
  .page-theming .img-header-container,
  .layout-fondation .img-header-container {
    display: block;
    min-height: auto;
  }
}
/* line 517, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .img-header-container .img-header,
.page-theming .img-header-container .img-header,
.layout-fondation .img-header-container .img-header {
  display: inline;
  height: auto;
}
/* line 527, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters,
.page-theming .view-filters,
.layout-fondation .view-filters {
  width: 50%;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 527, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters,
  .page-theming .view-filters,
  .layout-fondation .view-filters {
    width: 95%;
  }
}
/* line 533, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .views-exposed-form,
.page-theming .view-filters .views-exposed-form,
.layout-fondation .view-filters .views-exposed-form {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 48em) {
  /* line 533, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters .views-exposed-form,
  .page-theming .view-filters .views-exposed-form,
  .layout-fondation .view-filters .views-exposed-form {
    width: 100%;
  }
}
/* line 541, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .search-input-container .search-input,
.page-theming .view-filters .search-input-container .search-input,
.layout-fondation .view-filters .search-input-container .search-input {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
}
@media only screen and (max-width: 64em) {
  /* line 541, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters .search-input-container .search-input,
  .page-theming .view-filters .search-input-container .search-input,
  .layout-fondation .view-filters .search-input-container .search-input {
    border: 4px solid #EA670A;
  }
}
/* line 548, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .search-input-container .search-submit,
.page-theming .view-filters .search-input-container .search-submit,
.layout-fondation .view-filters .search-input-container .search-submit {
  color: #EA670A;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
}
/* line 552, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .search-input-container .search-submit:focus, [class*="node-type-fondation"] .view-filters .search-input-container .search-submit:hover,
.page-theming .view-filters .search-input-container .search-submit:focus,
.page-theming .view-filters .search-input-container .search-submit:hover,
.layout-fondation .view-filters .search-input-container .search-submit:focus,
.layout-fondation .view-filters .search-input-container .search-submit:hover {
  background: #EA670A;
  color: white;
}
/* line 557, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .search-input-container [class^="icon-"],
.page-theming .view-filters .search-input-container [class^="icon-"],
.layout-fondation .view-filters .search-input-container [class^="icon-"] {
  position: absolute;
  height: 100%;
  top: 13px;
  left: 10px;
  color: #a0a0a0;
  font-size: 1.875em;
}
@media only screen and (max-width: 64em) {
  /* line 557, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters .search-input-container [class^="icon-"],
  .page-theming .view-filters .search-input-container [class^="icon-"],
  .layout-fondation .view-filters .search-input-container [class^="icon-"] {
    font-size: 1.5625em;
  }
}
/* line 567, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters .search-input-container [class^="icon-"]:after,
.page-theming .view-filters .search-input-container [class^="icon-"]:after,
.layout-fondation .view-filters .search-input-container [class^="icon-"]:after {
  position: absolute;
  content: "";
  top: calc(20% - 13px);
  right: -12px;
  height: 60%;
  width: 1px;
  background: #f3f2f1;
}
/* line 578, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters ::placeholder,
.page-theming .view-filters ::placeholder,
.layout-fondation .view-filters ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a0a0;
  opacity: 1;
  /* Firefox */
}
/* line 582, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters :-ms-input-placeholder,
.page-theming .view-filters :-ms-input-placeholder,
.layout-fondation .view-filters :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a0a0;
}
/* line 585, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters ::-ms-input-placeholder,
.page-theming .view-filters ::-ms-input-placeholder,
.layout-fondation .view-filters ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a0a0;
}
/* line 589, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects,
.page-theming .view-filters.filters-list-projects,
.layout-fondation .view-filters.filters-list-projects {
  margin-top: 50px;
  width: 100%;
}
/* line 592, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .filters-list-projects-label,
.page-theming .view-filters.filters-list-projects .filters-list-projects-label,
.layout-fondation .view-filters.filters-list-projects .filters-list-projects-label {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.625em;
  color: #3855a0;
  margin-bottom: 20px;
  display: block;
}
/* line 599, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .views-exposed-form,
.page-theming .view-filters.filters-list-projects .views-exposed-form,
.layout-fondation .view-filters.filters-list-projects .views-exposed-form {
  width: 50%;
}
@media only screen and (max-width: 48em) {
  /* line 599, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters.filters-list-projects .views-exposed-form,
  .page-theming .view-filters.filters-list-projects .views-exposed-form,
  .layout-fondation .view-filters.filters-list-projects .views-exposed-form {
    width: 100%;
  }
}
/* line 606, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects [class^="icon-"]:after,
.page-theming .view-filters.filters-list-projects [class^="icon-"]:after,
.layout-fondation .view-filters.filters-list-projects [class^="icon-"]:after {
  background: #a0a0a0;
}
/* line 610, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .search-input,
.page-theming .view-filters.filters-list-projects .search-input,
.layout-fondation .view-filters.filters-list-projects .search-input {
  border: 1px solid #a0a0a0;
  text-align: center;
}
/* line 615, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .search-submit-container:before,
.page-theming .view-filters.filters-list-projects .search-submit-container:before,
.layout-fondation .view-filters.filters-list-projects .search-submit-container:before {
  position: absolute;
  content: "";
  top: 20%;
  left: 0;
  height: 60%;
  width: 1px;
  background: #a0a0a0;
}
/* line 625, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list,
.page-theming .view-filters.filters-list-projects .bk-filters-list,
.layout-fondation .view-filters.filters-list-projects .bk-filters-list {
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.625em;
  padding: 0;
}
/* line 631, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .filters-list-title,
.page-theming .view-filters.filters-list-projects .bk-filters-list .filters-list-title,
.layout-fondation .view-filters.filters-list-projects .bk-filters-list .filters-list-title {
  display: block;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
/* line 637, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
.page-theming .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
.layout-fondation .view-filters.filters-list-projects .bk-filters-list .select-wrapper {
  margin-left: 0;
  width: 31%;
}
@media only screen and (max-width: 64em) {
  /* line 637, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
  .page-theming .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
  .layout-fondation .view-filters.filters-list-projects .bk-filters-list .select-wrapper {
    font-size: 1.125em;
  }
}
@media only screen and (max-width: 48em) {
  /* line 637, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
  .page-theming .view-filters.filters-list-projects .bk-filters-list .select-wrapper,
  .layout-fondation .view-filters.filters-list-projects .bk-filters-list .select-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
}
/* line 647, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select,
.page-theming .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select,
.layout-fondation .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select {
  border-radius: 0;
}
/* line 649, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select .form-item,
.page-theming .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select .form-item,
.layout-fondation .view-filters.filters-list-projects .bk-filters-list .select-wrapper .styled-select .form-item {
  margin: 0;
}
/* line 660, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .view-empty,
.page-theming .view-empty,
.layout-fondation .view-empty {
  color: #3855a0;
  text-align: center;
  margin: 20px 0 40px;
}
/* line 669, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .rss-link-container,
.page-theming .rss-link-container,
.layout-fondation .rss-link-container {
  width: 100%;
  text-align: right;
}
/* line 672, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .rss-link-container [class^="icon-"],
.page-theming .rss-link-container [class^="icon-"],
.layout-fondation .rss-link-container [class^="icon-"] {
  color: #163375;
  font-size: 22px;
  margin-right: 5px;
}
/* line 677, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .rss-link-container .rss-link,
.page-theming .rss-link-container .rss-link,
.layout-fondation .rss-link-container .rss-link {
  font-family: Arial;
  font-size: 0.875em;
  text-decoration: none;
  color: #163375;
  transition: all 0.3s ease;
}
/* line 683, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .rss-link-container .rss-link:focus, [class*="node-type-fondation"] .rss-link-container .rss-link:hover,
.page-theming .rss-link-container .rss-link:focus,
.page-theming .rss-link-container .rss-link:hover,
.layout-fondation .rss-link-container .rss-link:focus,
.layout-fondation .rss-link-container .rss-link:hover {
  text-decoration: underline;
}
/* line 692, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .search-bar-and-rss-feed,
.page-theming .search-bar-and-rss-feed,
.layout-fondation .search-bar-and-rss-feed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
@media only screen and (max-width: 64em) {
  /* line 692, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .search-bar-and-rss-feed,
  .page-theming .search-bar-and-rss-feed,
  .layout-fondation .search-bar-and-rss-feed {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 48em) {
  /* line 692, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .search-bar-and-rss-feed,
  .page-theming .search-bar-and-rss-feed,
  .layout-fondation .search-bar-and-rss-feed {
    flex-wrap: wrap;
  }
}
/* line 703, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .search-bar-and-rss-feed .view-filters,
.page-theming .search-bar-and-rss-feed .view-filters,
.layout-fondation .search-bar-and-rss-feed .view-filters {
  width: 50%;
}
@media only screen and (max-width: 48em) {
  /* line 703, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .search-bar-and-rss-feed .view-filters,
  .page-theming .search-bar-and-rss-feed .view-filters,
  .layout-fondation .search-bar-and-rss-feed .view-filters {
    width: 100%;
  }
}
/* line 709, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .search-bar-and-rss-feed .rss-link-container,
.page-theming .search-bar-and-rss-feed .rss-link-container,
.layout-fondation .search-bar-and-rss-feed .rss-link-container {
  width: 50%;
}
@media only screen and (max-width: 48em) {
  /* line 709, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .search-bar-and-rss-feed .rss-link-container,
  .page-theming .search-bar-and-rss-feed .rss-link-container,
  .layout-fondation .search-bar-and-rss-feed .rss-link-container {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
}
/* line 722, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container,
.page-theming .hp-grid-container,
.layout-fondation .hp-grid-container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px 45px;
}
@media only screen and (max-width: 48em) {
  /* line 722, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container,
  .page-theming .hp-grid-container,
  .layout-fondation .hp-grid-container {
    padding: 20px;
  }
}
/* line 729, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list,
.page-theming .hp-grid-container .grid-list,
.layout-fondation .hp-grid-container .grid-list {
  margin-bottom: 0;
  text-align: center;
}
/* line 734, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid,
.page-theming .hp-grid-container .grid-list .grid,
.layout-fondation .hp-grid-container .grid-list .grid {
  width: 100%;
  margin-bottom: 18px;
  /**************************************
  ---- BLOCS COULEURS
  **************************************/
  /**************************************
  ---- BLOCS MEDIA
  **************************************/
  /**************************************
  ---- CONTENU MANUEL
  **************************************/
  /**************************************
  ---- BLOC SLIDER
  **************************************/
}
@media print, screen and (min-width: 20em) and (max-width: 64.0625em) {
  /* line 734, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid,
  .page-theming .hp-grid-container .grid-list .grid,
  .layout-fondation .hp-grid-container .grid-list .grid {
    margin-right: 0 !important;
  }
}
@media print, screen and (min-width: 64.0625em) {
  /* line 734, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid,
  .page-theming .hp-grid-container .grid-list .grid,
  .layout-fondation .hp-grid-container .grid-list .grid {
    display: inline-block;
    vertical-align: top;
    width: 299px;
  }
  /* line 747, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four,
  .page-theming .hp-grid-container .grid-list .grid.grid-two,
  .page-theming .hp-grid-container .grid-list .grid.grid-four,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four {
    width: 616px;
  }
  /* line 751, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .video-grid .video-preview-link, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .video-grid .video-preview-link,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .video-grid .video-preview-link,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .video-grid .video-preview-link,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .video-grid .video-preview-link,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .video-grid .video-preview-link {
    display: block;
    width: 50%;
  }
  /* line 755, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .video-grid .video-content, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .video-grid .video-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .video-grid .video-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .video-grid .video-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .video-grid .video-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .video-grid .video-content {
    width: 50%;
  }
  /* line 760, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-preview, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-preview,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-preview,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-preview,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-preview,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-preview {
    display: block;
    width: 50%;
  }
  /* line 764, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-column, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-column,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-column,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-column,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-custom-block .custom-block-column,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-custom-block .custom-block-column {
    width: 50%;
  }
  /* line 770, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-content, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-content {
    width: 50%;
  }
  /* line 773, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-bg, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-bg,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-bg,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-bg,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .fondation-slide .slide-bg,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .fondation-slide .slide-bg {
    display: block;
    width: 50%;
  }
  /* line 779, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .nav-slider .next, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .nav-slider .next,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .nav-slider .next,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .nav-slider .next,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .nav-slider .next,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .nav-slider .next {
    right: 50%;
  }
  /* line 783, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .bx-pager, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .bx-pager,
  .page-theming .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .bx-pager,
  .page-theming .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .bx-pager,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-two .grid-fondation-slider .bx-pager,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-four .grid-fondation-slider .bx-pager {
    width: 50%;
  }
  /* line 789, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six,
  .page-theming .hp-grid-container .grid-list .grid.grid-three,
  .page-theming .hp-grid-container .grid-list .grid.grid-six,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six {
    width: 100%;
  }
  /* line 793, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .video-grid .video-preview-link, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .video-grid .video-preview-link,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .video-grid .video-preview-link,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .video-grid .video-preview-link,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .video-grid .video-preview-link,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .video-grid .video-preview-link {
    display: block;
    width: 50%;
  }
  /* line 797, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .video-grid .video-content, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .video-grid .video-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .video-grid .video-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .video-grid .video-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .video-grid .video-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .video-grid .video-content {
    width: 50%;
    height: 440px;
  }
  /* line 803, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview {
    display: block;
    width: 50%;
  }
  /* line 806, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview .custom-block-img, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview .custom-block-img,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview .custom-block-img,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview .custom-block-img,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-preview .custom-block-img,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-preview .custom-block-img {
    height: 440px;
  }
  /* line 810, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-column, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-column,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-column,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-column,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-custom-block .custom-block-column,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-custom-block .custom-block-column {
    padding: 70px 20px 40px;
    width: 50%;
    height: 440px;
  }
  /* line 818, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content {
    width: 50%;
  }
  /* line 820, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content .slide-text, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content .slide-text,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content .slide-text,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content .slide-text,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-content .slide-text,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-content .slide-text {
    display: block;
  }
  /* line 824, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-bg, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-bg,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-bg,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-bg,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .fondation-slide .slide-bg,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .fondation-slide .slide-bg {
    display: block;
    width: 50%;
  }
  /* line 830, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .nav-slider .next, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .nav-slider .next,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .nav-slider .next,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .nav-slider .next,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .nav-slider .next,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .nav-slider .next {
    right: 50%;
  }
  /* line 834, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .bx-pager, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .bx-pager,
  .page-theming .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .bx-pager,
  .page-theming .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .bx-pager,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-three .grid-fondation-slider .bx-pager,
  .layout-fondation .hp-grid-container .grid-list .grid.grid-six .grid-fondation-slider .bx-pager {
    width: 50%;
  }
}
/* line 844, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid,
.page-theming .hp-grid-container .grid-list .grid .color-grid,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid {
  position: relative;
}
/* line 846, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid .color-content,
.page-theming .hp-grid-container .grid-list .grid .color-grid .color-content,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid .color-content {
  padding: 0 30px 40px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
/* line 859, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title,
.page-theming .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* line 864, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title h1,
.page-theming .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title h1,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid .color-content .color-content-title h1 {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.875em;
}
/* line 869, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid .color-content .color-link,
.page-theming .hp-grid-container .grid-list .grid .color-grid .color-content .color-link,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid .color-content .color-link {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
}
/* line 876, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.orange .color-link,
.page-theming .hp-grid-container .grid-list .grid .color-grid.orange .color-link,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.orange .color-link {
  color: #163375 !important;
}
/* line 878, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.orange .color-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.orange .color-link:hover,
.page-theming .hp-grid-container .grid-list .grid .color-grid.orange .color-link:focus,
.page-theming .hp-grid-container .grid-list .grid .color-grid.orange .color-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.orange .color-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.orange .color-link:hover {
  background-color: #163375 !important;
  border: 1px solid white !important;
  color: white !important;
}
/* line 886, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.pink .color-link,
.page-theming .hp-grid-container .grid-list .grid .color-grid.pink .color-link,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.pink .color-link {
  color: #EA670A !important;
}
/* line 888, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.pink .color-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.pink .color-link:hover,
.page-theming .hp-grid-container .grid-list .grid .color-grid.pink .color-link:focus,
.page-theming .hp-grid-container .grid-list .grid .color-grid.pink .color-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.pink .color-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.pink .color-link:hover {
  background-color: #EA670A !important;
  border: 1px solid white !important;
  color: white !important;
}
/* line 896, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link,
.page-theming .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link {
  color: #3855a0 !important;
}
/* line 898, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:hover,
.page-theming .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:focus,
.page-theming .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .color-grid.dark_blue .color-link:hover {
  background-color: #3855a0 !important;
  border: 1px solid white !important;
  color: white !important;
}
/* line 912, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-preview-link,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-preview-link,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-preview-link {
  display: none;
  width: 100%;
  overflow: hidden;
}
/* line 916, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-preview-link .video-preview,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-preview-link .video-preview,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-preview-link .video-preview {
  width: auto;
  max-width: initial;
}
/* line 921, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content {
  width: 100%;
  position: relative;
  background-color: white;
  border-right: 1px solid #ebf1f3;
  height: 330px;
}
/* line 927, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center {
  padding: 35px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
/* line 940, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* line 945, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper .video-title,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper .video-title,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-title-wrapper .video-title {
  border: none;
  color: #163375;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
}
/* line 952, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link {
  background-color: #163375;
  border: 1px solid #163375;
  color: white;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 10px 20px;
}
/* line 960, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:hover,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:focus,
.page-theming .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .video-grid .video-content .center .video-link:hover {
  background-color: white;
  color: #163375;
  border: 1px solid #163375;
}
/* line 973, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
/* line 978, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview {
  display: none;
  width: 100%;
  overflow: hidden;
}
/* line 982, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview .custom-block-img,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview .custom-block-img,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-preview .custom-block-img {
  width: auto;
  max-width: initial;
  margin: 0;
}
/* line 988, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}
/* line 996, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-title,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-title,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  color: #EA670A;
  text-align: center;
}
/* line 1002, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-content,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-content,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .custom-block-content {
  font-size: 1em;
  color: #3855a0;
}
/* line 1006, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center {
  width: 100%;
}
/* line 1008, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.375em;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #EA670A;
  background-color: #EA670A;
  color: white;
}
/* line 1016, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:hover,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:focus,
.page-theming .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:hover,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:focus,
.layout-fondation .hp-grid-container .grid-list .grid .grid-custom-block .custom-block-column .text-center .custom-block-btn:hover {
  background-color: white;
  color: #EA670A;
}
/* line 1029, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .top-slider,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .top-slider,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .top-slider {
  background-color: white;
}
/* line 1032, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
/* line 1036, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content {
  height: 330px;
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 25px 40px 60px 40px;
  position: relative;
}
/* line 1047, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-tag,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-tag,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-tag {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1em;
  color: #a0a0a0;
  width: 100%;
  text-align: right;
  margin-right: -40px;
}
/* line 1055, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-title,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-title,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  color: #EA670A;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* line 1066, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-text,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-text,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-text {
  display: none;
  color: #3855a0;
}
/* line 1070, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  background-color: #EA670A;
  border-color: #EA670A;
}
/* line 1077, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:hover,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:focus,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-content .slide-link:hover {
  background: white;
  color: #EA670A;
}
/* line 1083, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-bg,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-bg,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .fondation-slide .slide-bg {
  display: none;
}
/* line 1088, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev {
  left: 0;
  bottom: 46%;
}
/* line 1092, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next {
  right: 0;
  bottom: 46%;
}
/* line 1099, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:focus:before, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:hover:before,
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:focus:before,
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:hover:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:focus:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:hover:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:focus:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:hover:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:focus:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .prev:hover:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:focus:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .nav-slider .next:hover:before {
  color: #EA670A;
}
/* line 1105, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager {
  width: 100%;
  left: 0;
  top: auto;
  bottom: 5px;
}
/* line 1111, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link.active, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:focus, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:hover,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link.active,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:focus,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:hover,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link.active,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:focus,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider .bx-pager .bx-pager-link:hover {
  background-color: #EA670A;
}
/* line 1119, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image {
  height: 330px;
}
/* line 1121, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide {
  flex-wrap: wrap;
}
/* line 1123, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content {
  width: 100%;
  background-color: transparent;
}
/* line 1126, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-content .slide-title {
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 20px 30px;
  color: white;
  text-transform: initial;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.875em;
}
/* line 1141, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg {
  width: 100%;
  margin-right: -100%;
}
@media only screen and (max-width: 64em) {
  /* line 1141, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg,
  .page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg,
  .layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .fondation-slide .slide-bg {
    display: block;
  }
}
/* line 1150, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev {
  left: 20px;
}
/* line 1153, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next {
  right: 20px;
}
/* line 1156, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev,
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next {
  color: white;
}
/* line 1160, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:focus:before, [class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:hover:before,
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:focus:before,
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:hover:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:focus:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:hover:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:focus:before,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:hover:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:focus:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .prev:hover:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:focus:before,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .nav-slider .next:hover:before {
  color: white;
}
/* line 1166, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager {
  right: 20px;
  left: auto;
  bottom: 0;
  width: auto;
  text-align: right;
  margin: 0;
  padding: 14px;
  position: absolute;
}
/* line 1176, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager li button,
.page-theming .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager li button,
.layout-fondation .hp-grid-container .grid-list .grid .grid-fondation-slider.grid-fondation-slider-full-image .bx-pager li button {
  background-color: white;
}
/* line 1190, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image,
.page-theming .bk-fondation-slider-full-image,
.layout-fondation .bk-fondation-slider-full-image {
  height: 400px;
  margin-bottom: 50px;
}
/* line 1193, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .top-slider,
.page-theming .bk-fondation-slider-full-image .top-slider,
.layout-fondation .bk-fondation-slider-full-image .top-slider {
  background-color: white;
}
/* line 1196, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide,
.page-theming .bk-fondation-slider-full-image .fondation-slide,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
/* line 1201, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content {
  height: 400px;
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 25px 40px 60px 40px;
  position: relative;
  background-color: transparent;
}
/* line 1213, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-tag,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-tag,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-tag {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1em;
  color: #a0a0a0;
  width: 100%;
  text-align: right;
  margin-right: -40px;
}
/* line 1221, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.875em;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 20px 30px;
  color: white;
  text-transform: initial;
}
@media only screen and (max-width: 64em) {
  /* line 1221, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
  .page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title,
  .layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-title {
    font-size: 1.125em;
  }
}
/* line 1242, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-text,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-text,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-text {
  display: none;
  color: #3855a0;
}
/* line 1246, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  background-color: #EA670A;
  border-color: #EA670A;
}
/* line 1253, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:focus, [class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:hover,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:focus,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:hover,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:focus,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-content .slide-link:hover {
  background: white;
  color: #EA670A;
}
/* line 1259, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-bg,
.page-theming .bk-fondation-slider-full-image .fondation-slide .slide-bg,
.layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-bg {
  width: 100%;
  margin-right: -100%;
}
@media only screen and (max-width: 64em) {
  /* line 1259, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-fondation-slider-full-image .fondation-slide .slide-bg,
  .page-theming .bk-fondation-slider-full-image .fondation-slide .slide-bg,
  .layout-fondation .bk-fondation-slider-full-image .fondation-slide .slide-bg {
    display: block;
  }
}
/* line 1268, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .prev,
.page-theming .bk-fondation-slider-full-image .nav-slider .prev,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .prev {
  left: 20px;
  bottom: 46%;
}
/* line 1272, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .next,
.page-theming .bk-fondation-slider-full-image .nav-slider .next,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .next {
  right: 20px;
  bottom: 46%;
}
/* line 1276, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .prev,
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .next,
.page-theming .bk-fondation-slider-full-image .nav-slider .prev,
.page-theming .bk-fondation-slider-full-image .nav-slider .next,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .prev,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .next {
  color: white;
}
/* line 1280, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .prev:focus:before, [class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .prev:hover:before,
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .next:focus:before,
[class*="node-type-fondation"] .bk-fondation-slider-full-image .nav-slider .next:hover:before,
.page-theming .bk-fondation-slider-full-image .nav-slider .prev:focus:before,
.page-theming .bk-fondation-slider-full-image .nav-slider .prev:hover:before,
.page-theming .bk-fondation-slider-full-image .nav-slider .next:focus:before,
.page-theming .bk-fondation-slider-full-image .nav-slider .next:hover:before,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .prev:focus:before,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .prev:hover:before,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .next:focus:before,
.layout-fondation .bk-fondation-slider-full-image .nav-slider .next:hover:before {
  color: white;
}
/* line 1286, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager,
.page-theming .bk-fondation-slider-full-image .bx-pager,
.layout-fondation .bk-fondation-slider-full-image .bx-pager {
  position: absolute;
  top: auto;
  right: 20px;
  left: auto;
  bottom: 0;
  width: auto;
  text-align: right;
  margin: 0;
  padding: 14px;
}
@media only screen and (max-width: 64em) {
  /* line 1286, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager,
  .page-theming .bk-fondation-slider-full-image .bx-pager,
  .layout-fondation .bk-fondation-slider-full-image .bx-pager {
    right: 0;
    bottom: 55px;
    width: 100%;
    text-align: center;
    padding: 0;
  }
}
/* line 1304, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager li button,
.page-theming .bk-fondation-slider-full-image .bx-pager li button,
.layout-fondation .bk-fondation-slider-full-image .bx-pager li button {
  background-color: white;
}
/* line 1309, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager .bx-pager-link.active, [class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager .bx-pager-link:focus, [class*="node-type-fondation"] .bk-fondation-slider-full-image .bx-pager .bx-pager-link:hover,
.page-theming .bk-fondation-slider-full-image .bx-pager .bx-pager-link.active,
.page-theming .bk-fondation-slider-full-image .bx-pager .bx-pager-link:focus,
.page-theming .bk-fondation-slider-full-image .bx-pager .bx-pager-link:hover,
.layout-fondation .bk-fondation-slider-full-image .bx-pager .bx-pager-link.active,
.layout-fondation .bk-fondation-slider-full-image .bx-pager .bx-pager-link:focus,
.layout-fondation .bk-fondation-slider-full-image .bx-pager .bx-pager-link:hover {
  background-color: #EA670A;
}
/* line 1321, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper,
.page-theming .bk-color-infos-wrapper,
.layout-fondation .bk-color-infos-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 1321, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-color-infos-wrapper,
  .page-theming .bk-color-infos-wrapper,
  .layout-fondation .bk-color-infos-wrapper {
    flex-flow: row wrap;
  }
}
/* line 1330, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos,
.page-theming .bk-color-infos-wrapper .bk-color-infos,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos {
  background-color: #3855a0;
  width: 100%;
  height: 270px;
  text-align: center;
}
@media only screen and (max-width: 64em) {
  /* line 1330, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos,
  .page-theming .bk-color-infos-wrapper .bk-color-infos,
  .layout-fondation .bk-color-infos-wrapper .bk-color-infos {
    width: calc(100% - 20px);
    height: auto;
    margin: 0 auto 20px;
  }
}
/* line 1340, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos,
.page-theming .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos {
  margin-left: 20px;
}
@media only screen and (max-width: 64em) {
  /* line 1340, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos,
  .page-theming .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos,
  .layout-fondation .bk-color-infos-wrapper .bk-color-infos + .bk-color-infos {
    margin-left: auto;
  }
}
/* line 1346, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content,
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content {
  color: #3855a0;
  padding: 40px 30px;
  justify-content: flex-start;
}
/* line 1350, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content [class^="icon-"],
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content [class^="icon-"],
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content [class^="icon-"] {
  font-size: 40px;
  color: white;
}
/* line 1354, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title,
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title {
  height: auto;
}
/* line 1356, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title h1,
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title h1,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title h1 {
  font-size: 3.75em;
  color: white;
  margin: 10px 0 0;
}
/* line 1361, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title .color-infos-numbers,
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title .color-infos-numbers,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-infos-content-title .color-infos-numbers {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3.75em;
  color: white;
  margin: 30px 0;
  display: block;
}
/* line 1369, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-content-text,
.page-theming .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-content-text,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos .color-infos-content .color-content-text {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  color: white;
}
/* line 1375, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.orange,
.page-theming .bk-color-infos-wrapper .bk-color-infos.orange,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.orange {
  background-color: #163375;
}
/* line 1377, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.orange .color-link,
.page-theming .bk-color-infos-wrapper .bk-color-infos.orange .color-link,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.orange .color-link {
  color: #163375;
}
/* line 1379, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.orange .color-link:focus, [class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.orange .color-link:hover,
.page-theming .bk-color-infos-wrapper .bk-color-infos.orange .color-link:focus,
.page-theming .bk-color-infos-wrapper .bk-color-infos.orange .color-link:hover,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.orange .color-link:focus,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.orange .color-link:hover {
  background-color: #163375;
  border: 1px solid white;
  color: white;
}
/* line 1386, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.pink,
.page-theming .bk-color-infos-wrapper .bk-color-infos.pink,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.pink {
  background-color: #EA670A;
}
/* line 1388, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.pink .color-link,
.page-theming .bk-color-infos-wrapper .bk-color-infos.pink .color-link,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.pink .color-link {
  color: #EA670A;
}
/* line 1390, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.pink .color-link:focus, [class*="node-type-fondation"] .bk-color-infos-wrapper .bk-color-infos.pink .color-link:hover,
.page-theming .bk-color-infos-wrapper .bk-color-infos.pink .color-link:focus,
.page-theming .bk-color-infos-wrapper .bk-color-infos.pink .color-link:hover,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.pink .color-link:focus,
.layout-fondation .bk-color-infos-wrapper .bk-color-infos.pink .color-link:hover {
  background-color: #EA670A;
  border: 1px solid white;
  color: white;
}
/* line 1404, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-btn.is-active,
.page-theming .sommaire-btn.is-active,
.layout-fondation .sommaire-btn.is-active {
  color: #3855a0;
}
/* line 1412, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] #back-top,
.page-theming #back-top,
.layout-fondation #back-top {
  color: #3855a0;
}
@media print, screen and (min-width: 64.0625em) {
  /* line 1419, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .page-footer-wrapper,
  .page-theming .page-footer-wrapper,
  .layout-fondation .page-footer-wrapper {
    background: url('../images/footer-bg-desktop-fondation.png?1633013057') no-repeat center bottom;
  }
}
/* line 1424, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .page-footer-wrapper .fondation-line-bg .line-bg-line,
.page-theming .page-footer-wrapper .fondation-line-bg .line-bg-line,
.layout-fondation .page-footer-wrapper .fondation-line-bg .line-bg-line {
  background-color: #060055;
}
/* line 1428, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .page-footer-wrapper .fondation-line-bg .line-bg-txt h1,
.page-theming .page-footer-wrapper .fondation-line-bg .line-bg-txt h1,
.layout-fondation .page-footer-wrapper .fondation-line-bg .line-bg-txt h1 {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  color: #060055;
  margin-right: 20px;
}
/* line 1437, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .page-footer-wrapper .fondation-footer-top p,
.page-theming .page-footer-wrapper .fondation-footer-top p,
.layout-fondation .page-footer-wrapper .fondation-footer-top p {
  font-size: 1em;
  color: #060055;
}
/* line 1448, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-container,
.page-theming .thumbnail-list-container,
.layout-fondation .thumbnail-list-container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px 45px;
}
@media only screen and (max-width: 64em) {
  /* line 1448, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-list-container,
  .page-theming .thumbnail-list-container,
  .layout-fondation .thumbnail-list-container {
    padding: 20px;
  }
}
/* line 1456, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list,
.page-theming .thumbnail-list,
.layout-fondation .thumbnail-list {
  margin-bottom: 0;
}
/* line 1459, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail,
.page-theming .thumbnail,
.layout-fondation .thumbnail {
  margin-bottom: 50px;
  position: relative;
  min-height: 395px;
}
@media only screen and (max-width: 64em) {
  /* line 1459, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail,
  .page-theming .thumbnail,
  .layout-fondation .thumbnail {
    margin-bottom: 20px;
  }
}
/* line 1466, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .left-part,
.page-theming .thumbnail .left-part,
.layout-fondation .thumbnail .left-part {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* line 1471, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part,
.page-theming .thumbnail .right-part,
.layout-fondation .thumbnail .right-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: white;
}
/* line 1479, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content,
.page-theming .thumbnail .right-part .thumbnail-content,
.layout-fondation .thumbnail .right-part .thumbnail-content {
  background-color: white;
}
/* line 1481, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-title {
  width: 100%;
  border-bottom: 5px solid #3855a0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
/* line 1487, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
  margin-bottom: 0;
  line-height: 1;
}
/* line 1492, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper a,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper a,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-title-wrapper a {
  text-decoration: none;
  white-space: normal;
}
/* line 1497, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .scoopit-source,
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-date,
.page-theming .thumbnail .right-part .thumbnail-content .scoopit-source,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-date,
.layout-fondation .thumbnail .right-part .thumbnail-content .scoopit-source,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-date {
  display: block;
  font-family: Arial;
  font-size: 0.6875em;
  color: #a0a0a0;
}
/* line 1504, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-text,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-text,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-text {
  color: #3855a0;
  font-family: Arial;
  font-size: 0.875em;
  width: 100%;
}
/* line 1510, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-media,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-media,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-media {
  height: 200px;
  text-align: center;
}
@media only screen and (max-width: 64em) {
  /* line 1510, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-media,
  .page-theming .thumbnail .right-part .thumbnail-content .thumbnail-media,
  .layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-media {
    height: 320px;
  }
}
/* line 1516, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-media img,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-media img,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-media img {
  max-height: 200px;
  margin: auto;
}
/* line 1522, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-label,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-label,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-label {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1em;
  color: #3855a0;
}
/* line 1527, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-value,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-value,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-options .thumbnail-options-value {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
  color: #EA670A;
}
/* line 1533, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-btn,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-btn,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-btn {
  width: 100%;
  text-align: right;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
/* line 1540, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #3855a0;
  font-family: Arial;
  font-size: 0.875em;
}
/* line 1548, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link [class^="icon-"],
.page-theming .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link [class^="icon-"],
.layout-fondation .thumbnail .right-part .thumbnail-content .thumbnail-btn .thumbnail-link [class^="icon-"] {
  font-size: 1.375em;
  margin-left: 5px;
}
/* line 1558, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail.pink .thumbnail-content .thumbnail-title,
.page-theming .thumbnail.pink .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail.pink .thumbnail-content .thumbnail-title {
  border-bottom-color: #EA670A;
}
/* line 1562, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail.pink .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail.pink .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail.pink .thumbnail-content .thumbnail-btn .thumbnail-link {
  color: #EA670A;
}
/* line 1570, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail.orange .thumbnail-content .thumbnail-title,
.page-theming .thumbnail.orange .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail.orange .thumbnail-content .thumbnail-title {
  border-bottom-color: #163375;
}
/* line 1574, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail.orange .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail.orange .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail.orange .thumbnail-content .thumbnail-btn .thumbnail-link {
  color: #163375;
}
/* line 1581, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xl,
.page-theming .thumbnail-xl,
.layout-fondation .thumbnail-xl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: auto;
}
/* line 1587, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xl .left-part,
.page-theming .thumbnail-xl .left-part,
.layout-fondation .thumbnail-xl .left-part {
  width: 50%;
}
@media only screen and (max-width: 48em) {
  /* line 1587, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xl .left-part,
  .page-theming .thumbnail-xl .left-part,
  .layout-fondation .thumbnail-xl .left-part {
    width: 100%;
    height: 200px;
  }
}
/* line 1594, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xl .right-part,
.page-theming .thumbnail-xl .right-part,
.layout-fondation .thumbnail-xl .right-part {
  position: relative;
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 48em) {
  /* line 1594, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xl .right-part,
  .page-theming .thumbnail-xl .right-part,
  .layout-fondation .thumbnail-xl .right-part {
    width: 100%;
  }
}
/* line 1601, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xl .right-part .thumbnail-content,
.page-theming .thumbnail-xl .right-part .thumbnail-content,
.layout-fondation .thumbnail-xl .right-part .thumbnail-content {
  padding: 30px 50px;
}
@media only screen and (max-width: 48em) {
  /* line 1601, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xl .right-part .thumbnail-content,
  .page-theming .thumbnail-xl .right-part .thumbnail-content,
  .layout-fondation .thumbnail-xl .right-part .thumbnail-content {
    padding: 20px;
  }
}
/* line 1606, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xl .right-part .thumbnail-content .thumbnail-text,
.page-theming .thumbnail-xl .right-part .thumbnail-content .thumbnail-text,
.layout-fondation .thumbnail-xl .right-part .thumbnail-content .thumbnail-text {
  margin-bottom: 30px;
}
/* line 1612, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl,
.page-theming .thumbnail-xxl,
.layout-fondation .thumbnail-xxl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: auto;
}
/* line 1618, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .left-part,
.page-theming .thumbnail-xxl .left-part,
.layout-fondation .thumbnail-xxl .left-part {
  background-size: contain;
  background-color: white;
  width: 35%;
}
@media only screen and (max-width: 48em) {
  /* line 1618, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xxl .left-part,
  .page-theming .thumbnail-xxl .left-part,
  .layout-fondation .thumbnail-xxl .left-part {
    width: 100%;
    height: 200px;
  }
}
/* line 1627, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part,
.page-theming .thumbnail-xxl .right-part,
.layout-fondation .thumbnail-xxl .right-part {
  position: relative;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 48em) {
  /* line 1627, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xxl .right-part,
  .page-theming .thumbnail-xxl .right-part,
  .layout-fondation .thumbnail-xxl .right-part {
    width: 100%;
  }
}
/* line 1634, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content,
.page-theming .thumbnail-xxl .right-part .thumbnail-content,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content {
  padding: 15px 20px;
}
@media only screen and (max-width: 48em) {
  /* line 1634, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content,
  .page-theming .thumbnail-xxl .right-part .thumbnail-content,
  .layout-fondation .thumbnail-xxl .right-part .thumbnail-content {
    padding: 20px;
  }
}
/* line 1639, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title {
  text-transform: uppercase;
}
/* line 1641, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg,
.page-theming .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg {
  margin: 4px 10px 0 0;
  display: inline-block;
  width: 26px;
}
/* line 1645, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg + .thumbnail-title-wrapper,
.page-theming .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg + .thumbnail-title-wrapper,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title svg + .thumbnail-title-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
}
/* line 1651, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper,
.page-theming .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper {
  display: flex;
  align-items: flex-start;
}
/* line 1656, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl .right-part .thumbnail-content .thumbnail-text,
.page-theming .thumbnail-xxl .right-part .thumbnail-content .thumbnail-text,
.layout-fondation .thumbnail-xxl .right-part .thumbnail-content .thumbnail-text {
  margin-bottom: 50px;
}
/* line 1664, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title {
  border-bottom-color: #163375;
}
/* line 1666, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title svg,
.page-theming .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title svg,
.layout-fondation .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-title svg {
  fill: #163375;
}
/* line 1671, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail-xxl:nth-of-type(even) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link {
  color: #163375;
}
/* line 1681, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title {
  border-bottom-color: #EA670A;
}
/* line 1683, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title svg,
.page-theming .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title svg,
.layout-fondation .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-title svg {
  fill: #EA670A;
}
/* line 1688, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail-xxl:nth-of-type(odd) .right-part .thumbnail-content .thumbnail-btn .thumbnail-link {
  color: #EA670A;
}
/* line 1696, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-right,
.page-theming .thumbnail-right,
.layout-fondation .thumbnail-right {
  flex-direction: row-reverse;
}
/* line 1699, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xs,
.page-theming .thumbnail-xs,
.layout-fondation .thumbnail-xs {
  width: 30%;
  display: inline-flex;
  flex-flow: column nowrap;
  vertical-align: text-bottom;
}
/* line 1706, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-xs .right-part .thumbnail-content,
.page-theming .thumbnail-xs .right-part .thumbnail-content,
.layout-fondation .thumbnail-xs .right-part .thumbnail-content {
  padding: 15px 20px 35px 20px;
}
/* line 1712, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-img .left-part,
.page-theming .thumbnail-with-img .left-part,
.layout-fondation .thumbnail-with-img .left-part {
  height: 160px;
}
/* line 1715, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-img .right-part,
.page-theming .thumbnail-with-img .right-part,
.layout-fondation .thumbnail-with-img .right-part {
  position: static;
}
/* line 1720, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-video .left-part,
.page-theming .thumbnail-with-video .left-part,
.layout-fondation .thumbnail-with-video .left-part {
  background-color: black;
  height: 60%;
}
/* line 1723, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-video .left-part video,
.page-theming .thumbnail-with-video .left-part video,
.layout-fondation .thumbnail-with-video .left-part video {
  width: 100%;
}
/* line 1726, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-video .left-part iframe,
.page-theming .thumbnail-with-video .left-part iframe,
.layout-fondation .thumbnail-with-video .left-part iframe {
  width: 100% !important;
  height: 100% !important;
}
/* line 1731, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-with-video .right-part,
.page-theming .thumbnail-with-video .right-part,
.layout-fondation .thumbnail-with-video .right-part {
  top: 60%;
  height: 40%;
}
/* line 1736, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg,
.page-theming .thumbnail-bg,
.layout-fondation .thumbnail-bg {
  position: relative;
}
/* line 1738, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .left-part,
.page-theming .thumbnail-bg .left-part,
.layout-fondation .thumbnail-bg .left-part {
  height: 395px;
}
/* line 1741, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part,
.page-theming .thumbnail-bg .right-part,
.layout-fondation .thumbnail-bg .right-part {
  background-color: transparent;
}
/* line 1743, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part .thumbnail-content,
.page-theming .thumbnail-bg .right-part .thumbnail-content,
.layout-fondation .thumbnail-bg .right-part .thumbnail-content {
  background-color: transparent;
  padding: 50px;
}
/* line 1746, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-bg .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-bg .right-part .thumbnail-content .thumbnail-title {
  border-bottom: none;
  text-transform: uppercase;
  text-align: center;
}
/* line 1750, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper,
.page-theming .thumbnail-bg .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper,
.layout-fondation .thumbnail-bg .right-part .thumbnail-content .thumbnail-title .thumbnail-title-wrapper {
  color: white;
}
/* line 1754, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn,
.page-theming .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn,
.layout-fondation .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
/* line 1765, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.page-theming .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn .thumbnail-link,
.layout-fondation .thumbnail-bg .right-part .thumbnail-content .thumbnail-btn .thumbnail-link {
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #EA670A;
  background-color: #EA670A;
  color: white;
}
/* line 1776, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project,
.page-theming .thumbnail-project,
.layout-fondation .thumbnail-project {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: auto;
  margin-bottom: 30px;
}
/* line 1784, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project:last-of-type,
.page-theming .thumbnail-project:last-of-type,
.layout-fondation .thumbnail-project:last-of-type {
  margin-bottom: 0;
}
/* line 1787, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .left-part,
.page-theming .thumbnail-project .left-part,
.layout-fondation .thumbnail-project .left-part {
  order: 1;
  flex-shrink: 0;
  width: 100%;
  background-color: white;
  text-align: center;
}
/* line 1793, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .left-part img,
.page-theming .thumbnail-project .left-part img,
.layout-fondation .thumbnail-project .left-part img {
  max-width: 200px;
}
/* line 1797, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part,
.page-theming .thumbnail-project .right-part,
.layout-fondation .thumbnail-project .right-part {
  position: relative;
  width: 100%;
  height: auto;
}
/* line 1801, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content,
.page-theming .thumbnail-project .right-part .thumbnail-content,
.layout-fondation .thumbnail-project .right-part .thumbnail-content {
  padding: 30px 30px 0 30px;
}
@media only screen and (max-width: 48em) {
  /* line 1801, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content,
  .page-theming .thumbnail-project .right-part .thumbnail-content,
  .layout-fondation .thumbnail-project .right-part .thumbnail-content {
    padding: 20px 20px 0 20px;
  }
}
/* line 1806, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-project .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-project .right-part .thumbnail-content .thumbnail-title {
  color: #3855a0 !important;
  border-bottom: none;
  padding-bottom: 10px;
  margin-bottom: 0;
}
/* line 1811, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content .thumbnail-title *,
.page-theming .thumbnail-project .right-part .thumbnail-content .thumbnail-title *,
.layout-fondation .thumbnail-project .right-part .thumbnail-content .thumbnail-title * {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
}
/* line 1816, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content .thumbnail-text,
.page-theming .thumbnail-project .right-part .thumbnail-content .thumbnail-text,
.layout-fondation .thumbnail-project .right-part .thumbnail-content .thumbnail-text {
  color: black;
  margin-bottom: 40px;
}
/* line 1820, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-project .right-part .thumbnail-content .thumbnail-btn,
.page-theming .thumbnail-project .right-part .thumbnail-content .thumbnail-btn,
.layout-fondation .thumbnail-project .right-part .thumbnail-content .thumbnail-btn {
  position: relative;
}
/* line 1826, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects,
.page-theming .thumbnail-list-projects,
.layout-fondation .thumbnail-list-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: auto;
  margin-bottom: 30px;
}
/* line 1833, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects:last-of-type,
.page-theming .thumbnail-list-projects:last-of-type,
.layout-fondation .thumbnail-list-projects:last-of-type {
  margin-bottom: 0;
}
/* line 1836, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .left-part,
.page-theming .thumbnail-list-projects .left-part,
.layout-fondation .thumbnail-list-projects .left-part {
  width: 35%;
  background-size: contain;
  background-position: center;
}
@media only screen and (max-width: 48em) {
  /* line 1836, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-list-projects .left-part,
  .page-theming .thumbnail-list-projects .left-part,
  .layout-fondation .thumbnail-list-projects .left-part {
    width: 100%;
    height: 200px;
  }
}
/* line 1845, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .right-part,
.page-theming .thumbnail-list-projects .right-part,
.layout-fondation .thumbnail-list-projects .right-part {
  position: relative;
  width: 65%;
  height: auto;
  background-color: transparent;
}
@media only screen and (max-width: 48em) {
  /* line 1845, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-list-projects .right-part,
  .page-theming .thumbnail-list-projects .right-part,
  .layout-fondation .thumbnail-list-projects .right-part {
    width: 100%;
  }
}
/* line 1853, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .right-part .thumbnail-content,
.page-theming .thumbnail-list-projects .right-part .thumbnail-content,
.layout-fondation .thumbnail-list-projects .right-part .thumbnail-content {
  padding: 0 20px;
  background-color: transparent;
}
@media only screen and (max-width: 48em) {
  /* line 1853, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .thumbnail-list-projects .right-part .thumbnail-content,
  .page-theming .thumbnail-list-projects .right-part .thumbnail-content,
  .layout-fondation .thumbnail-list-projects .right-part .thumbnail-content {
    padding: 0 10px;
  }
}
/* line 1859, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title,
.page-theming .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title,
.layout-fondation .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title {
  color: #3855a0 !important;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
/* line 1863, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title *,
.page-theming .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title *,
.layout-fondation .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-title * {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
}
/* line 1868, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-text,
.page-theming .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-text,
.layout-fondation .thumbnail-list-projects .right-part .thumbnail-content .thumbnail-text {
  margin-bottom: 20px;
  white-space: normal;
}
/* line 1879, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container,
.page-theming .triple-slider-container,
.layout-fondation .triple-slider-container {
  background-color: #ebf1f3;
}
@media only screen and (max-width: 64em) {
  /* line 1879, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container,
  .page-theming .triple-slider-container,
  .layout-fondation .triple-slider-container {
    padding-top: 20px;
  }
}
/* line 1884, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .triple-slider,
.page-theming .triple-slider-container .triple-slider,
.layout-fondation .triple-slider-container .triple-slider {
  background-color: #ebf1f3;
}
/* line 1887, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .slickslider,
.page-theming .triple-slider-container .slickslider,
.layout-fondation .triple-slider-container .slickslider {
  width: 92%;
  margin: auto;
  user-select: text;
}
@media only screen and (max-width: 64em) {
  /* line 1887, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .slickslider,
  .page-theming .triple-slider-container .slickslider,
  .layout-fondation .triple-slider-container .slickslider {
    width: 100%;
  }
  /* line 1893, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .slickslider .thumbnail,
  .page-theming .triple-slider-container .slickslider .thumbnail,
  .layout-fondation .triple-slider-container .slickslider .thumbnail {
    width: 100%;
  }
}
/* line 1897, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .slickslider .slick-prev,
.page-theming .triple-slider-container .slickslider .slick-prev,
.layout-fondation .triple-slider-container .slickslider .slick-prev {
  left: -30px;
}
/* line 1900, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .slickslider .slick-next,
.page-theming .triple-slider-container .slickslider .slick-next,
.layout-fondation .triple-slider-container .slickslider .slick-next {
  right: -30px;
}
/* line 1903, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide,
.page-theming .triple-slider-container .slickslider .slick-slide,
.layout-fondation .triple-slider-container .slickslider .slick-slide {
  padding: 0 10px;
}
@media only screen and (max-width: 64em) {
  /* line 1903, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide,
  .page-theming .triple-slider-container .slickslider .slick-slide,
  .layout-fondation .triple-slider-container .slickslider .slick-slide {
    width: 100%;
    margin-bottom: 20px;
    display: none;
  }
  /* line 1909, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type, [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide, [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide {
    display: block;
    position: relative;
    z-index: 1;
  }
  /* line 1917, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type .thumbnail .right-part .thumbnail-content, [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide .thumbnail .right-part .thumbnail-content, [class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide .thumbnail .right-part .thumbnail-content,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type .thumbnail .right-part .thumbnail-content,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide .thumbnail .right-part .thumbnail-content,
  .page-theming .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide .thumbnail .right-part .thumbnail-content,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type .thumbnail .right-part .thumbnail-content,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide .thumbnail .right-part .thumbnail-content,
  .layout-fondation .triple-slider-container .slickslider .slick-slide:first-of-type + .slick-slide + .slick-slide .thumbnail .right-part .thumbnail-content {
    background-color: transparent;
  }
}
/* line 1924, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .slickslider .slick-slide .thumbnail,
.page-theming .triple-slider-container .slickslider .slick-slide .thumbnail,
.layout-fondation .triple-slider-container .slickslider .slick-slide .thumbnail {
  width: 100%;
  margin-bottom: 0;
  background-color: white;
}
/* line 1931, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .see-all-actu-link,
.page-theming .triple-slider-container .see-all-actu-link,
.layout-fondation .triple-slider-container .see-all-actu-link {
  bottom: 0;
  margin: 40px auto;
}
@media only screen and (max-width: 64em) {
  /* line 1931, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .triple-slider-container .see-all-actu-link,
  .page-theming .triple-slider-container .see-all-actu-link,
  .layout-fondation .triple-slider-container .see-all-actu-link {
    bottom: 0;
  }
}
/* line 1937, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .see-all-actu-link a,
.page-theming .triple-slider-container .see-all-actu-link a,
.layout-fondation .triple-slider-container .see-all-actu-link a {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  text-transform: uppercase;
  margin-top: 10px;
}
/* line 1944, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .see-all-actu-link.orange a,
.page-theming .triple-slider-container .see-all-actu-link.orange a,
.layout-fondation .triple-slider-container .see-all-actu-link.orange a {
  border: 1px solid #163375;
  background-color: #163375;
}
/* line 1947, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .triple-slider-container .see-all-actu-link.orange a:focus, [class*="node-type-fondation"] .triple-slider-container .see-all-actu-link.orange a:hover,
.page-theming .triple-slider-container .see-all-actu-link.orange a:focus,
.page-theming .triple-slider-container .see-all-actu-link.orange a:hover,
.layout-fondation .triple-slider-container .see-all-actu-link.orange a:focus,
.layout-fondation .triple-slider-container .see-all-actu-link.orange a:hover {
  background-color: white;
  color: #163375;
}
/* line 1961, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .pager a,
.page-theming .pager a,
.layout-fondation .pager a {
  color: #3855a0;
  margin-top: -4px;
}
/* line 1970, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .pager li.pager-first a, [class*="node-type-fondation"] .pager li.pager-previous a, [class*="node-type-fondation"] .pager li.pager-next a, [class*="node-type-fondation"] .pager li.pager-last a,
.page-theming .pager li.pager-first a,
.page-theming .pager li.pager-previous a,
.page-theming .pager li.pager-next a,
.page-theming .pager li.pager-last a,
.layout-fondation .pager li.pager-first a,
.layout-fondation .pager li.pager-previous a,
.layout-fondation .pager li.pager-next a,
.layout-fondation .pager li.pager-last a {
  color: #EA670A;
}
/* line 1974, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .pager li.pager-current,
.page-theming .pager li.pager-current,
.layout-fondation .pager li.pager-current {
  background-color: #EA670A;
}
/* line 1983, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list,
.page-theming .bk-filters-list,
.layout-fondation .bk-filters-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25em;
}
@media only screen and (max-width: 64em) {
  /* line 1983, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-filters-list,
  .page-theming .bk-filters-list,
  .layout-fondation .bk-filters-list {
    padding: 0 20px;
    flex-wrap: wrap;
  }
}
/* line 1993, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .filters-list-title,
.page-theming .bk-filters-list .filters-list-title,
.layout-fondation .bk-filters-list .filters-list-title {
  color: #3855a0;
}
@media only screen and (max-width: 48em) {
  /* line 1993, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-filters-list .filters-list-title,
  .page-theming .bk-filters-list .filters-list-title,
  .layout-fondation .bk-filters-list .filters-list-title {
    display: block;
    margin-bottom: 20px;
  }
}
/* line 2000, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .select-wrapper,
.page-theming .bk-filters-list .select-wrapper,
.layout-fondation .bk-filters-list .select-wrapper {
  margin-left: 20px;
  display: inline-block;
}
@media only screen and (max-width: 48em) {
  /* line 2000, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-filters-list .select-wrapper,
  .page-theming .bk-filters-list .select-wrapper,
  .layout-fondation .bk-filters-list .select-wrapper {
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
}
/* line 2008, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .select-wrapper .form-item,
.page-theming .bk-filters-list .select-wrapper .form-item,
.layout-fondation .bk-filters-list .select-wrapper .form-item {
  margin: 0;
}
/* line 2011, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .select-wrapper .styled-select,
.page-theming .bk-filters-list .select-wrapper .styled-select,
.layout-fondation .bk-filters-list .select-wrapper .styled-select {
  border: 1px solid #a0a0a0;
  padding: 5px 10px;
  border-radius: 30px;
}
/* line 2015, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .select-wrapper .styled-select:before,
.page-theming .bk-filters-list .select-wrapper .styled-select:before,
.layout-fondation .bk-filters-list .select-wrapper .styled-select:before {
  color: #a0a0a0;
  margin-top: -0.7em;
}
@media only screen and (max-width: 64em) {
  /* line 2015, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-filters-list .select-wrapper .styled-select:before,
  .page-theming .bk-filters-list .select-wrapper .styled-select:before,
  .layout-fondation .bk-filters-list .select-wrapper .styled-select:before {
    font-size: 16px;
  }
}
/* line 2022, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-filters-list .select-wrapper .styled-select select,
.page-theming .bk-filters-list .select-wrapper .styled-select select,
.layout-fondation .bk-filters-list .select-wrapper .styled-select select {
  color: #a0a0a0;
  appearance: none;
}
@media only screen and (max-width: 64em) {
  /* line 2022, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-filters-list .select-wrapper .styled-select select,
  .page-theming .bk-filters-list .select-wrapper .styled-select select,
  .layout-fondation .bk-filters-list .select-wrapper .styled-select select {
    padding: 0;
    font-size: 20px;
  }
}
/* line 2041, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .grid-list .thumbnail,
.page-theming .all-actu .grid-list .thumbnail,
.layout-fondation .all-actu .grid-list .thumbnail {
  height: 500px;
}
/* line 2045, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .grid-list .thumbnail .right-part .thumbnail-media img,
.page-theming .all-actu .grid-list .thumbnail .right-part .thumbnail-media img,
.layout-fondation .all-actu .grid-list .thumbnail .right-part .thumbnail-media img {
  max-height: none;
  width: 100%;
}
@media only screen and (max-width: 64em) {
  /* line 2045, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .all-actu .grid-list .thumbnail .right-part .thumbnail-media img,
  .page-theming .all-actu .grid-list .thumbnail .right-part .thumbnail-media img,
  .layout-fondation .all-actu .grid-list .thumbnail .right-part .thumbnail-media img {
    width: auto;
    max-height: 100%;
  }
}
/* line 2057, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .all-actu-list,
.page-theming .all-actu .all-actu-list,
.layout-fondation .all-actu .all-actu-list {
  padding: 0;
}
/* line 2059, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .all-actu-list .thumbnail,
.page-theming .all-actu .all-actu-list .thumbnail,
.layout-fondation .all-actu .all-actu-list .thumbnail {
  width: 100%;
  height: auto;
  min-height: auto;
}
/* line 2063, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .all-actu-list .thumbnail .right-part,
.page-theming .all-actu .all-actu-list .thumbnail .right-part,
.layout-fondation .all-actu .all-actu-list .thumbnail .right-part {
  position: relative;
  top: 0;
}
/* line 2066, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .all-actu .all-actu-list .thumbnail .right-part .thumbnail-btn,
.page-theming .all-actu .all-actu-list .thumbnail .right-part .thumbnail-btn,
.layout-fondation .all-actu .all-actu-list .thumbnail .right-part .thumbnail-btn {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
}
/* line 2080, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg,
.page-theming .wysiwyg,
.layout-fondation .wysiwyg {
  font-family: Arial, helvetica, sans-serif;
  font-size: 1em;
  color: #3855a0;
  margin-bottom: 40px;
}
/* line 2085, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg [class^="icon-"],
.page-theming .wysiwyg [class^="icon-"],
.layout-fondation .wysiwyg [class^="icon-"] {
  color: #EA670A;
  display: inline-block;
  margin-right: 10px;
  font-size: 24px;
  vertical-align: text-bottom;
}
/* line 2092, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg .link-docs,
.page-theming .wysiwyg .link-docs,
.layout-fondation .wysiwyg .link-docs {
  text-decoration: none;
  word-break: break-all;
}
/* line 2096, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg .text-intro,
.page-theming .wysiwyg .text-intro,
.layout-fondation .wysiwyg .text-intro {
  text-align: left;
}
/* line 2100, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg-and-img,
.page-theming .wysiwyg-and-img,
.layout-fondation .wysiwyg-and-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* line 2104, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg-and-img .wysiwyg,
.page-theming .wysiwyg-and-img .wysiwyg,
.layout-fondation .wysiwyg-and-img .wysiwyg {
  margin-bottom: 0;
}
/* line 2107, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .wysiwyg-and-img img,
.page-theming .wysiwyg-and-img img,
.layout-fondation .wysiwyg-and-img img {
  margin-left: 20px;
}
@media only screen and (max-width: 64em) {
  /* line 2100, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .wysiwyg-and-img,
  .page-theming .wysiwyg-and-img,
  .layout-fondation .wysiwyg-and-img {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 48em) {
  /* line 2100, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .wysiwyg-and-img,
  .page-theming .wysiwyg-and-img,
  .layout-fondation .wysiwyg-and-img {
    flex-wrap: wrap;
  }
  /* line 2115, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .wysiwyg-and-img img,
  .page-theming .wysiwyg-and-img img,
  .layout-fondation .wysiwyg-and-img img {
    margin: auto;
  }
}
/* line 2120, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container,
.page-theming .sommaire-container,
.layout-fondation .sommaire-container {
  margin-bottom: 20px;
}
/* line 2122, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire,
.page-theming .sommaire-container .sommaire,
.layout-fondation .sommaire-container .sommaire {
  color: #3855a0;
  border: none;
}
/* line 2125, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire .sommaire-title,
.page-theming .sommaire-container .sommaire .sommaire-title,
.layout-fondation .sommaire-container .sommaire .sommaire-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875em;
}
/* line 2128, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire .sommaire-title:before,
.page-theming .sommaire-container .sommaire .sommaire-title:before,
.layout-fondation .sommaire-container .sommaire .sommaire-title:before {
  color: #3855a0;
}
/* line 2131, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire .sommaire-title .sommaire-title-text,
.page-theming .sommaire-container .sommaire .sommaire-title .sommaire-title-text,
.layout-fondation .sommaire-container .sommaire .sommaire-title .sommaire-title-text {
  color: #3855a0;
}
/* line 2136, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire .arrow-list li,
.page-theming .sommaire-container .sommaire .arrow-list li,
.layout-fondation .sommaire-container .sommaire .arrow-list li {
  font-size: 1em;
}
/* line 2138, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container .sommaire .arrow-list li:before,
.page-theming .sommaire-container .sommaire .arrow-list li:before,
.layout-fondation .sommaire-container .sommaire .arrow-list li:before {
  color: #3855a0;
}
/* line 2147, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container.pink .sommaire .sommaire-title:before,
.page-theming .sommaire-container.pink .sommaire .sommaire-title:before,
.layout-fondation .sommaire-container.pink .sommaire .sommaire-title:before {
  color: #EA670A;
}
/* line 2153, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .sommaire-container.pink .sommaire .arrow-list li:before,
.page-theming .sommaire-container.pink .sommaire .arrow-list li:before,
.layout-fondation .sommaire-container.pink .sommaire .arrow-list li:before {
  color: #EA670A;
}
/* line 2162, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .video-block,
.page-theming .video-block,
.layout-fondation .video-block {
  width: 80%;
  margin: 0 auto 50px;
}
@media only screen and (max-width: 64em) {
  /* line 2162, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .video-block,
  .page-theming .video-block,
  .layout-fondation .video-block {
    width: 90%;
    margin: 0 auto 30px;
  }
}
/* line 2171, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project,
.page-theming .bk-description-project,
.layout-fondation .bk-description-project {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 2171, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-description-project,
  .page-theming .bk-description-project,
  .layout-fondation .bk-description-project {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 48em) {
  /* line 2171, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-description-project,
  .page-theming .bk-description-project,
  .layout-fondation .bk-description-project {
    width: 100%;
  }
}
/* line 2183, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .left-part,
.page-theming .bk-description-project .left-part,
.layout-fondation .bk-description-project .left-part {
  width: 70%;
}
@media only screen and (max-width: 48em) {
  /* line 2183, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-description-project .left-part,
  .page-theming .bk-description-project .left-part,
  .layout-fondation .bk-description-project .left-part {
    width: 100%;
  }
}
/* line 2188, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .left-part .h1-fondation,
.page-theming .bk-description-project .left-part .h1-fondation,
.layout-fondation .bk-description-project .left-part .h1-fondation {
  font-size: 3em;
  margin-bottom: 25px;
}
/* line 2193, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .left-part .text-intro *:last-child,
.page-theming .bk-description-project .left-part .text-intro *:last-child,
.layout-fondation .bk-description-project .left-part .text-intro *:last-child {
  margin-bottom: 0;
}
/* line 2198, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .right-part,
.page-theming .bk-description-project .right-part,
.layout-fondation .bk-description-project .right-part {
  width: 28%;
}
@media only screen and (max-width: 48em) {
  /* line 2198, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-description-project .right-part,
  .page-theming .bk-description-project .right-part,
  .layout-fondation .bk-description-project .right-part {
    width: 100%;
    margin: auto;
  }
}
/* line 2204, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .right-part .btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-orange, [class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.orange .line-bg-right-btn, #page-garantie.orange [class*="node-type-fondation"] .bk-description-project .right-part .line-bg-right-btn,
[class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange [class*="node-type-fondation"] .bk-description-project .right-part .pdf-dl-btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-green, [class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.green .line-bg-right-btn, #page-garantie.green [class*="node-type-fondation"] .bk-description-project .right-part .line-bg-right-btn,
[class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.green .pdf-dl-btn,
#page-garantie.green [class*="node-type-fondation"] .bk-description-project .right-part .pdf-dl-btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-light-blue, [class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.light-blue .line-bg-right-btn, #page-garantie.light-blue [class*="node-type-fondation"] .bk-description-project .right-part .line-bg-right-btn,
[class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue [class*="node-type-fondation"] .bk-description-project .right-part .pdf-dl-btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-pink, [class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.pink .line-bg-right-btn, #page-garantie.pink [class*="node-type-fondation"] .bk-description-project .right-part .line-bg-right-btn,
[class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink [class*="node-type-fondation"] .bk-description-project .right-part .pdf-dl-btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-purple, [class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.purple .line-bg-right-btn, #page-garantie.purple [class*="node-type-fondation"] .bk-description-project .right-part .line-bg-right-btn,
[class*="node-type-fondation"] .bk-description-project .right-part #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple [class*="node-type-fondation"] .bk-description-project .right-part .pdf-dl-btn, [class*="node-type-fondation"] .bk-description-project .right-part .btn-light-grey, [class*="node-type-fondation"] .bk-description-project .right-part .btn-grey, [class*="node-type-fondation"] .bk-description-project .right-part .btn-light-green, [class*="node-type-fondation"] .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .video-btn, .node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-description-project .right-part .video-btn,
[class*="node-type-fondation"] .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat [class*="node-type-fondation"] .bk-description-project .right-part .project-btn,
.page-theming .bk-description-project .right-part .btn,
.page-theming .bk-description-project .right-part .btn-orange,
.page-theming .bk-description-project .right-part #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .page-theming .bk-description-project .right-part .line-bg-right-btn,
.page-theming .bk-description-project .right-part #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange .page-theming .bk-description-project .right-part .pdf-dl-btn,
.page-theming .bk-description-project .right-part .btn-green,
.page-theming .bk-description-project .right-part #page-garantie.green .line-bg-right-btn,
#page-garantie.green .page-theming .bk-description-project .right-part .line-bg-right-btn,
.page-theming .bk-description-project .right-part #page-garantie.green .pdf-dl-btn,
#page-garantie.green .page-theming .bk-description-project .right-part .pdf-dl-btn,
.page-theming .bk-description-project .right-part .btn-light-blue,
.page-theming .bk-description-project .right-part #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .page-theming .bk-description-project .right-part .line-bg-right-btn,
.page-theming .bk-description-project .right-part #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue .page-theming .bk-description-project .right-part .pdf-dl-btn,
.page-theming .bk-description-project .right-part .btn-pink,
.page-theming .bk-description-project .right-part #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .page-theming .bk-description-project .right-part .line-bg-right-btn,
.page-theming .bk-description-project .right-part #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink .page-theming .bk-description-project .right-part .pdf-dl-btn,
.page-theming .bk-description-project .right-part .btn-purple,
.page-theming .bk-description-project .right-part #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .page-theming .bk-description-project .right-part .line-bg-right-btn,
.page-theming .bk-description-project .right-part #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple .page-theming .bk-description-project .right-part .pdf-dl-btn,
.page-theming .bk-description-project .right-part .btn-light-grey,
.page-theming .bk-description-project .right-part .btn-grey,
.page-theming .bk-description-project .right-part .btn-light-green,
.page-theming .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-description-project .right-part .video-btn,
.page-theming .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .page-theming .bk-description-project .right-part .project-btn,
.layout-fondation .bk-description-project .right-part .btn,
.layout-fondation .bk-description-project .right-part .btn-orange,
.layout-fondation .bk-description-project .right-part #page-garantie.orange .line-bg-right-btn,
#page-garantie.orange .layout-fondation .bk-description-project .right-part .line-bg-right-btn,
.layout-fondation .bk-description-project .right-part #page-garantie.orange .pdf-dl-btn,
#page-garantie.orange .layout-fondation .bk-description-project .right-part .pdf-dl-btn,
.layout-fondation .bk-description-project .right-part .btn-green,
.layout-fondation .bk-description-project .right-part #page-garantie.green .line-bg-right-btn,
#page-garantie.green .layout-fondation .bk-description-project .right-part .line-bg-right-btn,
.layout-fondation .bk-description-project .right-part #page-garantie.green .pdf-dl-btn,
#page-garantie.green .layout-fondation .bk-description-project .right-part .pdf-dl-btn,
.layout-fondation .bk-description-project .right-part .btn-light-blue,
.layout-fondation .bk-description-project .right-part #page-garantie.light-blue .line-bg-right-btn,
#page-garantie.light-blue .layout-fondation .bk-description-project .right-part .line-bg-right-btn,
.layout-fondation .bk-description-project .right-part #page-garantie.light-blue .pdf-dl-btn,
#page-garantie.light-blue .layout-fondation .bk-description-project .right-part .pdf-dl-btn,
.layout-fondation .bk-description-project .right-part .btn-pink,
.layout-fondation .bk-description-project .right-part #page-garantie.pink .line-bg-right-btn,
#page-garantie.pink .layout-fondation .bk-description-project .right-part .line-bg-right-btn,
.layout-fondation .bk-description-project .right-part #page-garantie.pink .pdf-dl-btn,
#page-garantie.pink .layout-fondation .bk-description-project .right-part .pdf-dl-btn,
.layout-fondation .bk-description-project .right-part .btn-purple,
.layout-fondation .bk-description-project .right-part #page-garantie.purple .line-bg-right-btn,
#page-garantie.purple .layout-fondation .bk-description-project .right-part .line-bg-right-btn,
.layout-fondation .bk-description-project .right-part #page-garantie.purple .pdf-dl-btn,
#page-garantie.purple .layout-fondation .bk-description-project .right-part .pdf-dl-btn,
.layout-fondation .bk-description-project .right-part .btn-light-grey,
.layout-fondation .bk-description-project .right-part .btn-grey,
.layout-fondation .bk-description-project .right-part .btn-light-green,
.layout-fondation .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .video-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-description-project .right-part .video-btn,
.layout-fondation .bk-description-project .right-part .node-type-laureate-list #liste-laureats > li.laureat .project-btn,
.node-type-laureate-list #liste-laureats > li.laureat .layout-fondation .bk-description-project .right-part .project-btn {
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
}
/* line 2209, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .right-part .project-infos,
.page-theming .bk-description-project .right-part .project-infos,
.layout-fondation .bk-description-project .right-part .project-infos {
  background-color: #ebf1f3;
  padding: 15px;
}
/* line 2212, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .right-part .project-infos .project-infos-wrapper,
.page-theming .bk-description-project .right-part .project-infos .project-infos-wrapper,
.layout-fondation .bk-description-project .right-part .project-infos .project-infos-wrapper {
  background-color: white;
}
/* line 2214, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-description-project .right-part .project-infos .project-infos-wrapper ul,
.page-theming .bk-description-project .right-part .project-infos .project-infos-wrapper ul,
.layout-fondation .bk-description-project .right-part .project-infos .project-infos-wrapper ul {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1em;
  margin: 0;
  padding: 10px 20px;
  color: #3855a0;
}
/* line 2229, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-detail-ressource,
.page-theming .bk-detail-ressource,
.layout-fondation .bk-detail-ressource {
  margin-top: 40px;
}
@media only screen and (max-width: 64em) {
  /* line 2229, ../sass/_layout-fondation.scss */
  [class*="node-type-fondation"] .bk-detail-ressource,
  .page-theming .bk-detail-ressource,
  .layout-fondation .bk-detail-ressource {
    padding: 0 10px;
  }
}
/* line 2234, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-detail-ressource .detail-ressource-title,
.page-theming .bk-detail-ressource .detail-ressource-title,
.layout-fondation .bk-detail-ressource .detail-ressource-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.5em;
  text-transform: uppercase;
  text-align: left;
  color: #163375;
  margin-bottom: 5px;
}
/* line 2242, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-detail-ressource .detail-ressource-author,
.page-theming .bk-detail-ressource .detail-ressource-author,
.layout-fondation .bk-detail-ressource .detail-ressource-author {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  display: block;
  color: #3855a0;
  margin-bottom: 20px;
}
/* line 2249, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .bk-detail-ressource .detail-ressource-img,
.page-theming .bk-detail-ressource .detail-ressource-img,
.layout-fondation .bk-detail-ressource .detail-ressource-img {
  text-align: center;
  margin-bottom: 20px;
}
/* line 2258, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block,
.page-theming .notation-block,
.layout-fondation .notation-block {
  border-top: 1px solid #3855a0;
}
/* line 2260, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .mark-block,
.page-theming .notation-block .mark-block,
.layout-fondation .notation-block .mark-block {
  color: #3855a0;
}
/* line 2262, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .mark-block .item-list,
.page-theming .notation-block .mark-block .item-list,
.layout-fondation .notation-block .mark-block .item-list {
  border: 1px solid #3855a0;
}
/* line 2266, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .mark-block .item-list ul li a, [class*="node-type-fondation"] .notation-block .mark-block .item-list ul li span,
.page-theming .notation-block .mark-block .item-list ul li a,
.page-theming .notation-block .mark-block .item-list ul li span,
.layout-fondation .notation-block .mark-block .item-list ul li a,
.layout-fondation .notation-block .mark-block .item-list ul li span {
  background: url("../images/star-empty-blue-dark.png") no-repeat center center;
}
/* line 2268, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .mark-block .item-list ul li a.user-voted, [class*="node-type-fondation"] .notation-block .mark-block .item-list ul li span.user-voted,
.page-theming .notation-block .mark-block .item-list ul li a.user-voted,
.page-theming .notation-block .mark-block .item-list ul li span.user-voted,
.layout-fondation .notation-block .mark-block .item-list ul li a.user-voted,
.layout-fondation .notation-block .mark-block .item-list ul li span.user-voted {
  background: url("../images/star-full-full-blue-dark.png") no-repeat center center #3855a0;
}
/* line 2275, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .mark-block .rate-info,
.page-theming .notation-block .mark-block .rate-info,
.layout-fondation .notation-block .mark-block .rate-info {
  color: #3855a0;
}
/* line 2279, ../sass/_layout-fondation.scss */
[class*="node-type-fondation"] .notation-block .share-block,
.page-theming .notation-block .share-block,
.layout-fondation .notation-block .share-block {
  color: #3855a0;
}

/**************************************
---- RESULT COUNT
**************************************/
/* line 2288, ../sass/_layout-fondation.scss */
.bk-result-count {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.625em;
  margin: 30px auto 10px;
  color: #3855a0;
}
@media only screen and (max-width: 64em) {
  /* line 2288, ../sass/_layout-fondation.scss */
  .bk-result-count {
    margin: 10px auto;
  }
}
/* line 2296, ../sass/_layout-fondation.scss */
.bk-result-count .result-count-number {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 30px;
}

/**************************************
---- DATATABLES
**************************************/
/* line 2307, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 64em) {
  /* line 2307, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable {
    margin-bottom: 0;
  }
}
/* line 2313, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead {
  background-color: #ebf1f3;
}
/* line 2316, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead tr th {
  padding: 40px 10px 20px 40px;
  border: none;
  position: relative;
  color: #3855a0;
  min-height: auto;
}
@media only screen and (max-width: 64em) {
  /* line 2316, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable thead tr th {
    padding: 20px 10px 20px 10px;
  }
}
/* line 2325, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead tr th.region, .bk-projects-list-table.dataTable thead tr th.annee {
  padding: 40px 30px 20px 10px;
  width: 100px;
}
@media only screen and (max-width: 64em) {
  /* line 2325, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable thead tr th.region, .bk-projects-list-table.dataTable thead tr th.annee {
    padding: 20px 10px 20px 10px;
  }
}
/* line 2335, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting {
  background-image: none !important;
}
/* line 2337, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting:before {
  content: "\f077";
  font-family: icomoon;
  font-size: 10px;
  right: 10px;
  position: absolute;
  top: calc(50% - 6px);
}
@media only screen and (max-width: 64em) {
  /* line 2337, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable thead .sorting:before {
    top: calc(50% - 16px);
  }
}
/* line 2348, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting:after {
  content: "\f078";
  font-family: icomoon;
  font-size: 10px;
  right: 10px;
  position: absolute;
  top: calc(50% + 2px);
}
@media only screen and (max-width: 64em) {
  /* line 2348, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable thead .sorting:after {
    top: calc(50% - 8px);
  }
}
/* line 2360, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting_asc {
  background-image: none !important;
}
/* line 2362, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting_asc:after {
  content: "\f077";
  font-family: icomoon;
  font-size: 10px;
  right: 10px;
  position: absolute;
  top: calc(50% - 2px);
}
/* line 2371, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting_desc {
  background-image: none !important;
}
/* line 2373, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable thead .sorting_desc:after {
  content: "\f078";
  font-family: icomoon;
  font-size: 10px;
  right: 10px;
  position: absolute;
  top: calc(50% - 2px);
}
/* line 2385, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr {
  padding: 0;
}
/* line 2387, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd {
  background-color: #ebf1f3;
}
/* line 2390, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd td.table-thumbnail {
  min-height: auto;
}
/* line 2392, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd td.table-thumbnail:before {
  background-color: #E40070 !important;
}
/* line 2396, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd td.table-thumbnail .thumbnail-content .thumbnail-title {
  border-bottom-color: #E40070;
}
/* line 2400, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd td.table-thumbnail .thumbnail-content .thumbnail-options .thumbnail-options-value {
  color: #E40070;
}
/* line 2407, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd + .child {
  background-color: #f9f9f9;
}
/* line 2409, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr.odd + .child .dtr-data {
  color: #E40070 !important;
}
/* line 2416, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr:first-of-type td.table-thumbnail {
  padding-top: 20px;
}
/* line 2421, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td {
  padding: 0;
}
/* line 2423, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail {
  padding: 40px;
}
@media only screen and (max-width: 64em) {
  /* line 2423, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable tbody tr td.table-thumbnail {
    padding: 20px 10px;
  }
}
/* line 2428, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail:before {
  background-color: #EA670A !important;
}
/* line 2431, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail .right-part {
  max-width: 500px;
}
@media only screen and (max-width: 64em) {
  /* line 2434, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable tbody tr td.table-thumbnail .left-part {
    background-size: cover;
  }
}
/* line 2439, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail .thumbnail-content {
  padding: 0 10px;
}
@media only screen and (max-width: 48em) {
  /* line 2439, ../sass/_layout-fondation.scss */
  .bk-projects-list-table.dataTable tbody tr td.table-thumbnail .thumbnail-content {
    padding: 10px 0;
  }
}
/* line 2444, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail .thumbnail-content .thumbnail-title {
  border-bottom-color: #EA670A;
}
/* line 2448, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.table-thumbnail .thumbnail-content .thumbnail-options .thumbnail-options-value {
  color: #EA670A;
}
/* line 2454, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr td.region, .bk-projects-list-table.dataTable tbody tr td.annee {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  color: #3855a0;
  padding: 20px 10px;
}
/* line 2462, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child {
  border: 0;
}
/* line 2464, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child .dtr-details {
  padding-top: 0 !important;
  margin: 0 !important;
}
/* line 2467, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child .dtr-details li {
  border-bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
/* line 2471, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child .dtr-details li .dtr-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1em;
  color: #3855a0;
  min-width: auto;
}
/* line 2476, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child .dtr-details li .dtr-title:after {
  content: ':';
  display: inline-block;
  margin: 0 2px;
}
/* line 2482, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable tbody tr .child .dtr-details li .dtr-data {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
  color: #EA670A;
}
/* line 2493, ../sass/_layout-fondation.scss */
.bk-projects-list-table.dataTable.no-footer {
  border-bottom: none;
}

/* line 2499, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_info {
  opacity: 0;
  position: absolute;
  top: 0;
}
/* line 2504, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate {
  float: none;
  text-align: center;
}
/* line 2509, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  color: #E40070 !important;
  font-size: 28px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
}
/* line 2516, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next:focus {
  border: 1px solid #E40070;
  background: #E40070;
  color: white !important;
}
/* line 2524, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #3855a0 !important;
  border-radius: 0;
  padding: 4px 0;
  width: 32px;
  height: 32px;
  min-height: auto;
  min-width: auto;
  margin-left: 10px;
}
/* line 2533, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button:first-of-type {
  margin-left: 0;
}
/* line 2536, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: 1px solid #E40070;
  background: #E40070;
  color: white !important;
}
/* line 2545, ../sass/_layout-fondation.scss */
.dataTables_wrapper .dataTables_paginate .ellipsis {
  color: #3855a0 !important;
}

/* line 2550, ../sass/_layout-fondation.scss */
.parent:before, .parent-mobile:before,
.parent:after, .parent-mobile:after {
  content: none;
}

/* line 2554, ../sass/_layout-fondation.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  padding-left: 10px;
  min-width: 300px;
}

/* line 2559, ../sass/_layout-fondation.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: auto;
  bottom: 8px;
  left: 10px;
}

/* line 2565, ../sass/_layout-fondation.scss */
table.dataTable > tbody > tr.child:hover {
  background: white !important;
}

/* line 2568, ../sass/_layout-fondation.scss */
table.dataTable > tbody > tr.child ul.dtr-details {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* line 2573, ../sass/_layout-fondation.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  border-bottom: 1px solid #ccc;
}

/*
 * Layout for Extranet pages
 */
/**************************************
---- NON CONNECTER
**************************************/
/* line 9, ../sass/_layout-extranet.scss */
.not-logged-in.page-theming-extranet,
.not-logged-in .layout-extranet {
  padding: 0px !important;
}
/* line 14, ../sass/_layout-extranet.scss */
.not-logged-in .block-system {
  margin-bottom: 0px;
}

/**************************************
---- HEADER EXTRANET PAGES
**************************************/
/* line 24, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .main-nav-list > li {
  margin-top: 30px;
}
@media only screen and (max-width: 64em) {
  /* line 24, ../sass/_layout-extranet.scss */
  .page-theming-extranet .page-header-wrapper .main-nav-list > li {
    margin-top: 0px;
  }
}
/* line 30, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx {
  text-align: right;
  border-left: none;
}
@media only screen and (max-width: 64em) {
  /* line 30, ../sass/_layout-extranet.scss */
  .page-theming-extranet .page-header-wrapper .bk-infos-cnx {
    padding: 20px;
  }
}
/* line 36, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx .infos-user-name {
  font-family: Arial;
  font-size: 0.875em;
  font-weight: 400;
  color: #3855a0;
  display: block;
  margin-bottom: 10px;
}
/* line 44, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx .infos-user-actions-list {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
/* line 51, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx .infos-user-actions-list .infos-user-actions-item {
  margin: 0px;
  padding: 0px;
  line-height: 1;
}
/* line 55, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx .infos-user-actions-list .infos-user-actions-item + .infos-user-actions-item {
  border-left: 1px solid #3855a0;
  margin-left: 8px;
  padding-left: 8px;
}
/* line 60, ../sass/_layout-extranet.scss */
.page-theming-extranet .page-header-wrapper .bk-infos-cnx .infos-user-actions-list .infos-user-actions-item a {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  color: #3855a0;
  text-decoration: underline;
  line-height: 1;
}

/* line 75, ../sass/_layout-extranet.scss */
.page-theming-extranet {
  padding: 100px 10px 0px 10px;
}
/* line 77, ../sass/_layout-extranet.scss */
.page-theming-extranet[class*="section-"] {
  padding: 0px 10px 0px 10px;
}

/* line 81, ../sass/_layout-extranet.scss */
.layout-extranet {
  padding: 30px 10px 0px 10px;
  /**************************************
  ---- TITLES
  **************************************/
  /**************************************
  ---- WYSIWYG
  **************************************/
  /**************************************
  ---- FORMULAIRE
  **************************************/
  /**************************************
  ---- TOTAL RESULTS
  **************************************/
  /**************************************
  ---- BLOCK CATEGORY
  **************************************/
  /**************************************
  ---- BLOCK DOCUMENT
  **************************************/
  /**************************************
  ---- SHOW MORE
  **************************************/
  /**************************************
  ---- BK GRADIENT
  **************************************/
  /**************************************
  ---- POPIN
  **************************************/
  /**************************************
  ---- LISTE LINKS
  **************************************/
  /**************************************
  ---- BTN LIKE INPUT SUBMIT
  **************************************/
}
@media only screen and (max-width: 64em) {
  /* line 81, ../sass/_layout-extranet.scss */
  .layout-extranet {
    padding: 20px 10px 0px 10px;
  }
}
/* line 91, ../sass/_layout-extranet.scss */
.layout-extranet .h1-fondation {
  text-align: center;
}
/* line 99, ../sass/_layout-extranet.scss */
.layout-extranet .wysiwyg {
  font-family: Arial, helvetica, sans-serif;
  font-size: 1em;
  color: #3855a0;
  margin-bottom: 40px;
}
/* line 104, ../sass/_layout-extranet.scss */
.layout-extranet .wysiwyg p {
  padding-left: 0px;
  padding-right: 0px;
}
/* line 115, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form {
  width: 100%;
}
/* line 117, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset {
  margin: 0px 0px 50px 0px;
}
/* line 119, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset legend {
  background-color: #f3f2f1;
  font-family: Arial;
  font-size: 1em;
  font-weight: 400;
  color: black;
  width: 100%;
  margin: 0px 0px 30px 0px;
  padding: 10px;
}
/* line 129, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}
/* line 134, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line.form-actions {
  justify-content: center;
}
/* line 137, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-actions {
  margin-bottom: 0px;
}
/* line 140, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field {
  display: inline-block;
  margin-bottom: 30px;
}
/* line 143, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-item {
  margin-top: 0px;
  margin-bottom: 0px;
}
/* line 146, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-item label:not(.form-field-label) {
  display: none;
}
/* line 149, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-item .error {
  border: 2px solid #900 !important;
}
/* line 153, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-label {
  font-family: Arial;
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin-bottom: 5px;
}
/* line 160, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-label--big {
  width: 200%;
  max-width: 100%;
}
/* line 166, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select {
  border: 1px solid grey;
  display: block;
  padding: 8px 10px;
  position: relative;
}
/* line 171, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select:before {
  content: '';
  position: absolute;
  top: 8px;
  right: 10px;
  margin: 0px;
  padding: 0;
  width: 0px;
  height: 0px;
  border-top: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid grey;
}
/* line 185, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select:after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: 8px;
  margin: 0px;
  padding: 0;
  width: 0px;
  height: 0px;
  border-top: 10px solid grey;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: none;
}
/* line 199, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select select {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  color: black;
  border: none;
  width: 100%;
  padding: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  position: relative;
  z-index: 2;
}
/* line 212, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select select::-ms-expand {
  display: none;
}
/* line 217, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-input {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  color: black;
  border: 1px solid grey;
  width: 100%;
  height: 44px;
  padding: 10px;
}
/* line 225, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field .form-field-input[disabled=disabled] {
  background-color: #f3f2f1;
}
/* line 230, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field--xs {
  width: 10%;
}
/* line 233, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field--s {
  width: 40%;
}
/* line 236, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field--m {
  width: 47%;
}
/* line 239, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field--l {
  width: 100%;
}
@media only screen and (max-width: 37.5em) {
  /* line 140, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field {
    width: 100%;
  }
}
/* line 246, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .captcha {
  margin: 0px auto 30px;
}
/* line 249, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field-btn {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  width: 220px;
  margin: 0px;
}
/* line 257, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field-btn + .form-field-btn {
  margin-left: 6%;
}
@media only screen and (max-width: 37.5em) {
  /* line 249, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .form-field-btn {
    width: 140px;
    margin: auto;
  }
}
/* line 265, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire form fieldset .formulaire-line .messages-error {
  color: #900;
  margin: 0px auto 30px;
}
/* line 273, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters {
  background-color: #f3f2f1;
  padding: 20px 40px;
}
@media only screen and (max-width: 37.5em) {
  /* line 273, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters {
    padding: 20px;
  }
}
/* line 280, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset {
  margin-bottom: 0px;
}
/* line 282, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset legend {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.25em;
  font-weight: 400;
  background-color: transparent;
  color: #3855a0;
  padding: 0px;
}
/* line 291, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  margin: 0px 0px 30px 10%;
}
/* line 298, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field--big {
  width: 75%;
}
@media only screen and (max-width: 64em) {
  /* line 291, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field {
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
}
@media only screen and (max-width: 37.5em) {
  /* line 291, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field {
    flex-flow: row wrap;
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
}
/* line 311, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 37.5em) {
  /* line 311, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-item {
    flex-flow: row wrap;
  }
}
/* line 321, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-item + .form-sub-category {
  width: 75%;
  margin-left: 20px;
}
@media only screen and (max-width: 37.5em) {
  /* line 321, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-item + .form-sub-category {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
}
/* line 331, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-btn {
  margin-left: 20px;
  width: 66px;
}
@media only screen and (max-width: 37.5em) {
  /* line 331, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-btn {
    width: 140px;
    margin: 10px auto 0px;
  }
}
/* line 341, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-label {
  flex-shrink: 0;
  color: #3855a0;
  margin: 0px 25px 0px 0px;
}
@media only screen and (max-width: 37.5em) {
  /* line 341, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-label {
    margin: 0px 0px 5px 0px;
  }
}
/* line 349, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select {
  width: 100%;
}
/* line 351, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select {
  background-color: white;
  border: 1px solid #a0a0a0;
}
/* line 354, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select .form-field-styled-select select {
  color: #a0a0a0;
  text-align-last: center;
}
/* line 359, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select + .form-field {
  width: 100%;
  margin: 0px 0px 0px 40px;
}
@media only screen and (max-width: 37.5em) {
  /* line 359, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select + .form-field {
    margin: 20px 0px 20px 0px;
  }
}
/* line 365, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-select + .form-field .form-field-input {
  padding: 10px 50px 10px 10px;
}
/* line 370, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
}
/* line 377, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 40%;
  top: 30%;
  right: 40px;
  background-color: #a0a0a0;
}
/* line 386, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper [class^="icon-"] {
  font-size: 20px;
  color: #a0a0a0;
  width: 40px;
  text-align: center;
  margin: 0px -40px 0px 0px;
  position: relative;
}
/* line 394, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper .form-field-input {
  padding: 10px 50px 10px 40px;
  border: 1px solid #a0a0a0;
}
/* line 398, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper .form-field-btn {
  width: 40px;
  padding: 12px 0px;
  margin: 0px 0px 0px -40px;
  background-color: transparent;
  color: #3855a0;
  border: none;
}
/* line 405, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper .form-field-btn:focus, .layout-extranet .bk-extranet-formulaire--filters form fieldset .formulaire-line .form-field .form-field-input-wrapper .form-field-btn:hover {
  background-color: #3855a0;
  color: white;
}
/* line 419, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire .messages.error {
  font-family: Arial, helvetica, sans-serif;
  font-size: 1em;
  color: #900;
}
/* line 424, ../sass/_layout-extranet.scss */
.layout-extranet .bk-extranet-formulaire .messages.error::before {
  content: none;
}
/* line 429, ../sass/_layout-extranet.scss */
.layout-extranet ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a0a0;
  text-align: center;
}
/* line 433, ../sass/_layout-extranet.scss */
.layout-extranet :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a0a0;
  text-align: center;
}
/* line 437, ../sass/_layout-extranet.scss */
.layout-extranet ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a0a0;
  text-align: center;
}
/* line 446, ../sass/_layout-extranet.scss */
.layout-extranet .bk-total-results {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.4375em;
  color: #3855a0;
  margin: 40px 0px 20px 0px;
}
/* line 457, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category {
  margin-bottom: 50px;
}
/* line 459, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category.canBeHidden {
  display: none;
}
/* line 462, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category .category-title-wrapper {
  border-bottom: 1px solid #f3f2f1;
  margin: 0px 0px 40px 0px;
  padding: 0px 0px 5px 0px;
  color: #3855a0;
}
/* line 467, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category .category-title-wrapper .category-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.1875em;
  padding: 0px;
  margin: 0px;
}
/* line 473, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category .category-title-wrapper .category-subtitle {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.4375em;
  display: block;
}
/* line 479, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category .category-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0px;
}
/* line 485, ../sass/_layout-extranet.scss */
.layout-extranet .bk-category .category-list .category-list-item {
  width: 49%;
  margin-bottom: 2%;
}
@media only screen and (max-width: 48em) {
  /* line 485, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-category .category-list .category-list-item {
    width: 100%;
    margin-bottom: 20px;
  }
}
/* line 500, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document {
  background-color: #f3f2f1;
  padding: 40px 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100%;
}
@media only screen and (max-width: 37.5em) {
  /* line 500, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-document {
    padding: 20px;
    flex-flow: row wrap;
  }
}
/* line 512, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-img {
  flex-shrink: 0;
  width: 130px;
}
@media only screen and (max-width: 37.5em) {
  /* line 512, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-document .document-img {
    width: 100%;
    height: 160px;
    text-align: center;
  }
}
/* line 520, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-img img {
  width: auto;
  height: 160px;
}
/* line 525, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-infos {
  width: 100%;
  padding: 0px 0px 0px 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 37.5em) {
  /* line 525, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-document .document-infos {
    padding: 10px 0px 0px 0px;
  }
}
/* line 536, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-infos .document-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.4375em;
  color: black;
  margin-bottom: 15px;
}
/* line 542, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-infos .document-text {
  font-family: Arial;
  font-size: 1em;
  margin-bottom: 15px;
}
/* line 547, ../sass/_layout-extranet.scss */
.layout-extranet .bk-document .document-infos .document-link {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.4375em;
  text-decoration: underline;
  margin: 0px 0px 0px auto;
}
/* line 560, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more {
  display: block;
  width: 100%;
  background-color: #3855a0;
  border: 1px solid #3855a0;
  padding: 10px;
  text-decoration: none;
}
/* line 567, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more .show-more-text {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.4375em;
  position: relative;
  color: white;
}
/* line 572, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more .show-more-text:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: 0px 0px 0px 10px;
  padding: 0;
  width: 0px;
  height: 0px;
  border-top: 10px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: none;
}
/* line 586, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more:hover {
  background-color: white;
  transition: all 0.3s ease;
}
/* line 589, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more:hover .show-more-text {
  color: #3855a0;
  transition: all 0.3s ease;
}
/* line 592, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more:hover .show-more-text:after {
  border-top: 10px solid #3855a0;
  border-bottom: none;
  transition: all 0.3s ease;
}
/* line 600, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more:hover.opened .show-more-text:after {
  border-top: none;
  border-bottom: 10px solid #3855a0;
}
/* line 609, ../sass/_layout-extranet.scss */
.layout-extranet .bk-show-more.opened .show-more-text:after {
  border-top: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid white;
}
/* line 623, ../sass/_layout-extranet.scss */
.layout-extranet .bk-gradient {
  background-color: #a0aab3;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFA0AAB3', endColorstr='#FFFFFCED');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2EwYWFiMyIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjY2ZjNWMwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmY2VkIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 0% 100%, 100% 0%, color-stop(0%, #a0aab3), color-stop(50%, #cfc5c0), color-stop(100%, #fffced));
  background-image: -moz-linear-gradient(left bottom, #a0aab3 0%, #cfc5c0 50%, #fffced 100%);
  background-image: -webkit-linear-gradient(left bottom, #a0aab3 0%, #cfc5c0 50%, #fffced 100%);
  background-image: linear-gradient(to right top, #a0aab3 0%, #cfc5c0 50%, #fffced 100%);
  padding: 100px 10px;
  min-height: 100vh;
}
/* line 635, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin {
  border: 1px solid grey;
  background-color: white;
  padding: 30px 60px 60px;
  box-shadow: 2px 2px 10px grey;
}
@media only screen and (max-width: 48em) {
  /* line 635, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin {
    padding: 20px;
  }
}
/* line 644, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-logo-wrapper {
  width: 100%;
  height: 68px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 48em) {
  /* line 644, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin .popin-logo-wrapper {
    margin-bottom: 20px;
  }
}
/* line 651, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-logo-wrapper .popin-logo {
  height: 100%;
}
/* line 656, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-title {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.25em;
  color: black;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1;
}
@media only screen and (max-width: 48em) {
  /* line 656, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin .popin-title {
    font-size: 1.75em;
    margin-bottom: 20px;
  }
}
/* line 671, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-content .wysiwyg {
  color: black;
}
@media only screen and (max-width: 48em) {
  /* line 671, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin .popin-content .wysiwyg {
    font-size: 0.875em;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 48em) {
  /* line 680, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin .popin-content .bk-extranet-formulaire fieldset {
    margin: 0px 0px 30px 0px;
  }
}
/* line 685, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-content .bk-extranet-formulaire fieldset .form-actions {
  margin-top: 0px;
}
/* line 689, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-content .bk-extranet-formulaire ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-align: left;
}
/* line 692, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-content .bk-extranet-formulaire :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: left;
}
/* line 695, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin .popin-content .bk-extranet-formulaire ::-ms-input-placeholder {
  /* Microsoft Edge */
  text-align: left;
}
/* line 702, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion, .layout-extranet .bk-popin--inscription-confirmation {
  width: 600px;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 702, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--connexion, .layout-extranet .bk-popin--inscription-confirmation {
    width: 100%;
  }
}
/* line 709, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion .popin-content, .layout-extranet .bk-popin--inscription-confirmation .popin-content {
  width: 360px;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 709, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--connexion .popin-content, .layout-extranet .bk-popin--inscription-confirmation .popin-content {
    width: 100%;
  }
}
/* line 716, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion .popin-content .btn-flex-wrapper, .layout-extranet .bk-popin--inscription-confirmation .popin-content .btn-flex-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
/* line 720, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion .popin-content .btn-flex-wrapper > *, .layout-extranet .bk-popin--inscription-confirmation .popin-content .btn-flex-wrapper > * {
  width: calc(50% - 10px);
}
/* line 723, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion .popin-content .btn-flex-wrapper a, .layout-extranet .bk-popin--connexion .popin-content .btn-flex-wrapper input, .layout-extranet .bk-popin--inscription-confirmation .popin-content .btn-flex-wrapper a, .layout-extranet .bk-popin--inscription-confirmation .popin-content .btn-flex-wrapper input {
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif !important;
}
/* line 730, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--connexion .popin-content .btn-flex-wrapper input, .layout-extranet .bk-popin--inscription-confirmation .popin-content .btn-flex-wrapper input {
  width: 100% !important;
}
/* line 739, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--forgot-pwd {
  width: 600px;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 739, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--forgot-pwd {
    width: 100%;
  }
}
/* line 745, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--forgot-pwd .popin-content {
  width: 360px;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 745, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--forgot-pwd .popin-content {
    width: 100%;
  }
}
/* line 754, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--forgot-pwd .popin-content .bk-extranet-formulaire fieldset .form-actions .form-field-btn {
  width: 100%;
}
/* line 762, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--inscription {
  width: 1000px;
  max-width: 100%;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 762, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--inscription {
    width: 100%;
  }
}
/* line 769, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--inscription .popin-content {
  max-width: 760px;
  margin: auto;
}
@media only screen and (max-width: 48em) {
  /* line 769, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--inscription .popin-content {
    width: 100%;
  }
}
/* line 777, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--inscription .popin-content .bk-extranet-formulaire fieldset[name="step-2"] {
  margin-top: -50px;
}
/* line 779, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--inscription .popin-content .bk-extranet-formulaire fieldset[name="step-2"] .formulaire-line {
  align-items: flex-start;
}
@media only screen and (max-width: 48em) {
  /* line 784, ../sass/_layout-extranet.scss */
  .layout-extranet .bk-popin--inscription .popin-content .bk-extranet-formulaire fieldset .form-actions .form-field-btn {
    width: auto;
  }
}
/* line 792, ../sass/_layout-extranet.scss */
.layout-extranet .bk-popin--inscription .popin-content .bk-extranet-formulaire #extranet-profile-password .formulaire-line {
  align-items: flex-start;
}
/* line 805, ../sass/_layout-extranet.scss */
.layout-extranet .bk-links {
  margin: 0px;
  padding: 0px;
  text-align: right;
}
/* line 810, ../sass/_layout-extranet.scss */
.layout-extranet .bk-links .links-item a {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  color: #3855a0;
  text-decoration: none;
}
/* line 816, ../sass/_layout-extranet.scss */
.layout-extranet .bk-links .links-item a:hover {
  text-decoration: underline;
}
/* line 827, ../sass/_layout-extranet.scss */
.layout-extranet .btn-like-submit {
  font-weight: normal;
  font-style: normal;
  font-family: "myriad-pro-condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  width: 220px;
  margin: 0px auto;
  padding: 10px;
  position: relative;
  background-color: #3855a0;
  border: 1px solid #3855a0;
  color: white;
  text-decoration: none;
  transition: all 0.3S ease;
  text-align: center;
}
@media only screen and (max-width: 37.5em) {
  /* line 827, ../sass/_layout-extranet.scss */
  .layout-extranet .btn-like-submit {
    width: 140px;
    margin: auto;
  }
}
/* line 847, ../sass/_layout-extranet.scss */
.layout-extranet .btn-like-submit:hover {
  background-color: white;
  color: #3855a0;
}
